import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import "./Welcome.scss";
import { useTranslation } from "react-i18next";
import childrens from "../../assets/childrens.jpg";
import CustomStepper from "../../components/customStepper/CustomStepper";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import AudioSupport from "../../components/audio-support/AudioSupport";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";

const Welcome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showMemberStatus = useAppSelector(showMember);

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <AudioSupport
        audioText={`${t("welcome.1")} ${t("welcome.2")} ${t("welcome.3")} `}
      />
      <Box className="welcomePage">
        <Box className="welcomePage__intro">
          <Typography variant="h3">{t("welcome.1")}</Typography>
          <Typography variant="h3" sx={{ color: "#005994" }}>
            {t("welcome.2")}
          </Typography>
          <Box sx={{ width: "300px", marginTop: "2rem" }}>
            <Typography sx={{ color: "#A3A3A3", textAlign: "start" }}>
              {t("welcome.3")}
            </Typography>
          </Box>
          <Button
            variant="contained"
            className="btn"
            sx={{ width: "270px" }}
            onClick={() => navigate("/signup-new/overview")}
          >
            {t("common.3")}
          </Button>
        </Box>
        <Box className="welcomePage__imageContainer">
          <img src={childrens} alt="" className="img" />
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={0} />
      </Box>
    </>
  );
};

export default Welcome;
