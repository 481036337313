import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import "../common/GoalSettings.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getCurrentMember } from "../../../features/user/userSlice";
import { getUserData, loadUserData } from "../../../features/auth/authSlice";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import {
  getAllRiskAreas,
  getRiskAreasByMemberId,
  updateIsMoveStatus,
} from "../../../features/user/asyncActions";
import { store } from "../../../app/store";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import AudioSupport from "../../../components/audio-support/AudioSupport";
import CustomStepper from "../../../components/customStepper/CustomStepper";
import CustomHint from "../../../components/custom-hint/CustomHint";
import RiskArea from "../../../components/riskArea/RiskArea";

const SettingsSafeAreas = () => {
  const navigate = useNavigate();
  const memberData = useAppSelector(getCurrentMember);
  const [allRiskAreas, setAllRiskAreas] = React.useState<any>([]);
  const [memberRiskAreas, setMemberRiskAreas] = React.useState<any>([]);
  const userData = useAppSelector(getUserData);
  const showMemberStatus = useAppSelector(showMember);
  const { t } = useTranslation();
  const [selectedRiskArea, setSelectedRiskArea] = React.useState<any>(null);

  const fetchAllRisksArea = async () => {
    const data = await getAllRiskAreas(memberData?.user_id);
    if (data) setAllRiskAreas(data);
  };

  const fetchMemberRisksArea = async () => {
    const data = await getRiskAreasByMemberId(memberData?.user_id);
    if (data) setMemberRiskAreas(data);
  };

  const safeAreas = allRiskAreas.filter((area: any) => {
    let exist = true;
    if (memberRiskAreas.length === 0) return exist;
    for (const riskArea of memberRiskAreas) {
      if (area.risk_id === riskArea.risk_id) exist = false;
    }
    return exist;
  });

  React.useEffect(() => {
    fetchAllRisksArea();
    fetchMemberRisksArea();
    if (userData?.isMove === 0) updateIsMoveStatus(userData?.group_id);
    store.dispatch(loadUserData(userData?.group_id));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  React.useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(true));
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="goalSettingsPage">
        <Box className="goalSettingsPage__contentContainer">
          <Box className="subHeader">
            <AudioSupport audioText={t("goal-settings.2")} />
          </Box>
          <Box className="subMain">
            <Box className="goalSafeAreasContainer">
              <Typography
                className="title"
                variant="h3"
                fontSize={{
                  lg: "2.5rem",
                }}
              >
                {t("goal-settings.1")}
              </Typography>
              <Grid container spacing={3} className="areasContainer">
                <Grid item xs={12} sm={12} md={7} lg={8}>
                  <Box className="areas">
                    {safeAreas?.map((area: any) => (
                      <RiskArea
                        key={area.risk_id}
                        title={area.risk_id}
                        type="low_risk_areas"
                        clicked
                        clickHandler={() => setSelectedRiskArea(area.risk_id)}
                        selectedArea={area.risk_id === selectedRiskArea}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                >
                  {selectedRiskArea ? (
                    <Box alignSelf="center" margin="0 1rem">
                      <CustomHint
                        showPlay
                        bottom="-50px"
                        left="-50px"
                        text={t(`all_safe_areas_feedback.${selectedRiskArea}`)}
                        audioText={t(
                          `all_safe_areas_feedback.${selectedRiskArea}`
                        )}
                      />
                    </Box>
                  ) : (
                    <CustomHint
                      text={t("goal-settings.2")}
                      audioText={t("goal-settings.2")}
                      bottom="-50px"
                      right="-50px"
                    />
                  )}
                </Grid>
              </Grid>
              <Button
                className="btn"
                variant="contained"
                onClick={() => navigate("/goal-settings/risk-areas")}
              >
                {t("common.3")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={2} />
      </Box>
    </>
  );
};

export default SettingsSafeAreas;
