import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Grid,
  AlertColor,
} from "@mui/material";
import "./UserDashboard.scss";
import { useTranslation } from "react-i18next";
import { teethIcon, whiteMemberLogo } from "../../../utils/common/global";
import { AddRounded } from "@mui/icons-material";
import CustomDialog from "../../../components/customDialog/CustomDialog";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import {
  getLicenseExpiredDate,
  getUserData,
} from "../../../features/auth/authSlice";
import { store } from "../../../app/store";
import {
  allFamilyMembers,
  getAllfamilyMembers,
  updateMemberData,
} from "../../../features/user/userSlice";
import CustomAlert from "../../../components/custom-alert/CustomAlert";
import { checkEmpty } from "../../../utils/common/checkEmpty";
import { getAge } from "../../../utils/common/getAge";
import GuidePopup from "../../../components/guide-popup/GuidePopup";
import { useWidthSize } from "../../../utils/hooks/resizeHook";
import ConfirmationModal from "../../../components/confirmation-modal/ConfirmationModal";
import { addFamilyMember } from "../../../features/user/asyncActions";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  getGlobalLanguage,
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import { isEmpty } from "lodash";

const UserDashboard = () => {
  const { t } = useTranslation();
  const customWidth = useWidthSize();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const licenseExpiredDate = useAppSelector(getLicenseExpiredDate);
  const familyMembers: any = useAppSelector(getAllfamilyMembers);
  const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);
  const [showMemberDetailsDialog, setShowMemberDetailsDialog] = useState(false);
  const [showModalInfo, setShowModalInfo] = React.useState(
    userData?.isMove === 0 && customWidth < 700
  );
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const showMemberStatus = useAppSelector(showMember);
  const language = useAppSelector(getGlobalLanguage);

  const addMemberHandler = async (body: any) => {
    const state = checkEmpty(body);
    if (state) {
      const { data } = await addFamilyMember({
        groupId: userData.group_id,
        data: body,
      });
      if (data) {
        store.dispatch(updateMemberData(data));
      }
      navigate("/cavity-tutorial");
    } else {
      setShowMessage(true);
      setMessage(t("common.16"));
      setMessageType("error");
    }
  };

  const familiesUnderPCId = async () => {
    if (userData.group_id)
      await store.dispatch(allFamilyMembers(userData.group_id));
  };

  useEffect(() => {
    familiesUnderPCId();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <ConfirmationModal
        title=""
        open={isEmpty(familyMembers) && showModalInfo}
        closeHandler={() => {
          setShowModalInfo(false);
        }}
        showCancelButton={false}
        showSubmitButton={false}
      >
        <Typography className="text">{t("user-dashboard.11")}</Typography>
      </ConfirmationModal>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <CustomDialog
        open={showAddMemberDialog}
        closeHandler={() => {
          setShowAddMemberDialog(false);
        }}
        title={t("user-dashboard.7")}
        saveHandler={addMemberHandler}
      />

      <CustomDialog
        open={showMemberDetailsDialog}
        closeHandler={() => {
          setShowMemberDetailsDialog(false);
        }}
        saveHandler={() => {
          // TODO: add update mumber logic
          navigate("/user-dashboard");
        }}
        title="Member Details"
      />
      <LanguageSupport showTranslateComponent />
      <Box className="userDashboardPage">
        {isEmpty(familyMembers) &&
          userData?.isMove === 0 &&
          customWidth > 700 && (
            <GuidePopup
              text={
                <Typography
                  fontSize="1.2rem"
                  fontWeight="inherit"
                  marginBottom="1rem"
                >
                  {t("user-dashboard.11")}
                </Typography>
              }
              width="368px"
              top="670px"
              left="150px"
              audioText={t("user-dashboard.11")}
            />
          )}
        <Box className="userDashboardPage__intro">
          <Box className="userInfoContainer">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                alt=""
                src={teethIcon}
                sx={{ width: 28, height: 28 }}
                style={{
                  marginRight: "8px",
                  border: "2px solid #005994",
                }}
              />
              {userData?.first_Name && userData.last_name && (
                <Typography sx={{ fontSize: "1.5rem", color: "#07120D" }}>
                  {userData.first_Name} {userData.last_name}
                </Typography>
              )}
            </Box>
            <Typography sx={{ color: "#333333" }}>
              {t("user-dashboard.2")}: {userData.email}
            </Typography>
          </Box>
        </Box>

        <Box className="userDashboardPage__membersContainer">
          <Box className="subHeader">
            <Typography sx={{ fontSize: "1.5rem", color: "#07120D" }}>
              {t("user-dashboard.3")}
            </Typography>
            {!isEmpty(familyMembers) && (
              <Button
                variant="contained"
                className="addMemberBtn"
                sx={{ width: "270px" }}
                onClick={() => setShowAddMemberDialog(true)}
              >
                {t("user-dashboard.4")}
              </Button>
            )}
          </Box>

          <Grid container spacing={3} sx={{ marginTop: "1rem" }}>
            {familyMembers?.map((member: any) => (
              <Grid
                key={member?.user_id}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              >
                <div className="member">
                  <Avatar className="memberLogo" src={whiteMemberLogo} alt="" />
                  <Typography className="memberName">
                    {member?.first_name} {member?.last_name}
                  </Typography>
                  <Typography className="age">
                    {t("user-dashboard.5")} : {getAge(member?.birth_date)}
                  </Typography>
                  <Typography
                    className="details"
                    onClick={() =>
                      navigate(`/member-details/${member.user_id}`)
                    }
                  >
                    {t("user-dashboard.6")}
                  </Typography>
                </div>
              </Grid>
            ))}
            {isEmpty(familyMembers) && (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div
                  className="add-member"
                  onClick={() => setShowAddMemberDialog(true)}
                >
                  <AddRounded sx={{ fontSize: "1.5rem" }} />
                  <Typography sx={{ fontSize: "1.1rem" }}>
                    {t("user-dashboard.4")}
                  </Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default UserDashboard;
