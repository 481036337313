import * as React from "react";
import { Box, Typography } from "@mui/material";
import "./CustomSupportText.scss";
import { useTranslation } from "react-i18next";
import AudioSupport from "../audio-support/AudioSupport";

interface CustomSupportTextProps {
  questionId: string;
}

const CustomSupportText: React.FunctionComponent<CustomSupportTextProps> = ({
  questionId,
}) => {
  const { t } = useTranslation();

  return (
    <Box className="customSupportText">
      <Box>
        <AudioSupport
          audioText={t(`supporting-text.${questionId}`)}
          showText={false}
          width="100%"
          padding="4px 0"
        />
        <Typography
          className="customText"
          variant="body1"
          marginBottom="1.5rem"
        >
          {t(`supporting-text.${questionId}`)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomSupportText;
