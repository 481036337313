import React from 'react';
import { Box, Typography } from '@mui/material';
import './AboutUs.scss';
import aboutUsIcon from '../../assets/about-us-icon.svg';
import { useTranslation } from 'react-i18next';
import LanguageSupport from '../../components/language-support/LanguageSupport';
import {
  showMember,
  updateShowMember,
} from '../../features/global/globalSlice';
import { store } from '../../app/store';
import { useAppSelector } from '../../app/hooks';

const AboutUs = () => {
  const { t } = useTranslation();
  const showMemberStatus = useAppSelector(showMember);

  React.useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <Box className="aboutUsPage">
        <img src={aboutUsIcon} alt="" />
        <Box width="100%" marginTop="2rem">
          <Typography marginBottom="1rem" fontSize="18px">
            <strong>{t('about-us.1')}</strong> {t('about-us.2')}
          </Typography>
          <Typography marginBottom="1rem" fontSize="18px">
            {t('about-us.3')}
          </Typography>
          <Typography marginBottom="1rem" fontSize="18px">
            {t('about-us.4')}
          </Typography>
          <Typography marginBottom="1rem" fontSize="18px">
            {t('about-us.5')}
          </Typography>
          <Typography marginBottom="1rem" fontSize="18px">
            {t('about-us.6')}
          </Typography>
          <ul style={{ marginBottom: '1rem' }}>
            <li>
              <Typography fontSize="18px">{t('about-us.7')}</Typography>
            </li>
            <li>
              <Typography fontSize="18px">{t('about-us.8')}</Typography>
            </li>
            <li>
              <Typography fontSize="18px">{t('about-us.9')}</Typography>
            </li>
          </ul>
          <Typography>{t('about-us.10')}</Typography>
          <Typography marginLeft="1rem">{t('about-us.11')}</Typography>
          <Typography marginLeft="1rem">{t('about-us.12')}</Typography>
          <Typography marginLeft="1rem">{t('about-us.13')}</Typography>
          <Typography marginLeft="1rem">
            <a href="mailto: ble22@columbia.edu">{t('about-us.14')}</a>
          </Typography>
          <Typography marginLeft="1rem">{t('about-us.15')}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default AboutUs;
