import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./ErrorPage.scss";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getUserData } from "../../features/auth/authSlice";
import { store } from "../../app/store";
import { BeatLoader } from "react-spinners";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";

const ErrorPage = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("access-token");
  const userData = useAppSelector(getUserData);
  const showMemberStatus = useAppSelector(showMember);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  useEffect(() => {
    if (!authToken || !userData?.role_id || !userData?.isMove)
      navigate("/sign-in");
    else
      setTimeout(() => {
        setLoading(false);
      }, 1000);
  }, []);

  return (
    <Box className="errorPage">
      {loading ? (
        <Box display="flex" alignItems="center" minHeight={400}>
          <BeatLoader color="#1976d2" size={25} />
        </Box>
      ) : (
        <>
          <Typography className="firstTypo">Oops!</Typography>
          <Typography className="secondTypo">
            We couldn't find the page.
          </Typography>
          <Typography className="thirdTypo">We are so sorry!</Typography>
        </>
      )}
    </Box>
  );
};

export default ErrorPage;
