import * as React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import './DeleteSiteModal.scss';
import { teethIcon } from '../../utils/common/global';
import { useTranslation } from 'react-i18next';

export interface SubmitParams {
  btnContent?: string;
  submitHandler?: () => void;
}

export interface CancelParams {
  btnContent?: string;
  cancelHandler?: () => void;
}

interface DeleteSiteModalProps {
  open: boolean;
  showError?: boolean;
  errorMessage?: string;
  closeHandler: () => void;
  deleteHandler?: () => void;
  removeHandler: () => void;
  hideDelete?: boolean;
}

const DeleteSiteModal: React.FC<DeleteSiteModalProps> = ({
  open,
  closeHandler,
  deleteHandler,
  removeHandler,
  showError = false,
  hideDelete = false,
  errorMessage = '',
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Box className="deleteModal">
          <Box>
            <img src={teethIcon} alt="" className="deleteModal__img" />
          </Box>
          <Box className="deleteModal__content">
            <Box id="modal-modal-title">
              <Typography
                variant="h5"
                component="h2"
                className="title"
                sx={{ color: '#285CB8' }}
              >
                {t('manager-dashboard.11')}
              </Typography>
              {!hideDelete && (
                <Typography
                  variant="h5"
                  component="h2"
                  className="title"
                  sx={{ color: '#285CB8' }}
                >
                  {t('manager-dashboard.12')}
                </Typography>
              )}
              {!hideDelete && (
                <Typography
                  variant="h5"
                  component="h2"
                  className="title"
                  sx={{ color: '#285CB8' }}
                >
                  {t('manager-dashboard.13')}
                </Typography>
              )}
            </Box>

            <Box className="children">
              {showError &&
                (errorMessage ? (
                  <Typography sx={{ color: '#FF0202' }}>
                    {errorMessage}
                  </Typography>
                ) : (
                  <Typography sx={{ color: '#FF0202' }}>
                    {t('manager-dashboard.14')}
                  </Typography>
                ))}
            </Box>
            <Box className="btnContainer">
              <Button
                className="btn removeBtn"
                variant="contained"
                sx={{ color: '#E33C90' }}
                onClick={() => removeHandler()}
              >
                {t('manager-dashboard.15')}
              </Button>
              {!hideDelete && (
                <Button
                  className="btn deleteBtn"
                  variant="outlined"
                  onClick={() => deleteHandler?.()}
                >
                  {t('manager-dashboard.16')}
                </Button>
              )}

              <Button
                variant="contained"
                className="btn cancelBtn"
                onClick={() => closeHandler()}
              >
                {t('common.2')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteSiteModal;
