import customAxios from "../../utils/common/useAxios";

export const addNewLicense = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/licenses/addNewLicense",
    data: body,
  });
  return response;
};

export const fetchLicenses = async () => {
  const response = await customAxios({
    method: "GET",
    url: "/licenses/allLicenses/",
    data: {},
  });
  return response;
};
export const fetchLicense = async (licenseId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/licenses/getLicense/${licenseId}`,
    data: {},
  });
  return response;
};
export const fetchLicenseByMId = async (managerId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/licenses/getLicenseByMId/${managerId}`,
    data: {},
  });
  return response;
};

export const updateLicense = async ({ licenseId, data }: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/licenses/updateLicense/${licenseId}`,
    data,
  });
  return response;
};
