import * as React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "./SettingsStartGoal.scss";
// import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import RiskArea from "../../../components/riskArea/RiskArea";
import CustomAlert from "../../../components/custom-alert/CustomAlert";
import ConfirmationModal, {
  CancelParams,
  SubmitParams,
} from "../../../components/confirmation-modal/ConfirmationModal";
import {
  getActiveRiskAndGoal,
  getCurrentMember,
  saveRiskAndGoal,
} from "../../../features/user/userSlice";
import { useAppSelector } from "../../../app/hooks";
import {
  getAllGoalsByRiskId,
  getRiskAreasByMemberId,
  goalsByMemberId,
} from "../../../features/user/asyncActions";
import { store } from "../../../app/store";
import { useTranslation } from "react-i18next";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import AudioSupport from "../../../components/audio-support/AudioSupport";
import CustomHint from "../../../components/custom-hint/CustomHint";
import { isEmpty } from "lodash";

type SettingsStartGoalProps = {};

const SettingsStartGoal: React.FC<SettingsStartGoalProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedGoal, setSelectedGoal] = React.useState<any | null>({});
  const [showErrorAlert, setShowErrorAlert] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [showBackModal, setShowBackModal] = React.useState<boolean>(false);
  const memberData = useAppSelector(getCurrentMember);
  const [memberRiskAreas, setMemberRiskAreas] = React.useState<any>([]);
  const [riskAreaGoals, setRiskAreaGoals] = React.useState<any>([]);
  const [memberGoals, setMemberGoals] = React.useState<any>([]);
  const currentRiskArea = useAppSelector(getActiveRiskAndGoal);

  const fetchMemberRisksArea = async () => {
    const data = await getRiskAreasByMemberId(memberData.user_id);
    if (data) setMemberRiskAreas(data);
  };

  const goalsWithResult = (goals: Array<any>) => {
    if (goalsByMemberId.length === 0) return goals;
    const newGoals = goals.map((goal: any) => {
      if (memberGoals.includes(goal.goal_id)) {
        return { ...goal, result: "yes" };
      }
      return goal;
    });
    return newGoals;
  };

  const fetchGoalsByRiskId = async (riskId: any) => {
    const data = await getAllGoalsByRiskId(riskId);
    const newGoals = goalsWithResult(data);
    if (data) setRiskAreaGoals(newGoals);
  };

  const fetchGoalsByMemberId = async () => {
    const { data } = await goalsByMemberId(memberData?.user_id);
    if (data) setMemberGoals(data);
  };

  const submitHandler = () => {
    if (!currentRiskArea?.riskId || !selectedGoal?.id) {
      if (!currentRiskArea?.riskId) setErrorMessage(t("start-goal.7"));
      else if (!selectedGoal?.id) setErrorMessage(t("start-goal.8"));
      setShowErrorAlert(true);
    } else {
      addRiskAndGoalHandler();
      navigate("/action-planning/intro");
    }
  };

  const addRiskAndGoalHandler = () => {
    store.dispatch(
      saveRiskAndGoal({
        ...currentRiskArea,
        goalId: selectedGoal.id,
        goalName: selectedGoal.name,
      })
    );
  };

  React.useEffect(() => {
    fetchMemberRisksArea();
    fetchGoalsByMemberId();
  }, []);

  React.useEffect(() => {
    if (!isEmpty(currentRiskArea)) fetchGoalsByRiskId(currentRiskArea.riskId);
  }, [currentRiskArea]);

  const submitParams: SubmitParams = {
    btnContent: "No",
    submitHandler: () => setShowBackModal(false),
  };

  const cancelParams: CancelParams = {
    btnContent: t("start-goal.11"),
    cancelHandler: () => navigate("/user-dashboard"),
  };

  const selectedHandler = (type: string, id: string, name: string) => {
    currentRiskArea && setSelectedGoal({ id: id, name: name });
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <ConfirmationModal
        open={showBackModal}
        title={t("start-goal.9")}
        closeHandler={() => setShowBackModal(false)}
        submitParams={submitParams}
        cancelParams={cancelParams}
      >
        <Typography
          sx={{
            fontSize: "1.25rem",
            lineHeight: "30px",
            textAlign: "center",
            color: "#787D86",
          }}
        >
          {t("start-goal.10")}
        </Typography>
      </ConfirmationModal>
      <CustomAlert
        type="error"
        open={showErrorAlert}
        closeHandler={() => setShowErrorAlert(false)}
        message={errorMessage}
      />
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="startGoalPage">
        <Box className="startGoalPage__contentContainer">
          <Box className="subHeader">
            <AudioSupport
              audioText={
                memberRiskAreas.length > 0
                  ? `${t("start-goal.1")} ${t("start-goal.2")}`
                  : `${t("start-goal.3")}`
              }
            />
          </Box>
          {/* <Box className="hintContainer">
            <img src={teethIcon} alt="" className="teethIcon" />
            <Box className="hint">
              {memberRiskAreas.length > 0 ? (
                <Typography className="hint__text">
                  {t("start-goal.1")} <br /> {t("start-goal.2")}
                </Typography>
              ) : (
                <Typography className="hint__text">
                  {t("start-goal.3")}
                </Typography>
              )}
            </Box>
          </Box> */}
          <Box className="subMain">
            <Box className="startGoalContainer">
              <Box className="goal">
                <Typography className="goalTitle">
                  {t(`high_risk_areas.${currentRiskArea?.riskId}`)}
                </Typography>
              </Box>
              <Typography className="title">{t("start-goal.12")}</Typography>
              <Grid container spacing={3} className="areasContainer">
                <Grid item xs={12} sm={12} md={7} lg={8}>
                  <Box className="goals">
                    {riskAreaGoals.map((goal: any) => (
                      <RiskArea
                        key={goal.goal_id}
                        title={goal.goal_id}
                        type="all_goals"
                        selectedGoal={goal.goal_id === selectedGoal.id}
                        {...(goal?.result && { done: true })}
                        clickHandler={() => {
                          if (!goal?.result) {
                            selectedHandler("goal", goal.goal_id, goal.name);
                          }
                        }}
                        clicked
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                >
                  {!isEmpty(selectedGoal) ? (
                    <Box alignSelf="center" margin="0 1rem">
                      <CustomHint
                        showPlay
                        bottom="-50px"
                        left="-50px"
                        text={t(`all_goals_explanation.${selectedGoal.id}`)}
                        audioText={t(
                          `all_goals_explanation.${selectedGoal.id}`
                        )}
                      />
                    </Box>
                  ) : (
                    <CustomHint
                      text={t("start-goal.3")}
                      audioText={t("start-goal.3")}
                      bottom="-50px"
                      right="-50px"
                    />
                  )}
                </Grid>
              </Grid>

              <Box className="btnContainer">
                <Button
                  className="btn backBtn"
                  variant="contained"
                  onClick={() => setShowBackModal(true)}
                >
                  {t("common.1")}
                </Button>
                <Button
                  className="btn nextBtn"
                  variant="contained"
                  disabled={!currentRiskArea?.riskId || !selectedGoal?.id}
                  onClick={submitHandler}
                >
                  {t("common.3")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SettingsStartGoal;
