import * as React from "react";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import "./LanguageSupport.scss";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  getGlobalLanguage,
  updateLanguage,
} from "../../features/global/globalSlice";
import { store } from "../../app/store";
import { updateUserLanguage } from "../../features/user/asyncActions";
import { getUserData, loadUserData } from "../../features/auth/authSlice";
import CustomBackButton from "../custom-bread-crumbs/CustomBackButton";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
// import { useTranslation } from 'react-i18next';

interface LanguageSupportProps {
  showBackArrowComponent?: boolean;
  showTranslateComponent?: boolean;
  showCustomBackBtn?: boolean;
  customBackBtnContent?: string;
  backFunc?: any;
  width?: string;
}
const languages: any = [
  {
    id: 0,
    label: "English",
    value: "en",
  },
  {
    id: 1,
    label: "Español",
    value: "es",
  },
  {
    id: 2,
    label: "Português Brasileiro",
    value: "pb",
  },
  {
    id: 3,
    label: "Swahili",
    value: "sw",
  },
];

const LanguageSupport: React.FC<LanguageSupportProps> = ({
  showBackArrowComponent = false,
  showTranslateComponent = false,
  showCustomBackBtn = false,
  customBackBtnContent = "",
  backFunc,
  width = "100%",
}) => {
  const language = useAppSelector(getGlobalLanguage);
  const userData = useAppSelector(getUserData);
  const [selectedLanguage, setSelectedLanguage] = React.useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSelectedLanguage = async (selectedLanguage: any) => {
    const lanId = selectedLanguage.id;
    await store.dispatch(updateLanguage(selectedLanguage.value));
    if (userData?.group_id) {
      await updateUserLanguage(userData?.group_id, lanId);
      await store.dispatch(loadUserData(userData.group_id));
    }
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const findLangauge = languages.find((l: any) => l.value === language);
    setSelectedLanguage(findLangauge);
  }, [language]);

  const navigate = useNavigate();
  return (
    <Box className="languageSupport" width={width}>
      {showBackArrowComponent && (
        <Box
          className="languageSupport__backArrow"
          onClick={backFunc ? () => backFunc() : () => navigate(-1)}
        >
          <KeyboardBackspaceRounded className="backIcon" />
        </Box>
      )}
      {showTranslateComponent && (
        <Box className="languageSupport__language">
          {showCustomBackBtn && (
            <CustomBackButton
              btnContent={customBackBtnContent}
              btnUrl="/user-dashboard"
            />
          )}
          {/* <Box display="flex" alignItems="center" columnGap="4px">
            <Typography
              className={`${language === 'en' ? 'language language--selected' : 'language'
                }`}
              onClick={() => updateSelectedLanguage('en')}
            >
              English
            </Typography>
            <Typography>|</Typography>
            <Typography
              className={`${language === 'es' ? 'language language--selected' : 'language'
                }`}
              onClick={() => updateSelectedLanguage('es')}
            >
              Español
            </Typography>
          </Box> */}
          <Box className="language__dropdown">
            <span
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="category-menu"
            >
              <Box
                display="flex"
                width="250px"
                maxWidth="320px"
                minHeight="50px"
                borderRadius="10px"
                border="2px solid #005994"
                style={{
                  cursor: "pointer",
                }}
                flex="1"
              >
                <Typography
                  variant="subtitle1"
                  color="#787D86"
                  fontSize="1.1rem"
                  fontWeight="700"
                  flex="1"
                  textAlign="center"
                  margin="auto 0"
                >
                  {selectedLanguage.label}
                </Typography>
                <Box
                  bgcolor="#005994"
                  borderRadius="0 6px 6px 0"
                  display="flex"
                  alignItems="center"
                >
                  {open ? (
                    <ArrowDropUp style={{ color: "#fff" }} />
                  ) : (
                    <ArrowDropDown style={{ color: "#fff" }} />
                  )}
                </Box>
              </Box>
            </span>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                width: "250px",
              }}
              className="dropdown__menu"
            >
              {languages.map((lan: any) => (
                <MenuItem
                  key={lan.id}
                  onClick={() => updateSelectedLanguage(lan)}
                >
                  {lan.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LanguageSupport;
