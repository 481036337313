import { Box } from "@mui/material";
import { BeatLoader } from "react-spinners";
const CustomLoader = () => {
  return (
    <Box
      display="flex"
      minHeight="400px"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <BeatLoader color="#1976d2" size={25} />
    </Box>
  );
};

export default CustomLoader;
