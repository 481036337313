import * as React from 'react';
import { Box, Typography } from '@mui/material';
import './RiskArea.scss';
import { teethIcon } from '../../utils/common/global';
import { useTranslation } from 'react-i18next';

interface RiskAreaProps {
  title: string;
  type?: string;
  done?: boolean;
  clicked?: boolean;
  selectedGoal?: boolean;
  selectedArea?: boolean;
  clickHandler?: (event: React.MouseEvent<HTMLElement>) => void;
}

const RiskArea: React.FunctionComponent<RiskAreaProps> = ({
  title,
  type = 'all_risk_areas',
  done = false,
  clicked = false,
  selectedGoal = false,
  selectedArea = false,
  clickHandler = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <Box
      className="riskAreaBox"
      onClick={(ev) => (clicked ? clickHandler(ev) : {})}
      sx={{ cursor: `${clicked ? 'pointer' : 'default'}` }}
    >
      <Box
        className={`leftBox ${
          !done && selectedGoal && 'leftBox--selectedGoal'
        }`}
        width={{ xs: '44px', md: '64px' }}
      />
      <Box
        className={`rightBox ${done && 'rightBox--done'} ${
          !done && selectedArea && 'rightBox--selectedArea'
        } ${!done && selectedGoal && 'rightBox--selectedGoal'}`}
      >
        <Typography
          variant="h5"
          className="rightBox__title"
          color="#005994"
          fontWeight="700"
        >
          {t(`${type}.${title}`)}
        </Typography>
        {done && (
          <Box className="rightBox__doneBox">
            <img src={teethIcon} alt="" className="teethIcon" />
            <Typography className="doneText">Done</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RiskArea;
