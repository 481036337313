import customAxios from "../../utils/common/useAxios";

export const addUserSite = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/sites/addUserSite",
    data: body,
  });
  return response;
};

export const addPcWithoutManagerToSite = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/sites/addPcWithoutManagerToSite",
    data: body,
  });
  return response;
};
export const addPcWithManagerToSite = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/sites/addPcWithManagerToSite",
    data: body,
  });
  return response;
};

export const fetchManagersBySiteId = async (managerId: string) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/managersUnderSites/${managerId}`,
    data: {},
  });
  return response.data;
};
export const showManagersforAddSite = async () => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/showManagersforAddSite`,
    data: {},
  });
  return response.data;
};

export const updateUserInfo = async (body: any) => {
  const response = await customAxios({
    method: "PUT",
    url: "/group/updateInfo",
    data: body,
  });
  return response;
};
export const updateFamilyUserInfo = async (body: any) => {
  const response = await customAxios({
    method: "PUT",
    url: "/group/updateFamilyInfo",
    data: body,
  });
  return response;
};

export const fetchSiteById = async (siteId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/getSite/${siteId}`,
    data: {},
  });
  return response;
};
export const addNewSite = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/sites/addNewSite",
    data: body,
  });
  return response;
};

export const editSite = async (body: any) => {
  const response = await customAxios({
    method: "PUT",
    url: "/sites/updateSite",
    data: body,
  });
  return response;
};

export const excludeSite = async ({ managerId, siteId }: any) => {
  const response = await customAxios({
    method: "DELETE",
    url: `/sites/excludeSite/${managerId}/${siteId}`,
    data: {},
  });
  return response;
};
export const excludePcFromSite = async ({ pcId, siteId }: any) => {
  const response = await customAxios({
    method: "DELETE",
    url: `/sites/excludePcFromSite/${pcId}/${siteId}`,
    data: {},
  });
  return response;
};

export const deleteSite = async (siteId: any) => {
  const response = await customAxios({
    method: "DELETE",
    url: `/sites/deleteSite/${siteId}`,
    data: {},
  });
  return response;
};
export const checkPCUnderSiteStatus = async (siteId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/checkPCUnderSiteStatus/${siteId}`,
    data: {},
  });
  return response;
};

export const managersSitesByManagerId = async (managerId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/getAllSitesBymId/${managerId}`,
    data: {},
  });
  return response.data;
};
export const managersUnderSitesByPcId = async (pcId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/managersUnderSitesByPcId/${pcId}`,
    data: {},
  });
  return response.data;
};

export const checkSiteOwner = async ({ siteId, managerId }: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/checkSiteOwner/${siteId}/${managerId}`,
    data: {},
  });
  return response;
};

export const fetchFlyer = async ({ lanId, goalId, id }: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/follow-up/${lanId}/${goalId}/${id}`,
    data: {},
  });
  return response;
};

export const checkSiteUniqueCode = async ({ siteCode }: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/sites/checkSiteUniqueCode`,
    data: {
      uniqueCode: siteCode,
    },
  });
  return response;
};
export const getAllSitesByMangerId = async (managerId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/getAllSitesBymId/${managerId}`,
    data: {},
  });
  return response;
};

export const getAllGroupsBySiteUniqueCode = async (uniqueCode: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/groupsUnderSite/${uniqueCode}`,
    data: {},
  });
  return response;
};
