import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface GlobalState {
  language: string;
  selectedAnswers: any;
  showMember: boolean;
  previousPaths: Array<any>;
  mode: string;
}

export const risksAssessmentAnswers: any = {
  diet: [],
  feeding: [],
  brushing: [],
  fluoride: [],
  dentalHome: [],
  oralHealth: [],
  timeArray: [],
  feedingAnswers: [],
  brushingAnswers: [],
  fluorideAnswers: [],
  dentalHomeAnswers: [],
  oralHealthAnswers: [],
};

export const globalInitialState: GlobalState = {
  language: "en",
  selectedAnswers: { ...risksAssessmentAnswers },
  showMember: false,
  previousPaths: [],
  mode: process.env.REACT_APP_ENV || "production",
};

export const globalSlice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    updateLanguage: (state, action) => {
      state.language = action.payload;
    },
    updateSelectedAnswers: (state, action) => {
      state.selectedAnswers = action.payload;
    },
    updateShowMember: (state, action) => {
      state.showMember = action.payload;
    },
    updatePreviousPaths: (state, action) => {
      state.previousPaths = action.payload;
    },
    globalSignOut: (state, action) => {
      state.previousPaths = [];
      state.selectedAnswers = risksAssessmentAnswers;
    },
  },
});
export const {
  updateLanguage,
  updateSelectedAnswers,
  updateShowMember,
  updatePreviousPaths,
  globalSignOut,
} = globalSlice.actions;

export const getGlobalLanguage = (state: RootState) => state.global.language;
export const getMode = (state: RootState) => state.global.mode;
export const getSelectedAnswers = (state: RootState) =>
  state.global.selectedAnswers;
export const showMember = (state: RootState) => state.global.showMember;
export const getPreviousPaths = (state: RootState) =>
  state.global.previousPaths;

export default globalSlice.reducer;
