import i18n from "../transSettings/i18next";

const { t } = i18n;

interface Areas {
  id: number;
  shape: string;
  coords: any[];
  fillColor?: string;
  strokeColor?: string;
  href?: string;
}

export const langaugesIndex: any = {
  en: 0,
  es: 1,
  pb: 2,
  sw: 3,
};

export const sign_intro_videos: any = {
  en: "a3kk0R8HglM",
  es: "oCo7Iodkl8M",
  pb: "-E25odR9h7w",
  sw: "XRvoQ1fIQhA",
};

export const caries_video: any = {
  "1": {
    en: "VykwvEI3b9Q",
    es: "YafZHk523MA",
    pb: "Gi5rQZn9Lik",
    sw: "FBuOU5fEkWI",
  },
  "2": {
    en: "_hO_eARnNww",
    es: "0zuiuXdruBA",
    pb: "XPYtXZJtLv4",
    sw: "NxbAGXhw-wE",
  },
};

export const action_planning_video: any = {
  en: "k0nl5nuy7Sw",
  es: "sMOE0fxRA7k",
  pb: "QV6N6AqvXuI",
  sw: "6MhvAnq8xQQ",
};

export const toothbrush_video: any = {
  "1": {
    en: "H4yeLNWa1H8",
    es: "2nvZrmUmBMw",
    pb: "EhMypXAosPM",
    sw: "tGrTmBkWO3M",
  },
  "2": {
    en: "HN9BxzQobAo",
    es: "FbPrPjqJ_24",
    pb: "AkLyKndFjSs",
    sw: "10flq3aZe-s",
  },
};

export const durationLabels: any = {
  "0": t("duration.1"),
  "2": t("duration.2"),
  "4": t("duration.3"),
  "6": t("duration.4"),
};

export const notifications: any = {
  "1": t("active-goal.7"),
  "2": t("active-goal.8"),
  "3": t("active-goal.9"),
};

const publicURL = `${process.env.REACT_APP_MSB_S3_BASE_URL}/public`;
const iconsURL = `${publicURL}/icons`;
const resourcesURL = `${publicURL}/resources`;
const foodsAndDrinksURL = `${publicURL}/food-and-drinks`;
const navigationURL = `${publicURL}/navigation`;
const surveyURL = `${publicURL}/survey`;
const followUpURL = `${process.env.REACT_APP_MSB_S3_BASE_URL}/follow-up`;
const pdfResourcesURL = `${process.env.REACT_APP_MSB_S3_BASE_URL}/pdf-resources`;

export const teethIcon = `${iconsURL}/teeth-icon.svg`;
export const mySmileBuddyIcon = `${iconsURL}/my-smile-buddy-icon.png`;
export const signInEnIcon = `${iconsURL}/sign-in-en.png`;
export const signInEsIcon = `${iconsURL}/sign-in-es.png`;
export const signInPbIcon = `${iconsURL}/sign-in-pb.png`;
export const signInSwIcon = `${iconsURL}/sign-in-sw.png`;
export const signUpEnIcon = `${iconsURL}/sign-up-en.png`;
export const signUpEsIcon = `${iconsURL}/sign-up-es.png`;
export const signUpPbIcon = `${iconsURL}/sign-up-pb.png`;
export const signUpSwIcon = `${iconsURL}/sign-up-sw.png`;

export const resourcesImages = Array.from(Array(15).keys()).map(
  (_: any, i: number) => `${resourcesURL}/resources${i + 1}.jpg`
);

export const allGoals = Array.from(Array(20).keys());
export const uniqueGoals: any = {
  1: [1, 13],
  2: [2, 14],
  3: [3, 15],
  4: [4],
  5: [5],
  6: [6],
  7: [7],
  8: [8, 16],
  9: [9, 17],
  10: [10, 18],
  11: [11, 19],
  12: [12, 20],
};

export const followUpImages: any = {
  "1": {
    "0": "avoidfoodcavity_1_en_younger.png",
    "1": "avoidfoodcavity_1_sp_younger.png",
    "2": "avoidfoodcavity_1_pb_younger.png",
    "3": "avoidfoodcavity_1_sw_younger.png",
  },
  "2": {
    "0": "drinkwater_1_en_younger.png",
    "1": "drinkwater_1_sp_younger.png",
    "2": "drinkwater_1_pb_younger.png",
    "3": "drinkwater_1_sw_younger.png",
  },
  "3": {
    "0": "makeeatingtime_1_en_younger.png",
    "1": "makeeatingtime_1_sp_younger.png",
    "2": "makeeatingtime_1_pb_younger.png",
    "3": "makeeatingtime_1_sw_younger.png",
  },
  "4": {
    "0": "reducedaybottle_1_en_younger.png",
    "1": "reducedaybottle_1_sp_younger.png",
    "2": "reducedaybottle_1_pb_younger.png",
    "3": "reducedaybottle_1_sw_younger.png",
  },
  "5": {
    "0": "reducenightbottle_1_en_younger.png",
    "1": "reducenightbottle_1_sp_younger.png",
    "2": "reducenightbottle_1_pb_younger.png",
    "3": "reducenightbottle_1_sw_younger.png",
  },
  "6": {
    "0": "reducebacteria_1_en_younger.png",
    "1": "reducebacteria_1_sp_younger.png",
    "2": "reducebacteria_1_pb_younger.png",
    "3": "reducebacteria_1_sw_younger.png",
  },
  "7": {
    "0": "brushteeth_1_en_younger.png",
    "1": "brushteeth_1_sp_younger.png",
    "2": "brushteeth_1_pb_younger.png",
    "3": "brushteeth_1_sw_younger.png",
  },
  "8": {
    "0": "brushthoroughly_1_en_younger.png",
    "1": "brushthoroughly_1_sp_younger.png",
    "2": "brushthoroughly_1_pb_younger.png",
    "3": "brushthoroughly_1_sw_younger.png",
  },
  "9": {
    "0": "flossregularly_1_en_younger.png",
    "1": "flossregularly_1_sp_younger.png",
    "2": "flossregularly_1_pb_younger.png",
    "3": "flossregularly_1_sw_younger.png",
  },
  "10": {
    "0": "fluorideuse_1_en_younger.png",
    "1": "fluorideuse_1_sp_younger.png",
    "2": "fluorideuse_1_pb_younger.png",
    "3": "fluorideuse_1_sw_younger.png",
  },
  "11": {
    "0": "scheduleforchild_1_en_younger.png",
    "1": "scheduleforchild_1_sp_younger.png",
    "2": "scheduleforchild_1_pb_younger.png",
    "3": "scheduleforchild_1_sw_younger.png",
  },
  "12": {
    "0": "caregiveroral_1_en_younger.png",
    "1": "caregiveroral_1_sp_younger.png",
    "2": "caregiveroral_1_pb_younger.png",
    "3": "caregiveroral_1_sw_younger.png",
  },
  "13": {
    "0": "dontbuysnack_1_en_older.png",
    "1": "dontbuysnack_1_sp_older.png",
    "2": "dontbuysnack_1_pb_older.png",
    "3": "dontbuysnack_1_sw_older.png",
  },
  "14": {
    "0": "drinkwater_1_en_older.png",
    "1": "drinkwater_1_sp_older.png",
    "2": "drinkwater_1_pb_older.png",
    "3": "drinkwater_1_sw_older.png",
  },
  "15": {
    "0": "makeeatingtime_1_en_older.png",
    "1": "makeeatingtime_1_sp_older.png",
    "2": "makeeatingtime_1_pb_older.png",
    "3": "makeeatingtime_1_sw_older.png",
  },
  "16": {
    "0": "toothbrushing_2_en_older.png",
    "1": "brushwell_1_sp_older.png",
    "2": "brushwell_1_pb_older.png",
    "3": "toothbrushing_2_sw_older.png",
  },
  "17": {
    "0": "flossregularly_1_en_older.png",
    "1": "flossregularly_1_sp_older.png",
    "2": "flossregularly_1_pb_older.png",
    "3": "flossregularly_1_sw_older.png",
  },
  "18": {
    "0": "fluorideuse_1_en_older.png",
    "1": "fluorideuse_1_sp_older.png",
    "2": "fluorideuse_1_pb_older.png",
    "3": "fluorideuse_1_sw_older.png",
  },
  "19": {
    "0": "scheduleforchild_1_en_older.png",
    "1": "scheduleforchild_1_sp_older.png",
    "2": "scheduleforchild_1_pb_older.png",
    "3": "scheduleforchild_1_sw_older.png",
  },
  "20": {
    "0": "caregiveroral_1_en_older.png",
    "1": "caregiveroral_1_sp_older.png",
    "2": "caregiveroral_1_pb_older.png",
    "3": "caregiveroral_1_sw_older.png",
  },
  "21": {
    "0": "brush_well_2_en_younger.png",
    "3": "brush_well_2_sw_younger.png",
    // "1": "brush_well_2_en_younger.png",
    // "2": "brush_well_2_en_younger.png",
  },
  "22": {
    "0": "brushwell_1_en_older.png",
    "3": "brushwell_1_sw_older.png",
    // "1": "brushwell_1_sp_older.png",
    // "2": "brushwell_1_pb_older.png",
  },
};
export const followUpPdfs: any = {
  "1": {
    "0": "Avoid Food Cavity.pdf",
    "1": "Avoid Food Cavity.pdf",
    "2": "Avoid Food Cavity.pdf",
    "3": "Avoid Food Cavity.pdf",
  },
  "2": {
    "0": "Drink Water.pdf",
    "1": "Drink Water.pdf",
    "2": "Drink Water.pdf",
    "3": "Drink Water.pdf",
  },
  "3": {
    "0": "Make Eating Time.pdf",
    "1": "Make Eating Time.pdf",
    "2": "Make Eating Time.pdf",
    "3": "Make Eating Time.pdf",
  },
  "4": {
    "0": "Reduce Day Bottle.pdf",
    "1": "Reduce Day Bottle.pdf",
    "2": "Reduce Day Bottle.pdf",
    "3": "Reduce Day Bottle.pdf",
  },
  "5": {
    "0": "Reduce Night Bottle.pdf",
    "1": "Reduce Night Bottle.pdf",
    "2": "Reduce Night Bottle.pdf",
    "3": "Reduce Night Bottle.pdf",
  },
  "6": {
    "0": "Reduce Bacteria.pdf",
    "1": "Reduce Bacteria.pdf",
    "2": "Reduce Bacteria.pdf",
    "3": "Reduce Bacteria.pdf",
  },
  "7": {
    "0": "Brush Teeth.pdf",
    "1": "Brush Teeth.pdf",
    "2": "Brush Teeth.pdf",
    "3": "Brush Teeth.pdf",
  },
  "8": {
    "0": "Brush Thoroughly.pdf",
    "1": "Brush Thoroughly.pdf",
    "2": "Brush Thoroughly.pdf",
    "3": "Brush Thoroughly.pdf",
  },
  "9": {
    "0": "Floss Regularly.pdf",
    "1": "Floss Regularly.pdf",
    "2": "Floss Regularly.pdf",
    "3": "Floss Regularly.pdf",
  },
  "10": {
    "0": "Fluride Use.pdf",
    "1": "Fluride Use.pdf",
    "2": "Fluride Use.pdf",
    "3": "Fluride Use.pdf",
  },
  "11": {
    "0": "Schedule For Child.pdf",
    "1": "Schedule For Child.pdf",
    "2": "Schedule For Child.pdf",
    "3": "Schedule For Child.pdf",
  },
  "12": {
    "0": "Caregiver Oral.pdf",
    "1": "Caregiver Oral.pdf",
    "2": "Caregiver Oral.pdf",
    "3": "Caregiver Oral.pdf",
  },
  "13": {
    "0": "Don_t Buy Food Cavity.pdf",
    "1": "Don_t Buy Food Cavity.pdf",
    "2": "Don_t Buy Food Cavity.pdf",
    "3": "Don_t Buy Food Cavity.pdf",
  },
  "14": {
    "0": "Drink Water.pdf",
    "1": "Drink Water.pdf",
    "2": "Drink Water.pdf",
    "3": "Drink Water.pdf",
  },
  "15": {
    "0": "Make Eating Time.pdf",
    "1": "Make Eating Time.pdf",
    "2": "Make Eating Time.pdf",
    "3": "Make Eating Time.pdf",
  },
  "16": {
    "0": "Brush Well.pdf",
    "1": "Brush Well.pdf",
    "2": "Brush Well.pdf",
    "3": "Brush Well.pdf",
  },
  "17": {
    "0": "Floss Regularly.pdf",
    "1": "Floss Regularly.pdf",
    "2": "Floss Regularly.pdf",
    "3": "Floss Regularly.pdf",
  },
  "18": {
    "0": "Use Floride Tooth Paste.pdf",
    "1": "Use Floride Tooth Paste.pdf",
    "2": "Use Floride Tooth Paste.pdf",
    "3": "Use Floride Tooth Paste.pdf",
  },
  "19": {
    "0": "Schedule Regular Dental Care.pdf",
    "1": "Schedule Regular Dental Care.pdf",
    "2": "Schedule Regular Dental Care.pdf",
    "3": "Schedule Regular Dental Care.pdf",
  },
  "20": {
    "0": "Caregiver Oral Health.pdf",
    "1": "Caregiver Oral Health.pdf",
    "2": "Caregiver Oral Health.pdf",
    "3": "Caregiver Oral Health.pdf",
  },
};

export const renderAreas = (goalId: string): any[] => {
  let areas: Areas[] = [];

  switch (goalId) {
    case "7":
      areas = [
        {
          id: 0,
          shape: "rect",
          coords: [1166, 1261, 1537, 1318],
          fillColor: "rgba(31, 179, 237, 0.3)",
          strokeColor: "rgba(31, 179, 237, 0.7)",
          href: "https://www.youtube.com/watch?v=H4yeLNWa1H8",
        },
      ];
      break;
    case "8":
      areas = [
        {
          id: 0,
          shape: "rect",
          coords: [148, 1120, 961, 1170],
          fillColor: "rgba(31, 179, 237, 0.3)",
          strokeColor: "rgba(31, 179, 237, 0.7)",
          href: "https://www.youtube.com/watch?v=H4yeLNWa1H8",
        },
        {
          id: 1,
          shape: "rect",
          coords: [150, 1180, 905, 1235],
          fillColor: "rgba(31, 179, 237, 0.3)",
          strokeColor: "rgba(31, 179, 237, 0.7)",
          href: "https://www.youtube.com/watch?v=HN9BxzQobAo",
        },
      ];
      break;
    case "16":
      areas = [
        {
          id: 0,
          shape: "rect",
          coords: [73, 552, 463, 581],
          fillColor: "rgba(31, 179, 237, 0.3)",
          strokeColor: "rgba(31, 179, 237, 0.7)",
          href: "https://www.youtube.com/watch?v=H4yeLNWa1H8",
        },
        {
          id: 1,
          shape: "rect",
          coords: [73, 583, 438, 609],
          fillColor: "rgba(31, 179, 237, 0.3)",
          strokeColor: "rgba(31, 179, 237, 0.7)",
          href: "https://www.youtube.com/watch?v=HN9BxzQobAo",
        },
      ];
      break;
    case "21":
      areas = [
        {
          id: 0,
          shape: "rect",
          coords: [493, 635, 885, 660],
          fillColor: "rgba(31, 179, 237, 0.3)",
          strokeColor: "rgba(31, 179, 237, 0.7)",
          href: "https://www.youtube.com/watch?v=H4yeLNWa1H8",
        },
      ];
      break;
    case "22":
      areas = [
        {
          id: 0,
          shape: "rect",
          coords: [871, 1396, 1633, 1443],
          fillColor: "rgba(31, 179, 237, 0.3)",
          strokeColor: "rgba(31, 179, 237, 0.7)",
          href: "https://www.youtube.com/watch?v=HN9BxzQobAo",
        },
      ];
      break;
    default:
      areas = [];
  }

  return areas;
};

export const foodsAndDrinksArrayImages: any = Array.from(Array(41).keys()).map(
  (_: any, i: number) => {
    return {
      [`diet_image${i + 1}`]: `${foodsAndDrinksURL}/diet_image${i + 1}.jpg`,
    };
  }
);
export const foodsAndDrinksImages = foodsAndDrinksArrayImages.reduce(
  (res: any, item: any) => {
    var key = Object.keys(item)[0];
    res[key] = item[key];
    return res;
  },
  {}
);

export const whatsIcon = `${iconsURL}/whats-icon.png`;
export const blackEmailIcon = `${iconsURL}/black-email-icon.svg`;
export const whiteEmailIcon = `${iconsURL}/white-email-icon.svg`;

export const LicenseIcon = `${navigationURL}/license-icon.svg`;
export const whiteMemberLogo = `${navigationURL}/white-member.svg`;
export const actionPlanningLogo = `${navigationURL}/action-planning.svg`;
export const activeMemberLogo = `${navigationURL}/active-member.svg`;
export const assessmentLogo = `${navigationURL}/assessment.svg`;
export const disabledCounselorLogo = `${navigationURL}/disabled-counselor.svg`;
export const disabledFamilyLogo = `${navigationURL}/disabled-family.svg`;
export const disabledManagerLogo = `${navigationURL}/disabled-manager.svg`;
export const disabledMemberLogo = `${navigationURL}/disabled-member.svg`;
export const disabledProviderLogo = `${navigationURL}/disabled-provider.svg`;
export const disabledSiteLogo = `${navigationURL}/disabled-site.svg`;
export const managerLogo = `${navigationURL}/manager.svg`;
export const providerLogo = `${navigationURL}/provider.svg`;
export const counselorLogo = `${navigationURL}/counselor.svg`;
export const familyLogo = `${navigationURL}/family.svg`;
export const siteLogo = `${navigationURL}/site.svg`;
export const followUpLogo = `${navigationURL}/follow-up.svg`;
export const goalSettingsLogo = `${navigationURL}/goal-settings.svg`;
export const goalLogo = `${navigationURL}/goal.svg`;
export const infoIcon = `${iconsURL}/info.png`;
export const questionGuideIcon = `${iconsURL}/question-guide.png`;
export const USflagIcon = `${iconsURL}/US-flag.svg`;
export const survey1 = `${surveyURL}/survey1.png`;
export const survey2 = `${surveyURL}/survey2.png`;

export const ImagesArrayFood = Array.from(Array(29), (_, i) => i + 1).map(
  (i) => {
    return {
      imageId: `${i}`,
      image: foodsAndDrinksImages?.[`diet_image${i}`],
    };
  }
);

export const ImagesArrayDrink = Array.from(Array(11), (_, i) => i + 30).map(
  (i) => {
    return {
      imageId: `${i}`,
      image: foodsAndDrinksImages?.[`diet_image${i}`],
    };
  }
);

export const firstFollowUpFlyer = (language: string, goalId: string | number) =>
  `${followUpURL}/${language}/${
    followUpImages[goalId][langaugesIndex[language]]
  }`;

export const followUpPdfLink = (
  language: string,
  goalId: string | number,
  idx: string | number
): string => {
  const type = idx === 0 ? "younger" : "older";
  return `${pdfResourcesURL}/${language}/${type}/${
    followUpPdfs[goalId][langaugesIndex[language]]
  }`;
};
