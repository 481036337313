import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, AlertColor } from "@mui/material";
import "./SiteDetails.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

import { store } from "../../app/store";
import { getUserData } from "../../features/auth/authSlice";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import {
  fetchSiteById,
  getAllGroupsBySiteUniqueCode,
} from "../../features/manager/asyncActions";
import CustomLoader from "../../components/custom-loader/CustomLoader";
import { isEmpty } from "lodash";

const SiteDetails: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { siteId } = useParams();
  const userData = useAppSelector(getUserData);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const showMemberStatus = useAppSelector(showMember);
  const [currentSite, setCurrentSite] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allGroups, setAllGroups] = useState([]);

  const getAllGroups = async () => {
    const { data } = await getAllGroupsBySiteUniqueCode(
      currentSite?.unique_code
    );
    setAllGroups(data);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  const getSite = async () => {
    const { data } = await fetchSiteById(siteId);
    setCurrentSite(data ?? {});
  };

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    getSite();
  }, []);

  useEffect(() => {
    if (currentSite?.unique_code) getAllGroups();
  }, [currentSite]);

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="siteDetailsPage">
        <Box className="siteDetailsPage__container">
          <Box className="subHeader">
            {!isEmpty(currentSite) && (
              <>
                <Typography
                  marginRight="auto"
                  variant="h4"
                  fontSize="1.5rem"
                  fontWeight="600"
                  color="#787d86"
                  display="flex"
                  gap="2rem"
                >
                  <span>{currentSite.description}</span>
                  <span>site code: {currentSite.unique_code}</span>
                </Typography>
                <Button
                  variant="contained"
                  className="btn containedBtn"
                  onClick={() => navigate(`/user-report/site/${siteId}`)}
                >
                  {t("pc-dashboard.1")}
                </Button>
              </>
            )}
          </Box>
          <Box className="subContent">
            {isLoading ? (
              <CustomLoader />
            ) : (
              <>
                <Typography className="title" variant="h4">
                  {t("site-details.9")}
                </Typography>
                <Grid container sx={{ margin: "0.5rem 0 4rem", flex: 1 }}>
                  {allGroups
                    ?.filter((group: any) => group?.role_id === 4)
                    .map((family: any) => (
                      <Grid
                        item
                        key={family.group_id}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                      >
                        <Box className="item">
                          <Typography className="item__title">
                            {/* {t('navigation.2')} {provider.group_id} */}
                            {`${family.first_Name} ${family.last_name}`}
                          </Typography>
                          <Typography
                            className="item__details"
                            onClick={() =>
                              navigate(`/family/${family.group_id}`)
                            }
                          >
                            see details
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
                <Typography className="title" variant="h4">
                  {t("site-details.10")}
                </Typography>
                <Grid container sx={{ margin: "0.5rem 0 4rem", flex: 1 }}>
                  {allGroups
                    ?.filter((group: any) => group?.role_id === 3)
                    .map((counselor: any) => (
                      <Grid
                        item
                        key={counselor.group_id}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                      >
                        <Box className="item">
                          <Typography className="item__title">
                            {/* {t('navigation.2')} {provider.group_id} */}
                            {`${counselor.first_Name} ${counselor.last_name}`}
                          </Typography>
                          <Typography
                            className="item__details"
                            onClick={() =>
                              navigate(`/pc/${counselor.group_id}`)
                            }
                          >
                            see details
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
                <Typography className="title" variant="h4">
                  {t("site-details.11")}
                </Typography>
                <Grid container sx={{ margin: "0.5rem 0 4rem", flex: 1 }}>
                  {allGroups
                    ?.filter((group: any) => group?.role_id === 2)
                    .map((provider: any) => (
                      <Grid
                        item
                        key={provider.group_id}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                      >
                        <Box className="item">
                          <Typography className="item__title">
                            {/* {t('navigation.2')} {provider.group_id} */}
                            {`${provider.first_Name} ${provider.last_name}`}
                          </Typography>
                          <Typography
                            className="item__details"
                            onClick={() => navigate(`/pc/${provider.group_id}`)}
                          >
                            see details
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SiteDetails;
