import * as React from "react";
import { Box, Typography, Select, MenuItem, Avatar } from "@mui/material";
import "./ActionItem.scss";
import { useTranslation } from "react-i18next";
import { Popover, Whisper } from "rsuite";
import AudioSupport from "../../../../components/audio-support/AudioSupport";
import { infoIcon } from "../../../../utils/common/global";
import { useAppSelector } from "../../../../app/hooks";
import { getMode } from "../../../../features/global/globalSlice";
import moment from "moment";

interface ActionItemProps {
  title: string;
  text: string;
  type?: string;
  selectedValue?: string;
  onChange?: (ev: any) => void;
  messageType: string;
}

const ActionItem: React.FunctionComponent<ActionItemProps> = ({
  title,
  selectedValue,
  text,
  type = "",
  onChange,
  messageType,
}) => {
  const { t } = useTranslation();
  const mode = useAppSelector(getMode);
  // const today =  new Date().toISOString().split("T")[0];
  const today = moment().format("YYYY-MM-DD");

  const durationLabels: any = [
    {
      value: "0",
      label: t("duration.1"),
    },
    {
      value: "2",
      label: t("duration.2"),
    },
    {
      value: "4",
      label: t("duration.3"),
    },
    {
      value: "6",
      label: t("duration.4"),
    },
  ];

  return (
    <Box className="itemContainer">
      <Box display="flex" alignItems="center">
        <Typography className="itemTitle">
          {title}
          <span>
            <Whisper
              trigger="click"
              placement="autoVerticalEnd"
              speaker={
                <Popover
                  style={{
                    maxWidth: "300px",
                    width: "100%",
                  }}
                >
                  <AudioSupport
                    audioText={text}
                    showText={false}
                    width="fit-content"
                    padding="4px 0"
                  />
                  <Typography
                    className="textHelper"
                    // width="100%"
                    // maxWidth={{
                    //   xs: "300px",
                    //   md: "300px",
                    // }}
                  >
                    {text}
                  </Typography>
                </Popover>
              }
            >
              <Avatar
                src={infoIcon}
                alt=""
                sx={{
                  marginLeft: "0.5rem",
                  width: 24,
                  height: 24,
                  cursor: "pointer",
                }}
              />
            </Whisper>
          </span>
        </Typography>
      </Box>
      <Box className="itemContent">
        {type === "text" && (
          <input
            name="responsible"
            placeholder={t("action-planning.10")}
            className="input"
            value={selectedValue}
            onChange={onChange}
          />
        )}
        {type === "select" && (
          // <SelectPicker
          //   data={durationLabels?.map(({ label, value }: any) => {
          //     return {
          //       label,
          //       value,
          //     };
          //   })}
          //   placeholder="How long"
          //   value={selectedValue}
          //   onChange={onChange}
          // />
          <Select
            className="select"
            value={selectedValue}
            onChange={onChange}
            placeholder="How long"
            style={{ width: "300px" }}
          >
            {durationLabels
              ?.filter((duration: any) =>
                mode === "production" ? duration.value !== "0" : duration
              )
              ?.map((option: any) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
          </Select>
        )}
        {type === "date" && (
          <input
            type="date"
            name="startDate"
            placeholder="Start Date"
            className="input"
            value={selectedValue}
            onChange={onChange}
            min={today}
          />
        )}
      </Box>
    </Box>
  );
};

export default ActionItem;
