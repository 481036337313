import React, { useEffect } from 'react';
import { AlertColor, Box, Button, Typography } from '@mui/material';
import './EmailVerification.scss';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthResendEmailVerification } from '../../features/auth/asyncAction';
import CustomAlert from '../../components/custom-alert/CustomAlert';
import LanguageSupport from '../../components/language-support/LanguageSupport';
import { store } from '../../app/store';
import {
  showMember,
  updateShowMember,
} from '../../features/global/globalSlice';
import { useAppSelector } from '../../app/hooks';

const EmailVerificationPage = () => {
  const { t } = useTranslation();
  const { roleId, groupId } = useParams();
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [messageType, setMessageType] = React.useState<AlertColor>('error');
  const showMemberStatus = useAppSelector(showMember);

  const resendEmailVerificationHandler = async (ev: any) => {
    ev.preventDefault();
    const resp = await AuthResendEmailVerification({ roleId, groupId });
    setMessage(resp.message);
    setShowMessage(true);
    setMessageType(resp.status);
  };

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <LanguageSupport showTranslateComponent />
      <Box className="emailVerificationPage">
        <Typography className="firstTypo">
          {t('email-verification.1')}
        </Typography>
        <Typography className="secondTypo">
          {t('email-verification.2')}
        </Typography>
        <Typography className="thirdTypo">
          {t('email-verification.4')}
        </Typography>
        <Typography className="thirdTypo">
          {t('email-verification.5')}
        </Typography>
        <Button
          className="btn"
          variant="contained"
          onClick={resendEmailVerificationHandler}
        >
          {t('common.22')}
        </Button>
        <Typography className="fourthTypo">
          {t('email-verification.6')}
        </Typography>
      </Box>
    </>
  );
};

export default EmailVerificationPage;
