import { Box, Typography } from "@mui/material";
import React from "react";
import {
  checkLetterCaseInPassword,
  checkNumberSymbolInPassword,
  checkPasswordLength,
} from "../../utils/common/checkValidator";
import { CheckCircle, Error } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type Props = {
  password: string;
};

const PasswordValidation = ({ password }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      bgcolor="#e8eaf4"
      padding="16px 10px"
      margin="4px 0"
      borderRadius="10px"
    >
      <Typography marginBottom="10px">{t("sign-up.16")}</Typography>
      <Typography
        display="flex"
        gap="6px"
        alignItems="start"
        marginLeft="0.5rem"
        marginBottom="4px"
      >
        {checkLetterCaseInPassword(password) ? (
          <CheckCircle style={{ color: "green" }} />
        ) : (
          <Error style={{ color: "red" }} />
        )}
        {t("sign-up.17")}
      </Typography>
      <Typography
        display="flex"
        gap="6px"
        alignItems="start"
        marginLeft="0.5rem"
        marginBottom="4px"
      >
        {checkNumberSymbolInPassword(password) ? (
          <CheckCircle style={{ color: "green" }} />
        ) : (
          <Error style={{ color: "red" }} />
        )}
        {t("sign-up.18")}
      </Typography>
      <Typography
        display="flex"
        gap="6px"
        alignItems="start"
        marginLeft="0.5rem"
        marginBottom="4px"
      >
        {checkPasswordLength(password) ? (
          <CheckCircle style={{ color: "green" }} />
        ) : (
          <Error style={{ color: "red" }} />
        )}
        {t("sign-up.19")}
      </Typography>
    </Box>
  );
};

export default PasswordValidation;
