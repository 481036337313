import { Box, Stepper, Step, StepLabel, Typography } from '@mui/material';
import * as React from 'react';
import './CustomStepper.scss';
import { useTranslation } from 'react-i18next';

interface CustomStepperProps {
  activeStep: number;
}

const CustomStepper: React.FC<CustomStepperProps> = ({ activeStep = 0 }) => {
  const { t } = useTranslation();

  const steps = [
    t('steps.1'),
    t('steps.2'),
    t('steps.3'),
    t('steps.4'),
    t('steps.5'),
  ];
  return (
    <>
      <Box
        className="stepper"
        sx={{
          display: { xs: 'flex', md: 'flex', lg: 'flex' },
        }}
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ width: '100%' }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                <Box
                  display={{ xs: 'none', md: 'flex' }}
                  justifyContent="center"
                >
                  {label}
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};

export default CustomStepper;
