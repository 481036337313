import React, { useState } from "react";
import { Box, Stepper, StepLabel, Step } from "@mui/material";
import "./Assessment.scss";
import CustomStepper from "../../components/customStepper/CustomStepper";
import { useLocation, useNavigate } from "react-router-dom";
import { DoneRounded } from "@mui/icons-material";
import queryString from "query-string";
import { Switch, Case } from "../../components/switch/Switch";
import DietContent from "./diet/DietContent";
import FeedingContent from "./feeding/FeedingContent";
import BrushingContent from "./brushing/BrushingContent";
import FluorideContent from "./fluoride/FluorideContent";
import DentalHomeContent from "./dental-home/DentalHomeContent";
import OralHealthContent from "./oral-health/OralHealthContent";
import { getCurrentMember } from "../../features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { getFiltredQuestionsChoices } from "../../features/user/asyncActions";
import { useTranslation } from "react-i18next";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";
import {
  getSelectedAnswers,
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import DietContainer from "./new-diet/DietContainer";

interface Question {
  questionID: string;
  questionText?: string;
  supportingText?: string;
}

type QuestionsType = Question | null;
interface SearchProps {
  assessmentStep?: number;
  type?: string;
}

const Assessment = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { assessmentStep, type }: SearchProps = queryString.parse(search, {
    parseNumbers: true,
  });
  const [selectedQuest, setSelectedQuest] = React.useState<QuestionsType>({
    questionID: "1",
  });

  const memberData = useAppSelector(getCurrentMember);

  const [currentAssessmentStep, setCurrentAssessmentStep] = useState<any>(
    assessmentStep ? assessmentStep : 0
  );
  const [dietList, setDietList] = useState([]);
  const [feedingList, setFeedingList] = useState([]);
  const [brushingList, setBrushingList] = useState([]);
  const [fluorideList, setFluorideList] = useState([]);
  const [dentalHomeList, setDentalHomeList] = useState([]);
  const [questionsWithChoices, setQuestionsWithChoices] = useState<any>([]);
  const [showFeedingIntro, setShowFeedingIntro] = React.useState(true);
  const showMemberStatus = useAppSelector(showMember);
  const selectedAnswers = useAppSelector(getSelectedAnswers);

  const allPreviousSelectedChoices = [
    ...dietList,
    ...feedingList,
    ...brushingList,
    ...fluorideList,
    ...dentalHomeList,
  ];

  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const steps = [
    t("assessment-steps.2"),
    t("assessment-steps.3"),
    t("assessment-steps.4"),
    t("assessment-steps.5"),
    t("assessment-steps.6"),
    t("assessment-steps.7"),
  ];

  const fetchQuestionsWithChoices = async () => {
    const data = await getFiltredQuestionsChoices(memberData.user_id);
    if (data) setQuestionsWithChoices(data);
  };

  React.useEffect(() => {
    const { assessmentStep: newStep } =
      queryString.parse(search, {
        parseNumbers: true,
      }) ?? {};
    if (newStep === undefined || newStep === null) {
      navigate("/assessment?assessmentStep=0", {
        replace: true,
      });
    } else {
      setCurrentAssessmentStep(newStep);
    }
  }, [search]);

  React.useEffect(() => {
    fetchQuestionsWithChoices();
    !showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport
        showBackArrowComponent
        showTranslateComponent
        width="100%"
      />
      <Box className="assessmentPage">
        <Box className="assessmentPage__contentContainer">
          <Box
            className={`subHeader ${
              currentAssessmentStep !== "0" ? "hideBorderBottom" : ""
            }`}
          >
            {currentAssessmentStep !== 0 && (
              <Stepper
                className="stepper stepper--main"
                activeStep={currentAssessmentStep}
              >
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepLabel
                      className={`step ${
                        currentAssessmentStep === index ? "step--active" : ""
                      } ${
                        currentAssessmentStep > index ? "step--previous" : ""
                      }`}
                    >
                      {currentAssessmentStep > index && (
                        <DoneRounded
                          sx={{ color: "#fff", marginRight: "0.5rem" }}
                        />
                      )}
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}

            {currentAssessmentStep !== 0 && (
              <Stepper
                className="stepper stepper--secondary"
                activeStep={currentAssessmentStep}
              >
                <Step />
                <Step key={1}>
                  <StepLabel
                    className="step step--active"
                    sx={{ width: "200px !important" }}
                  >
                    {steps[currentAssessmentStep]}
                  </StepLabel>
                </Step>
                <Step />
              </Stepper>
            )}
          </Box>
          <Box className="subMain">
            {currentAssessmentStep !== null &&
              currentAssessmentStep?.toString() && (
                <Switch test={currentAssessmentStep} defaultComponent={<div />}>
                  <Case value={0}>
                    {/* <DietContent
                      questionsWithChoices={questionsWithChoices?.diet}
                      setDietList={setDietList}
                      setSelectedQuest={setSelectedQuest}
                      selectedQuest={selectedQuest}
                    /> */}
                    <DietContainer />
                  </Case>
                  <Case value={1}>
                    <FeedingContent
                      questionsWithChoices={questionsWithChoices?.feeding}
                      setFeedingList={setFeedingList}
                      selectedQuest={selectedQuest}
                      setSelectedQuest={setSelectedQuest}
                      showFeedingIntro={showFeedingIntro}
                      setShowFeedingIntro={setShowFeedingIntro}
                    />
                  </Case>
                  <Case value={2}>
                    <BrushingContent
                      setBrushingList={setBrushingList}
                      questionsWithChoices={questionsWithChoices?.brushing}
                      setSelectedQuest={setSelectedQuest}
                      selectedQuest={selectedQuest}
                    />
                  </Case>
                  <Case value={3}>
                    <FluorideContent
                      questionsWithChoices={questionsWithChoices?.fluoride}
                      setFluorideList={setFluorideList}
                      setSelectedQuest={setSelectedQuest}
                      selectedQuest={selectedQuest}
                    />
                  </Case>
                  <Case value={4}>
                    <DentalHomeContent
                      questionsWithChoices={
                        questionsWithChoices?.dentalProvider
                      }
                      setDentalHomeList={setDentalHomeList}
                      setSelectedQuest={setSelectedQuest}
                      selectedQuest={selectedQuest}
                    />
                  </Case>
                  <Case value={5}>
                    <OralHealthContent
                      questionsWithChoices={questionsWithChoices?.caregiver}
                      allPreviousSelectedChoices={allPreviousSelectedChoices}
                      setSelectedQuest={setSelectedQuest}
                      selectedQuest={selectedQuest}
                    />
                  </Case>
                </Switch>
              )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={1} />
      </Box>
    </>
  );
};

export default Assessment;
