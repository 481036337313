import React, { useEffect } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "./ManagerAccount.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getUserData } from "../../../features/auth/authSlice";
import {
  fetchAllSites,
  fetchManagersUnderSites,
  getManagersUnderSites,
} from "../../../features/manager/managerSlice";
import { store } from "../../../app/store";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";

const ManagerAccount = () => {
  const { t } = useTranslation();
  const userData = useAppSelector(getUserData);
  const navigate = useNavigate();
  const managersUnderSites = useAppSelector(getManagersUnderSites);
  const showMemberStatus = useAppSelector(showMember);

  const fetchSites = async () => {
    const data = await store.dispatch(fetchAllSites({}));
    return data;
  };
  const fetchManagersSites = async () => {
    const data = await store.dispatch(
      fetchManagersUnderSites(userData.group_id)
    );
    return data;
  };

  useEffect(() => {
    fetchSites();
    fetchManagersSites();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="managerAccountPage">
        <Box className="managerAccountPage__contentContainer">
          <Box className="subHeader">
            <Box
              display="flex"
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              flexDirection={{ xs: "column", sm: "row" }}
              columnGap="6rem"
              rowGap="1rem"
            >
              {userData?.first_Name && userData.last_name && (
                <Typography fontSize="1.5rem" color="#07120D">
                  {userData.first_Name} {userData.last_name}
                </Typography>
              )}
              <Typography fontSize="1.5rem" color="#07120D">
                ID : {userData?.group_id}
              </Typography>
            </Box>

            <Button
              variant="contained"
              className="btn containedBtn"
              onClick={() => navigate("/edit-manager-account")}
            >
              {t("common.6")}
            </Button>
          </Box>
          <Typography className="subTitle__info">
            {t("user-account.2")}
          </Typography>
          <Box className="subMain">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.3")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.first_Name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.4")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.last_name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.5")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.phone_number || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.6")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData?.email || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.7")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    ***********
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* <Typography className="subTitle__info">
            {t("pc-account.6")}
          </Typography> */}

          {/* <Box maxWidth="600px" margin="1rem 0" borderRadius="10px">
            <TableContainer
              component={Paper}
              sx={{ maxWidth: 800, padding: '1rem' }}
            >
              <Table
                sx={{ maxWidth: 800 }}
                size="small"
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t('pc-account.7')}</TableCell>
                    <TableCell align="left">{t('pc-account.8')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {managersUnderSites?.map((site) => (
                    <TableRow
                      key={site.sites_id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="site" align="center">
                        {t('navigation.6')} {site.sites_id}
                      </TableCell>
                      <TableCell align="left">
                        {site.managers?.join(', ')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default ManagerAccount;
