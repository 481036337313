import * as React from 'react';
import { Box, TextField, Typography, Button, Modal } from '@mui/material';
import './ForgotPasswordModal.scss';
import { useTranslation } from 'react-i18next';
import { teethIcon } from '../../utils/common/global';
interface ForgotPasswordModalProps {
  open: boolean;
  closeHandler: () => void;
  submitHandler?: (ev: any) => void;
  email?: string;
  setEmail: (value: string) => void;
}

const ForgotPasswordModal: React.FunctionComponent<
  ForgotPasswordModalProps
> = ({ open, email, setEmail, closeHandler, submitHandler }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Box className="customModal">
          <Box>
            <img src={teethIcon} alt="" className="customModal__img" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ fontSize: '26px', color: '#285CB8' }}
            >
              {t('sign-in.7')}
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontSize: '20px',
                color: '#787D86',
                textAlign: 'center',
              }}
            >
              {t('sign-in.11')}
            </Typography>
            <Typography
              sx={{
                fontSize: '20px',
                color: '#787D86',
                textAlign: 'center',
              }}
            >
              {t('sign-in.12')}
            </Typography>
            <Typography sx={{ mt: 2, fontSize: '16px', color: '#787D86' }}>
              {t('sign-in.13')}
            </Typography>
            <TextField
              id="outlined-basic"
              label={t('sign-in.14')}
              variant="outlined"
              placeholder="example@email.com"
              value={email}
              onChange={(ev: any) => setEmail(ev.target.value)}
              sx={{
                width: '100%',
                margin: '1rem 0',
              }}
            />
            <Button
              fullWidth
              variant="contained"
              className="btn"
              onClick={submitHandler}
            >
              {t('sign-in.15')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ForgotPasswordModal;
