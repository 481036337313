import { Box, Button } from '@mui/material';
import * as React from 'react';
import './CustomBackButton.scss';
import { useNavigate } from 'react-router-dom';

interface CustomBackButtonProps {
  btnContent: string;
  btnUrl: any;
}

const CustomBackButton: React.FunctionComponent<CustomBackButtonProps> = ({
  btnContent,
  btnUrl,
}) => {
  const navigate = useNavigate();
  return (
    <Box className="customBackBtnContainer">
      <Button
        className="customBtn"
        variant="contained"
        onClick={() => navigate(btnUrl ? btnUrl : '')}
      >
        {btnContent}
      </Button>
    </Box>
  );
};

export default CustomBackButton;
