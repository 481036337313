import * as React from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  MenuItem,
  Avatar,
} from "@mui/material";
import "./PCrelationInfo.scss";
import { useTranslation } from "react-i18next";
import CustomStepper from "../../components/customStepper/CustomStepper";
import { useNavigate } from "react-router-dom";
import { store } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import { checkEmpty } from "../../utils/common/checkEmpty";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import { addPcByFamily, saveZipcode } from "../../features/user/userSlice";
import { getUserData, loadUserData } from "../../features/auth/authSlice";
import { Popover, Whisper } from "rsuite";
import { infoIcon } from "../../utils/common/global";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import AudioSupport from "../../components/audio-support/AudioSupport";
import {
  fetchAllCounselors,
  fetchAllProviders,
} from "../../features/PC/asyncActions";

const PcRelationInfo: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const [allProviders, setAllProviders] = React.useState([]);
  const [allCounselors, setAllCounselors] = React.useState([]);
  const [selectedProvider, setSelectedProvider] = React.useState<string>("");
  const [selectedCounselor, setSelectedCounselor] = React.useState<string>("");
  const [showError, setShowError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [zipCode, setZipCode] = React.useState("");
  const showMemberStatus = useAppSelector(showMember);

  const getAllProviders = async () => {
    const { data } = await fetchAllProviders(userData?.authorized_code);
    setAllProviders(data);
  };
  const getAllCounselors = async () => {
    const { data } = await fetchAllCounselors(userData?.authorized_code);
    setAllCounselors(data);
  };

  React.useEffect(() => {
    getAllProviders();
    getAllCounselors();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  const selectedProviderHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedProvider(event.target.value);
  };

  const selectedCounselorHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedCounselor(event.target.value);
  };

  const zipCodeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipCode(event.target.value);
  };

  const submitHundler = async (ev: any) => {
    ev.preventDefault();
    const state = checkEmpty({ zipCode });
    if (state) {
      selectedProvider &&
        (await store.dispatch(
          addPcByFamily({
            from_id: userData.group_id,
            to_id: selectedProvider,
          })
        ));

      selectedCounselor &&
        (await store.dispatch(
          addPcByFamily({
            from_id: userData.group_id,
            to_id: selectedCounselor,
          })
        ));
      await store.dispatch(
        saveZipcode({ group_id: userData.group_id, zip_code: zipCode })
      );
      await store.dispatch(loadUserData(userData.group_id));
      navigate("/signup-new/follow-up-info");
    } else {
      setShowError(true);
      setErrorMessage(t("common.16"));
    }
  };

  return (
    <>
      <CustomAlert
        type="error"
        open={showError}
        closeHandler={() => setShowError(false)}
        message={errorMessage}
      />
      <LanguageSupport showTranslateComponent />
      <Box className="pcRelationInfoPage">
        <Box sx={{ marginTop: "1rem" }}>
          <Typography
            className="pcRelationInfoPage__title"
            variant="h3"
            sx={{
              fontWeight: "600",
              margin: "0 auto",
            }}
          >
            {t("pc-relation-info.1")}
          </Typography>
        </Box>
        <Box className="pcRelationInfoPage__formContainer">
          <Box component="form" noValidate autoComplete="off" className="form">
            <FormControl className="form__formControl">
              <Box className="label">
                <Typography sx={{ fontSize: "1.3rem" }}>
                  {t("pc-relation-info.2")}
                </Typography>
                <Whisper
                  trigger="click"
                  placement="bottomEnd"
                  speaker={
                    <Popover>
                      <AudioSupport
                        showText={false}
                        width="fit-content"
                        padding="4px 0"
                        audioText={t("pc-relation-info.7")}
                      />
                      <Typography variant="body1" maxWidth="320px" width="100%">
                        {t("pc-relation-info.7")}
                      </Typography>
                    </Popover>
                  }
                >
                  <Avatar
                    src={infoIcon}
                    alt=""
                    sx={{
                      marginLeft: "0.5rem",
                      width: 24,
                      height: 24,
                      cursor: "pointer",
                    }}
                  />
                </Whisper>
              </Box>
              <TextField
                id="provider"
                select
                label={t("pc-relation-info.3")}
                value={selectedProvider}
                onChange={selectedProviderHandler}
              >
                <MenuItem key={0} value={""} style={{ color: "#ccc" }}>
                  {t("site-details.5")}
                </MenuItem>
                {allProviders?.map(({ group_id, first_Name, last_name }) => (
                  <MenuItem key={group_id} value={group_id}>
                    {first_Name} {last_name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl className="form__formControl">
              <Box className="label">
                <Typography sx={{ fontSize: "1.3rem" }}>
                  {t("pc-relation-info.4")}
                </Typography>
                <Whisper
                  trigger="click"
                  placement="bottomEnd"
                  speaker={
                    <Popover>
                      <AudioSupport
                        showText={false}
                        width="fit-content"
                        padding="4px 0"
                        audioText={t("pc-relation-info.8")}
                      />
                      <Typography variant="body1" maxWidth="320px" width="100%">
                        {t("pc-relation-info.8")}
                      </Typography>
                    </Popover>
                  }
                >
                  <Avatar
                    src={infoIcon}
                    alt=""
                    sx={{
                      marginLeft: "0.5rem",
                      width: 24,
                      height: 24,
                      cursor: "pointer",
                    }}
                  />
                </Whisper>
              </Box>
              <TextField
                id="counselor"
                select
                label={t("pc-relation-info.5")}
                value={selectedCounselor}
                onChange={selectedCounselorHandler}
              >
                <MenuItem key={0} value={""} style={{ color: "#ccc" }}>
                  {t("site-details.6")}
                </MenuItem>
                {allCounselors?.map(({ group_id, first_Name, last_name }) => (
                  <MenuItem key={group_id} value={group_id}>
                    {first_Name} {last_name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl className="form__formControl">
              <Box className="label">
                <Typography sx={{ fontSize: "1.3rem" }}>
                  {t("pc-relation-info.6")}
                  <span className="text-danger"> *</span>
                </Typography>
              </Box>
              <TextField
                id="zipcode"
                label="Zip Code"
                placeholder="Zip Code"
                type="number"
                required
                error={showError && !zipCode ? true : false}
                value={zipCode}
                onChange={zipCodeHandler}
              ></TextField>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              variant="contained"
              className="saveBtn"
              onClick={submitHundler}
            >
              {t("common.7")}
            </Button>
            {/* <Button
              className="skipBtn"
              onClick={() => navigate("/signup-new/follow-up-info")}
            >
              {t("common.8")}
            </Button> */}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={0} />
      </Box>
    </>
  );
};

export default PcRelationInfo;
