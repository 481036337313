import customAxios from "../../utils/common/useAxios";

export const getMember = async (memberId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/users/getFamilyMember/${memberId}`,
    data: {},
  });
  return response;
};

export const updateMember = async ({ memberId, memberData }: any) => {
  const response = await customAxios({
    method: "PUT",
    url: `/users/updateFamilyMember/${memberId}`,
    data: memberData,
  });
  return response;
};
export const deleteMember = async (memberId: any) => {
  const response = await customAxios({
    method: "DELETE",
    url: `/users/deleteFamilyMember/${memberId}`,
    data: {},
  });
  return response;
};

export const getPreviousPlans = async (memberId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/plans/getPreviousPlansByMemberId/${memberId}`,
    data: {},
  });
  return response;
};
export const getCurrentActivePlan = async (memberId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/plans/getCurrentActivePlanByMemberId/${memberId}`,
    data: {},
  });
  return response;
};

export const getPlanByPlanId = async (planId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/plans/getMemberPlanByPlanId/${planId}`,
    data: {},
  });
  return response;
};

export const savePhoneNumber = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/users/savePhoneNumber",
    data: body,
  });
  return response;
};
export const saveNotificationFlag = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: "users/saveNotifFlag",
    data: body,
  });
  return response;
};

export const addFamilyMember = async ({ groupId, data }: any) => {
  const response = await customAxios({
    method: "POST",
    url: `users/addFamilyMember/${groupId}`,
    data,
  });
  return response;
};

export const getFamilyData = async (groupId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `group/loadUserData/${groupId}`,
    data: {},
  });
  return response.data;
};

export const getFoodOrDrinksId = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/foodOrDrinks/getFoodOrDrinksId`,
    data: body,
  });
  return response.data;
};

export const getFiltredQuestionsChoices = async (memberId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/questions/getFiltredQuestionsWithChoices/${memberId}`,
    data: {},
  });
  return response.data;
};

export const saveAssessmentResult = async ({
  memberId,
  selectedChoices,
}: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/results/createNewResult/${memberId}`,
    data: { selectedChoices },
  });
  return response.data;
};

export const getAllRiskAreas = async (memberId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/riskAreas/allRiskAreas/${memberId}`,
    data: {},
  });
  return response.data;
};

export const getRiskAreasByMemberId = async (memberId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/riskAreas/getRiskAreasForMember/${memberId}`,
    data: {},
  });
  return response.data;
};

export const getAllGoalsByRiskId = async (riskId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/goals/allGoalsByRiskId/${riskId}`,
    data: {},
  });
  return response.data;
};

export const createNewPlan = async ({ memberId, goalId, ...body }: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/plans/createNewPlan/${memberId}/${goalId}`,
    data: {
      ...body,
    },
  });
  return response;
};

export const notificationSchedule = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/users/notificationSchedule/`,
    data: body,
  });
  return response;
};

export const sendFirstNotif = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/users/sendFirstNotif/`,
    data: body,
  });
  return response;
};

export const redoAssessment = async ({ memberId, planId }: any) => {
  const response = await customAxios({
    method: "PUT",
    url: `/plans/redoAssessment/${memberId}/${planId}`,
    data: {},
  });
  return response;
};

export const fetchPcsByFamilyId = async ({ fromId, roleId }: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/group/getPCsByfamilyId/${fromId}/${roleId}`,
    data: {},
  });
  return response;
};

export const addMultiplePcsByFamily = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/users/addMultiplePcsByFamily/`,
    data: body,
  });
  return response;
};
export const saveFollowUpResult = async ({ planId, result }: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/users/saveFollowUpResult/`,
    data: { planId, result },
  });
  return response;
};

export const updatePlanToIsPrevious = async ({ planId, result }: any) => {
  const response = await customAxios({
    method: "PUT",
    url: `/plans/updatePlanStatus`,
    data: {
      planId,
      result,
    },
  });
  return response;
};

export const createAssessmentRecord = async ({ planId, memberId }: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/plans/createAssessmentRecord`,
    data: {
      planId,
      memberId,
    },
  });
  return response;
};
export const getAssessmentRecords = async () => {
  const response = await customAxios({
    method: "GET",
    url: `/plans/getAssessmentRecords`,
    data: {},
  });
  return response;
};
export const goalsByMemberId = async (memberId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/goals/getGoalsByMemberId/${memberId}`,
    data: {},
  });
  return response;
};
export const updateIsMoveStatus = async (groupId: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/group/updateIsMoveStatus/${groupId}`,
    data: {},
  });
  return response;
};

export const updateUserLanguage = async (groupId: any, lanId: number) => {
  const response = await customAxios({
    method: "POST",
    url: `/group/updateLanguage/${groupId}`,
    data: {
      lanId,
    },
  });
  return response;
};
export const updatePlanToCompleted = async (planId: any) => {
  const response = await customAxios({
    method: "PUT",
    url: `/plans/updatePlanToCompleted/${planId}`,
    data: {},
  });
  return response;
};

export const removeInactivePlanFromRemaining = async (planId: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/plans/removeInactivePlan/`,
    data: {
      planId,
    },
  });
  return response;
};

export const updateMemberTrackingStatus = async (
  memberId: any,
  newStatus: number
) => {
  const response = await customAxios({
    method: "POST",
    url: `/users/updateMemberTrackingStatus/${memberId}`,
    data: {
      newStatus,
    },
  });
  return response;
};
