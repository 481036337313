import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import useAxios from "../../utils/common/useAxios";
// import { RootState, AppThunk } from '../../app/store';

interface AuthState {
  userData: any;
  message: string;
  token: string | null;
  licenseExpiredDate: string | null;
}

export const authInitialState: AuthState = {
  userData: {},
  message: "",
  token: null,
  licenseExpiredDate: "",
};

export const authSignIn = createAsyncThunk("auth/signIn", async (body: any) => {
  const response = await useAxios({
    method: "POST",
    url: "/auth/signIn",
    data: body,
  });
  return response;
});

export const ressetPassword = createAsyncThunk(
  "auth/ressetPassword",
  async (body: any) => {
    const data = await useAxios({
      method: "POST",
      url: "/auth/ressetPassword",
      data: body,
    });
    return data;
  }
);
export const emailVerification = createAsyncThunk(
  "auth/emailVerification",
  async (body: any) => {
    const data = await useAxios({
      method: "POST",
      url: "/auth/emailVerification",
      data: body,
    });
    return data;
  }
);
export const loadUserData = createAsyncThunk(
  "auth/loadUserData",
  async (groupId: any) => {
    const data = await useAxios({
      method: "GET",
      url: `/group/loadUserData/${groupId}`,
      data: {},
    });
    return data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {},
  extraReducers: {
    [authSignIn.fulfilled as any]: (state, action) => {
      const { message, data, token, licenseExpiredDate } = action.payload;
      state.userData = data;
      state.message = message;
      state.token = token;
      state.licenseExpiredDate = licenseExpiredDate;
      localStorage.setItem("access-token", token);
    },
    [loadUserData.fulfilled as any]: (state, action) => {
      const { message, data } = action.payload;
      state.userData = data;
      state.message = message;
    },
  },
});

export const getUser = (state: RootState) => state.auth;
export const getLicenseExpiredDate = (state: RootState) =>
  state.auth.licenseExpiredDate;
export const getUserData = (state: RootState) => state.auth.userData;
export default authSlice.reducer;
