import * as React from "react";
import { Box, Typography, Button, AlertColor } from "@mui/material";
import "./DentalHomeContent.scss";
import { infoIcon, teethIcon } from "../../../utils/common/global";
import CustomAlert from "../../../components/custom-alert/CustomAlert";
import { useTranslation } from "react-i18next";
import {
  getSelectedAnswers,
  updateSelectedAnswers,
} from "../../../features/global/globalSlice";
import { useAppSelector } from "../../../app/hooks";
import { useWidthSize } from "../../../utils/hooks/resizeHook";
import ConfirmationModal from "../../../components/confirmation-modal/ConfirmationModal";
import CustomSupportText from "../../../components/custom-support-text/CustomSupportText";
import CustomHint from "../../../components/custom-hint/CustomHint";
import { useNavigate } from "react-router-dom";
import { store } from "../../../app/store";
import AudioSupport from "../../../components/audio-support/AudioSupport";

interface DentalHomeContentProps {
  setDentalHomeList?: (val: any) => void;
  questionsWithChoices?: Array<any>;
  selectedQuest: any;
  setSelectedQuest: (val: any) => void;
}

const DentalHomeContent = ({
  setDentalHomeList,
  questionsWithChoices,
  selectedQuest,
  setSelectedQuest,
}: DentalHomeContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const customWidth = useWidthSize();
  const [showBubbleInfo, setShowBubbleInfo] = React.useState(false);
  const [showModalInfo, setShowModalInfo] = React.useState(false);
  const [selectedChoices, setSelectedChoices] = React.useState<any>([]);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const selectedAnswers = useAppSelector(getSelectedAnswers);

  const selectedChoicesHandler = (id: string, selectedChoice: any) => {
    const foundedChoice = selectedChoices.find(
      (choice: any) => choice.questionID === id
    );
    if (foundedChoice) {
      const newSelectedChoices = selectedChoices.filter(
        (choice: any) => choice.questionID !== id
      );
      if (foundedChoice.choiceID !== selectedChoice.choiceID) {
        setSelectedChoices([
          ...newSelectedChoices,
          { questionID: id, ...selectedChoice },
        ]);
      } else {
        setSelectedChoices([...newSelectedChoices]);
      }
    } else {
      setSelectedChoices([
        ...selectedChoices,
        { questionID: id, ...selectedChoice },
      ]);
    }
  };

  const selectedMultipleChoicesHandler = (id: string, selectedChoice: any) => {
    const questionChoices = selectedChoices.filter(
      (choice: any) => choice.questionID === id
    );
    const foundedChoice = questionChoices.find(
      (choice: any) =>
        choice.choiceID && choice.choiceID === selectedChoice.choiceID
    );
    if (foundedChoice) {
      const newSelectedChoices = selectedChoices.filter(
        (choice: any) =>
          !choice.choiceID || choice.choiceID !== selectedChoice.choiceID
      );
      setSelectedChoices([...newSelectedChoices]);
    } else if (selectedChoice.choiceText === "other") {
      const newSelectedChoices = selectedChoices.filter(
        (choice: any) => choice.questionID !== id
      );
      setSelectedChoices([
        ...newSelectedChoices,
        { questionID: id, ...selectedChoice },
      ]);
    } else {
      const newSelectedChoices = selectedChoices.filter(
        (choice: any) => choice.choiceID && choice.choiceText !== "other"
      );
      setSelectedChoices([
        ...newSelectedChoices,
        { questionID: id, ...selectedChoice },
      ]);
    }
  };

  const continueHandler = async (ev: any) => {
    ev.preventDefault();
    if (
      questionsWithChoices &&
      questionsWithChoices?.length <= selectedChoices.length
    ) {
      const NewSelectedChoices = selectedChoices
        .filter((choice: any) => choice.choiceID !== 0)
        .map((choice: any) => choice.choiceID)
        .sort((a: any, b: any) => a - b);
      setDentalHomeList?.(NewSelectedChoices);
      store.dispatch(
        updateSelectedAnswers({
          ...selectedAnswers,
          dentalHomeAnswers: selectedChoices,
          dentalHome: NewSelectedChoices,
        })
      );
      navigate("/assessment?assessmentStep=5");
    } else {
      setShowMessage(true);
      setMessage(t("common.23"));
      setMessageType("error");
    }
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    selectedAnswers?.dentalHomeAnswers &&
      setSelectedChoices(selectedAnswers.dentalHomeAnswers);
    setDentalHomeList?.(
      selectedAnswers?.dentalHome ? selectedAnswers?.dentalHome : []
    );
  }, []);

  return (
    <Box className="dentalHomeContainer">
      <ConfirmationModal
        title=""
        open={showModalInfo}
        closeHandler={() => {
          setShowModalInfo(false);
          setSelectedQuest(null);
        }}
        showCancelButton={false}
        showSubmitButton={false}
      >
        <Box className="bubbleText">
          <CustomSupportText questionId={selectedQuest?.questionID} />
        </Box>
      </ConfirmationModal>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <>
        <Box marginTop="2rem">
          <CustomHint
            text={t("dental-home.1")}
            audioText={t("dental-home.1")}
            bottom="-50px"
            left="-50px"
          />
        </Box>
        <Box className="content">
          <Box className="dentalHomeContent">
            {questionsWithChoices?.map((quest) => (
              <Box className="questionBox" key={quest.questionID}>
                <AudioSupport
                  audioText={t(`questions.${quest.questionID}`)}
                  width="fit-content"
                />
                <Typography className="questionTitle">
                  {t(`questions.${quest.questionID}`)}
                </Typography>
                {selectedQuest?.questionID !== quest.questionID && (
                  <img
                    src={infoIcon}
                    alt=""
                    width={30}
                    height={30}
                    style={{
                      objectFit: "contain",
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (customWidth > 1100) {
                        setShowBubbleInfo(true);
                      } else {
                        setShowModalInfo(true);
                      }
                      setSelectedQuest(quest);
                    }}
                  />
                )}
                <Box className="choicesBtnContainer">
                  {quest?.choices.map((choice: any) => (
                    <Button
                      key={choice.choiceID}
                      className={`choiceBtn ${
                        selectedChoices.find(
                          (item: any) =>
                            item.questionID === quest.questionID &&
                            item.choiceID === choice.choiceID
                        ) && "choiceBtn--selected"
                      }`}
                      onClick={() =>
                        quest.questionID === 18
                          ? selectedMultipleChoicesHandler(
                              quest.questionID,
                              choice
                            )
                          : selectedChoicesHandler(quest.questionID, choice)
                      }
                    >
                      {t(`choices.${choice.choiceID}`)}
                    </Button>
                  ))}
                  {quest.questionID === 18 && (
                    <Button
                      key={0}
                      className={`choiceBtn ${
                        selectedChoices.find(
                          (item: any) =>
                            item.questionID === quest.questionID &&
                            item.choiceID === 0
                        ) && "choiceBtn--selected"
                      }`}
                      onClick={() =>
                        selectedChoicesHandler(quest.questionID, {
                          choiceID: 0,
                          choiceText: "Not applicable",
                          questionID: 18,
                        })
                      }
                    >
                      {t("choices.119")}
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            className="bubblesContainer"
            display={showBubbleInfo ? "block" : "none"}
          >
            {questionsWithChoices?.map((quest) => (
              <Box
                key={quest?.questionID}
                className="bubbleTextContainer"
                visibility={
                  selectedQuest?.questionID === quest?.questionID
                    ? "inherit"
                    : "hidden"
                }
              >
                <img
                  src={infoIcon}
                  alt=""
                  width={30}
                  height={30}
                  style={{
                    objectFit: "contain",
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowBubbleInfo(false);
                    setSelectedQuest(null);
                  }}
                />
                <Box className="bubbleText">
                  <CustomSupportText questionId={quest?.questionID} />
                </Box>
                <img src={teethIcon} alt="" />
              </Box>
            ))}
          </Box>
        </Box>
        <Button
          className="btn"
          variant="contained"
          onClick={(ev) => continueHandler(ev)}
        >
          {t("common.9")}
        </Button>
      </>
    </Box>
  );
};

export default DentalHomeContent;
