import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./ExpiredLicensePage.scss";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getUserData } from "../../features/auth/authSlice";
import { store } from "../../app/store";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";

const ExpiredLicensePage = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("access-token");
  const userData = useAppSelector(getUserData);
  const showMemberStatus = useAppSelector(showMember);

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  useEffect(() => {
    // if (!authToken || !userData?.role_id || !userData?.isMove)
    if (!authToken || !userData?.role_id) navigate("/sign-in");
  }, []);

  return (
    <Box className="ExpiredLicensePage">
      <Typography className="secondTypo">License has been expired</Typography>
    </Box>
  );
};

export default ExpiredLicensePage;
