import * as React from 'react';
import './MainLogo.scss';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { mySmileBuddyIcon, teethIcon } from '../../utils/common/global';

const MainLogo: React.FC = () => {
  return (
    <Link to="/">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: '0.5rem',
        }}
      >
        <img src={teethIcon} alt="" className="toothLogo" />
        <img src={mySmileBuddyIcon} alt="" className="textLogo" />
      </Box>
    </Link>
  );
};

export default MainLogo;
