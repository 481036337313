import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Input,
  AlertColor,
  Grid,
  TextField,
} from "@mui/material";
import "./EditProviderAccount.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getUserData, loadUserData } from "../../../features/auth/authSlice";
import {
  fetchAllSites,
  fetchSitesBymId,
} from "../../../features/manager/managerSlice";
import { store } from "../../../app/store";
import {
  excludePcFromSite,
  updateUserInfo,
} from "../../../features/manager/asyncActions";
import { checkEmpty } from "../../../utils/common/checkEmpty";
import CustomAlert from "../../../components/custom-alert/CustomAlert";
import DeleteSiteModal from "../../../components/delete-site-modal/DeleteSiteModal";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import { authUpdatePassword } from "../../../features/auth/asyncAction";
import ConfirmationModal from "../../../components/confirmation-modal/ConfirmationModal";
import { validPassword } from "../../../utils/common/checkValidator";
import PasswordValidation from "../../../components/password-validation/PasswordValidation";

const EditProviderAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const [passowrd, setPassowrd] = useState("***********");
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showDeleteSiteDialog, setShowDeleteSiteDialog] = useState(false);
  const showMemberStatus = useAppSelector(showMember);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const fetchSites = async () => {
    const data = await store.dispatch(fetchAllSites({}));
    return data;
  };

  const registerHandler = async (ev: any) => {
    ev.preventDefault();
    const data = {
      firstName,
      lastName,
      phoneNumber,
    };
    const state = checkEmpty(data);
    if (state) {
      await updateUserInfo({ managerId: userData.group_id, ...data });
      await store.dispatch(loadUserData(userData.group_id));
      navigate("/");
    } else {
      setShowMessage(true);
      setMessage(t("common.16"));
      setMessageType("error");
    }
  };

  const excludeSiteHandler = async (siteId: any) => {
    const { message, status } = await excludePcFromSite({
      pcId: userData.group_id,
      siteId,
    });
    await store.dispatch(fetchAllSites({}));
    await store.dispatch(fetchSitesBymId(userData.group_id));
    setShowMessage(true);
    setMessage(message);
    setMessageType(status);
    setShowErrorMessage(false);
    setShowDeleteSiteDialog(false);
    setSelectedSiteId("");
    await fetchSites();
  };

  const updatePasswordHandler = async (ev: any) => {
    ev.preventDefault();
    if (newPassword === confirmPassword) {
      const resp = await authUpdatePassword({
        groupId: userData.group_id,
        newPassword,
        oldPassword,
      });
      setShowMessage(true);
      setMessageType(resp.status);
      setMessage(resp.message);
      resp?.status === "success" && closeUpdatePasswordModal();
    } else {
      setShowMessage(true);
      setMessage("Password didn't match");
      setMessageType("error");
    }
  };

  const closeUpdatePasswordModal = () => {
    setShowChangePassword(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  useEffect(() => {
    fetchSites();
    setFirstName(userData?.first_Name ?? "");
    setlastName(userData?.last_name ?? "");
    setPhoneNumber(userData?.phone_number ?? "");
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <ConfirmationModal
        open={showChangePassword}
        title={"Change password "}
        closeHandler={closeUpdatePasswordModal}
        submitParams={{
          btnContent: "Save changes",
          submitHandler: updatePasswordHandler,
          disabledRules:
            validPassword(newPassword) &&
            oldPassword &&
            newPassword &&
            confirmPassword
              ? false
              : true,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="1.5rem"
        >
          <Box width="100%">
            <TextField
              label="Old Password"
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="standard"
            />
            <TextField
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="standard"
            />
            {newPassword && !validPassword(newPassword) && (
              <PasswordValidation password={newPassword} />
            )}
            <TextField
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="standard"
            />
          </Box>
        </Box>
      </ConfirmationModal>
      <DeleteSiteModal
        hideDelete={true}
        showError={showErrorMessage}
        open={showDeleteSiteDialog}
        closeHandler={() => {
          setShowDeleteSiteDialog(false);
          setShowErrorMessage(false);
          setSelectedSiteId("");
        }}
        removeHandler={() => excludeSiteHandler(selectedSiteId)}
      />
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="editProviderAccountPage">
        <Box className="editProviderAccountPage__contentContainer">
          <Box className="subHeader">
            <Box
              display="flex"
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              flexDirection={{ xs: "column", sm: "row" }}
              columnGap="6rem"
              rowGap="1rem"
              borderBottom="1px solid #e0e0e0"
            >
              {userData?.first_Name && userData?.last_name && (
                <Typography fontSize="1.5rem" color="#07120D">
                  {userData.first_Name} {userData.last_name}
                </Typography>
              )}
              <Typography fontSize="1.5rem" color="#07120D">
                ID : {userData?.group_id}
              </Typography>
            </Box>
          </Box>
          <Typography className="subTitle__info">
            {t("user-account.2")}
          </Typography>
          <Box className="subMain">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography
                    className={`title ${
                      !firstName && messageType === "error" ? "text-danger" : ""
                    }`}
                  >
                    {t("user-account.3")}
                    <span className="text-danger"> *</span>
                  </Typography>
                  <Input
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      opacity: "0.7",
                    }}
                    id="First Name"
                    placeholder={t("user-account.3")}
                    type="text"
                    value={firstName}
                    onChange={(ev) => setFirstName(ev.target.value)}
                    inputProps={{ "aria-label": "description" }}
                    error={!firstName && messageType === "error" ? true : false}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography
                    className={`title ${
                      !lastName && messageType === "error" ? "text-danger" : ""
                    }`}
                  >
                    {t("user-account.4")}
                    <span className="text-danger"> *</span>
                  </Typography>
                  <Input
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      opacity: "0.7",
                    }}
                    id="Last Name"
                    placeholder={t("user-account.4")}
                    type="text"
                    value={lastName}
                    onChange={(ev) => setlastName(ev.target.value)}
                    inputProps={{ "aria-label": "description" }}
                    error={!lastName && messageType === "error" ? true : false}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.5")}
                  </Typography>
                  <Input
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      opacity: "0.7",
                    }}
                    id="Phone Number"
                    placeholder={t("user-account.5")}
                    type="phone"
                    value={phoneNumber}
                    onChange={(ev) => setPhoneNumber(ev.target.value)}
                    inputProps={{ "aria-label": "description" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.6")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      opacity: "0.7",
                    }}
                  >
                    {userData?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.7")}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap={2}
                  >
                    <Input
                      sx={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        opacity: "0.7",
                      }}
                      id="password"
                      placeholder={t("user-account.7")}
                      type="password"
                      value={passowrd}
                      onChange={(ev) => setPassowrd(ev.target.value)}
                      inputProps={{ "aria-label": "description" }}
                    />
                    <Button
                      variant="contained"
                      color="info"
                      size="small"
                      sx={{ borderRadius: "6px", textTransform: "none" }}
                      onClick={() => setShowChangePassword(true)}
                    >
                      Change password
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {userData?.authorized_code ? (
            <Typography className="subTitle__info">
              <span style={{ color: "#07120D", fontWeight: "normal" }}>{`${t(
                "pc-account.9"
              )} : `}</span>
              {`${userData?.authorized_code}`}
            </Typography>
          ) : null}

          {/* <Typography className="subTitle__desc">
            {t("pc-account.2")}
          </Typography> */}
          {/* <Box
            display="flex"
            alignItems="center"
            columnGap="2rem"
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
          >
            <Box className="selectedSiteContainer">
              <Box className="itemContainer">
                <Typography className="itemTitle">
                  {t('pc-account.3')}
                </Typography>
                <Box className="content">
                  <SelectPicker
                    className="select"
                    data={sites.map((site: any) => ({
                      label: `${t('navigation.6')} ${site.description}`,
                      value: site.sites_id,
                    }))}
                    onChange={(val) => setSelectedSiteId(val)}
                    value={selectedSiteId}
                    style={{ width: 224 }}
                    menuStyle={{ zIndex: '1000000' }}
                    searchable={false}
                    menuMaxHeight={140}
                    placeholder={t('common.17')}
                  />
                  <Button
                    className="addBtn"
                    variant="contained"
                    onClick={addSiteWithoutManagerHandler}
                  >
                    {t('common.5')}
                  </Button>
                </Box>
              </Box>
              <Box className="itemContainer">
                <Typography className="itemTitle">
                  {t('pc-account.4')}
                </Typography>
                <SelectPicker
                  className="select"
                  data={managerSitesToAdd.map((manager: any) => ({
                    label: `${manager.first_Name} ${manager.last_name}`,
                    value: manager.M_id,
                  }))}
                  value={selectedManagerId}
                  onChange={(val) => setSelectedManagerId(val)}
                  style={{ width: 224 }}
                  menuStyle={{ zIndex: '1000000' }}
                  searchable={false}
                  menuMaxHeight={140}
                  placeholder={t('common.17')}
                />
                <Box className="content">
                  <Typography>{t('pc-account.5')}</Typography>
                  <SelectPicker
                    className="select"
                    data={sitesUnderManager.map((site: any) => ({
                      label: `${t('navigation.6')} ${site.sites_id}`,
                      value: site.sites_id,
                    }))}
                    value={selectedSiteByManagerId}
                    onChange={(val) => setSelectedSiteByManagerId(val)}
                    style={{ width: 224 }}
                    menuStyle={{ zIndex: '1000000' }}
                    searchable={false}
                    menuMaxHeight={140}
                  />
                  <Button
                    className="addBtn"
                    variant="contained"
                    onClick={addSiteWithManagerHandler}
                  >
                    {t('common.5')}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              maxWidth="600px"
              width="100%"
              margin="1rem auto"
              borderRadius="10px"
            >
              <TableContainer
                component={Paper}
                sx={{ maxWidth: 850, padding: '1rem' }}
              >
                <Typography
                  padding="0.5rem 2rem"
                  fontSize="1.2rem"
                  fontWeight="600"
                >
                  {t('pc-account.6')}
                </Typography>
                <Table
                  sx={{ maxWidth: 850 }}
                  size="small"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('pc-account.7')}</TableCell>
                      <TableCell align="left">{t('pc-account.8')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {managersSites?.map((site: any) => (
                      <TableRow
                        key={site.sites_id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="site" align="center">
                          {t('navigation.6')} {site.sites_id}
                        </TableCell>
                        <TableCell align="left">
                          {site.managers?.join(', ')}
                        </TableCell>
                        <TableCell align="center">
                          <DeleteOutlineIcon
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSelectedSiteId(site.sites_id);
                              setShowDeleteSiteDialog(true);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box> */}
          <Button
            className="btn"
            onClick={registerHandler}
            variant="contained"
            style={{
              marginTop: "3rem",
            }}
          >
            {t("common.19")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EditProviderAccount;
