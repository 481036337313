import { Box, Typography } from "@mui/material";
import * as React from "react";
import { teethIcon } from "../../utils/common/global";
import AudioSupport from "../audio-support/AudioSupport";
import "./CustomHint.scss";

interface CustomHintProps {
  text: string;
  bottom?: string;
  left?: string;
  right?: string;
  showPlay?: boolean;
  audioText?: string;
}

const CustomHint: React.FunctionComponent<CustomHintProps> = ({
  text,
  bottom,
  left,
  right,
  showPlay = false,
  audioText,
}) => {
  return (
    <Box className="customHintContainer">
      {showPlay && (
        <Box alignSelf="flex-start">
          <AudioSupport
            showText={false}
            width="fit-content"
            padding="4px 0"
            audioText={audioText}
          />
        </Box>
      )}
      <Box
        display={{
          xs: "none",
          sm: "flex",
        }}
      >
        <img
          src={teethIcon}
          alt=""
          className="teethIcon"
          style={{
            bottom,
            left,
            right,
          }}
        />
      </Box>
      <Typography variant="body1" className="hint__text">
        {text}
      </Typography>
    </Box>
  );
};

export default CustomHint;
