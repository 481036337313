import * as React from "react";
import "../common/GoalSettings.scss";
import { Box, Typography, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getCurrentMember } from "../../../features/user/userSlice";
import { getUserData, loadUserData } from "../../../features/auth/authSlice";
import {
  getGlobalLanguage,
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import { updateIsMoveStatus } from "../../../features/user/asyncActions";
import { store } from "../../../app/store";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import AudioSupport from "../../../components/audio-support/AudioSupport";
import CustomStepper from "../../../components/customStepper/CustomStepper";
import VideoEmbed from "../../../components/video-embed/VideoEmbed";
import { getAge } from "../../../utils/common/getAge";
import { toothbrush_video } from "../../../utils/common/global";

const SettingsIntro = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const memberData = useAppSelector(getCurrentMember);
  const userData = useAppSelector(getUserData);
  const language = useAppSelector(getGlobalLanguage);
  const showMemberStatus = useAppSelector(showMember);
  const urlId =
    getAge(memberData.birth_date) >= 9
      ? toothbrush_video[2][language]
      : toothbrush_video[1][language];

  React.useEffect(() => {
    if (userData?.isMove === 0) updateIsMoveStatus(userData?.group_id);
    store.dispatch(loadUserData(userData?.group_id));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  React.useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(true));
  }, []);

  const renderTextAudio = () => `${t("goal-settings-intro.1")} ${
    memberData?.first_name
  } ${memberData?.last_name}
        ${
          language === "en"
            ? `${t("goal-settings-intro.2")} ${memberData?.first_name}${t(
                "goal-settings-intro.3"
              )}`
            : `${t("goal-settings-intro.2")} ${memberData?.first_name} ${t(
                "goal-settings-intro.3"
              )}`
        }
        ${
          language === "en"
            ? `${t("goal-settings-intro.4")} ${memberData?.first_name}${t(
                "goal-settings-intro.5"
              )}`
            : `${t("goal-settings-intro.4")} ${memberData?.first_name}  ${t(
                "goal-settings-intro.5"
              )}`
        }
          ${t("goal-settings-intro.6")}
        `;

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="goalSettingsPage">
        <Box className="goalSettingsPage__contentContainer">
          <Box className="subHeader">
            <AudioSupport audioText={renderTextAudio()} />
          </Box>
          <Box className="subMain">
            <Box className="firstIntroContainer">
              <Typography variant="h2" fontSize="40px" className="intro__title">
                {t("goal-settings-intro.1")}
              </Typography>
              <Typography className="intro__username">
                {memberData?.first_name} {memberData?.last_name}
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  display="flex"
                  alignItems="center"
                >
                  <VideoEmbed
                    className="video"
                    embedId={urlId}
                    width="568px"
                    height="342px"
                    border="3px solid #9EA6B"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box className="textContainer">
                    <Typography
                      className="text"
                      variant="body1"
                      fontSize="1.5rem"
                    >
                      {language === "en"
                        ? `${t("goal-settings-intro.2")} ${
                            memberData?.first_name
                          }${t("goal-settings-intro.3")}`
                        : `${t("goal-settings-intro.2")} ${
                            memberData?.first_name
                          } ${t("goal-settings-intro.3")}`}
                    </Typography>
                    <Typography
                      className="text"
                      variant="body1"
                      fontSize="1.5rem"
                    >
                      {language === "en"
                        ? `${t("goal-settings-intro.4")} ${
                            memberData?.first_name
                          }${t("goal-settings-intro.5")}`
                        : `${t("goal-settings-intro.4")} ${
                            memberData?.first_name
                          }  ${t("goal-settings-intro.5")}`}
                    </Typography>
                    <Typography
                      className="text"
                      variant="body1"
                      fontSize="1.5rem"
                    >
                      {t("goal-settings-intro.6")}
                    </Typography>
                    <Button
                      className="btn"
                      onClick={() => navigate("/goal-settings/overview")}
                    >
                      {t("common.9")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={2} />
      </Box>
    </>
  );
};

export default SettingsIntro;
