import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "../locales/en/common.json";
import translationES from "../locales/es/common.json";
import translationPB from "../locales/pb/common.json";
import translationSW from "../locales/sw/common.json";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  pb: {
    translation: translationPB,
  },
  sw: {
    translation: translationSW,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: ["en", "es", "pb", "sw"],
    debug: false,
    detection: {
      order: ["queryString", "cookie"],
      caches: ["cookie", "localStorage"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
