import { Typography } from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import "./MealCard.scss";

interface MealCardProps {
  imageId: string;
  title: string;
  imageURL: string;
  type?: string;
  removeHandler: () => void;
}

const MealCard: React.FunctionComponent<MealCardProps> = ({
  imageId,
  title,
  imageURL,
  removeHandler,
}) => {
  return (
    <div className="meal-card">
      <img src={imageURL} className="meal-img" alt="" />
      <Typography variant="subtitle1" className="meal-title" lineHeight={1.3}>
        {title}
      </Typography>
      <div className="remove-icon-container" onClick={removeHandler}>
        <ClearIcon sx={{ fontSize: "1.2rem" }} />
      </div>
    </div>
  );
};

export default MealCard;
