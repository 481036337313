import * as React from "react";
import { Box, Typography, Button, Grid, AlertColor } from "@mui/material";
import CustomStepper from "../../components/customStepper/CustomStepper";
import { useTranslation } from "react-i18next";
import {
  whatsIcon,
  whiteEmailIcon,
  blackEmailIcon,
  USflagIcon,
} from "../../utils/common/global";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./FollowUpInfo.scss";
import { Link, useNavigate } from "react-router-dom";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import {
  saveNotificationFlag,
  savePhoneNumber,
} from "../../features/user/asyncActions";
import { useAppSelector } from "../../app/hooks";
import { getUserData, loadUserData } from "../../features/auth/authSlice";
import { store } from "../../app/store";
import ConfirmationModal from "../../components/confirmation-modal/ConfirmationModal";
import { sendVerifyCode } from "../../features/auth/asyncAction";
import { cryptedCodeGenerator } from "../../utils/common/customModular";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import { validPhoneNumber } from "../../utils/common/checkValidator";
import { CheckCircle, Error } from "@mui/icons-material";

interface FollowUpInfoProps {}

type FollowUpTypes = "email" | "text" | "both" | null;

const FollowUpInfo: React.FC<FollowUpInfoProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const [selectedType, setSelectedType] = React.useState<FollowUpTypes>(null);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const [ShowVerifiedNumberModal, setShowVerifiedNumberModal] =
    React.useState(false);
  const [verifiedCode, setVerifiedCode] = React.useState<any | null>(null);
  const [inputVerifiedCode, setInputVerifiedCode] = React.useState<any | null>(
    null
  );
  const showMemberStatus = useAppSelector(showMember);
  const [hintMessage, setHintMessage] = React.useState("");
  const [hintStatus, setHintStatus] = React.useState<string>("");
  const [showContinueBtn, setShowContinueBtn] = React.useState(false);

  const generateVerifiedCode = () => {
    const code = Math.random().toString().substring(2, 8);
    setVerifiedCode(code);
  };

  React.useEffect(() => {
    generateVerifiedCode();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  const checkValidPhoneNumber = async (ev: any) => {
    ev.preventDefault();
    if (phoneNumber.length === 10) {
      await sendVerifyCodeHandler(ev);
    } else {
      setShowMessage(true);
      setMessageType("error");
      setMessage(t("follow-up-info.10"));
    }
  };

  const sendVerifyCodeHandler = async (ev: any) => {
    ev.preventDefault();
    const cryptedCode = cryptedCodeGenerator(verifiedCode);
    const { message, status } = await sendVerifyCode(
      `+1${phoneNumber}`,
      cryptedCode,
      userData.lan_id
    );
    setHintMessage(message);
    setHintStatus(status);
    if (status === "success") {
      setTimeout(() => {
        setShowVerifiedNumberModal(true);
        setHintStatus("");
        setHintMessage("");
      }, 3000);
    }
  };

  const verifyCodeHandler = (ev: any) => {
    ev.preventDefault();

    if (inputVerifiedCode?.length === 6) {
      if (inputVerifiedCode === verifiedCode) {
        setShowContinueBtn(true);
        setShowVerifiedNumberModal(false);
        setHintMessage(t("follow-up-info.11"));
        setHintStatus("success");
      } else {
        setShowMessage(true);
        setMessageType("error");
        setMessage(t("follow-up-info.9"));
      }
    } else {
      setShowMessage(true);
      setMessageType("error");
      setMessage(t("follow-up-info.8"));
    }
  };

  const saveHandler = async (ev: any) => {
    ev.preventDefault();
    if ((selectedType === "text" || selectedType === "both") && !phoneNumber) {
      setShowMessage(true);
      setMessage(t("common.16"));
      setMessageType("error");
    } else {
      if (selectedType === "text") {
        // add phone number
        await savePhoneNumber({
          group_id: userData.group_id,
          phone_number: `+1${phoneNumber}`,
        });
        await saveNotificationFlag({
          group_id: userData.group_id,
          notificationFlag: "1",
        });
      } else if (selectedType === "email") {
        await saveNotificationFlag({
          group_id: userData.group_id,
          notificationFlag: "2",
        });
      } else if (selectedType === "both") {
        await savePhoneNumber({
          group_id: userData.group_id,
          phone_number: `+1${phoneNumber}`,
        });
        await saveNotificationFlag({
          group_id: userData.group_id,
          notificationFlag: "3",
        });
      }
      await store.dispatch(loadUserData(userData.group_id));
      navigate("/user-dashboard");
    }
  };

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <ConfirmationModal
        open={ShowVerifiedNumberModal}
        title={t("follow-up-info.6")}
        closeHandler={() => setShowVerifiedNumberModal(false)}
        showCancelButton={false}
        showSubmitButton={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="1.5rem"
          marginTop="2rem"
        >
          <input
            placeholder={t("follow-up-info.7")}
            className="verify-code-input"
            type="number"
            maxLength={6}
            value={inputVerifiedCode}
            onChange={(ev: any) => {
              ev.target.value.length <= 6 &&
                setInputVerifiedCode(ev.target.value);
            }}
          />
          <Button
            variant="contained"
            className="btn"
            sx={{ width: "270px", alignSelf: "center" }}
            onClick={verifyCodeHandler}
          >
            {t("common.25")}
          </Button>
        </Box>
      </ConfirmationModal>
      <Box className="followUpInfoPage">
        <CustomAlert
          type={messageType}
          open={showMessage}
          closeHandler={() => setShowMessage(false)}
          message={message}
        />
        <Box sx={{ marginTop: "1rem" }}>
          <Typography
            className="followUpInfoPage__title"
            variant="h3"
            sx={{ fontWeight: "600", margin: "0 1rem" }}
          >
            {t("follow-up-info.1")}
          </Typography>
        </Box>
        <Box className="followUpInfoPage__content">
          <Grid className="followUpContainerChoices" container>
            <Grid
              item
              sm={8}
              xs={8}
              md={3}
              lg={3}
              xl={3}
              className={`followUpBox ${
                selectedType === "text" ? "followUpBox--selected" : ""
              }`}
              onClick={() => setSelectedType("text")}
            >
              <img className="whatsLogo" src={whatsIcon} alt="" />
              <Typography
                className={`textName ${
                  selectedType === "text" ? "textName--selected" : ""
                }`}
              >
                {t("follow-up-info.2")}
              </Typography>
            </Grid>
            <Grid
              item
              sm={8}
              xs={8}
              md={3}
              lg={3}
              xl={3}
              className={`followUpBox ${
                selectedType === "email" ? "followUpBox--selected" : ""
              }`}
              onClick={() => setSelectedType("email")}
            >
              <img
                className="emailLogo"
                src={selectedType === "email" ? whiteEmailIcon : blackEmailIcon}
                alt=""
              />
              <Typography
                className={`textName ${
                  selectedType === "email" ? "textName--selected" : ""
                }`}
              >
                {t("follow-up-info.3")}
              </Typography>
            </Grid>

            <Grid
              item
              sm={8}
              xs={8}
              md={3}
              lg={3}
              xl={3}
              className={`followUpBox ${
                selectedType === "both" ? "followUpBox--selected" : ""
              }`}
              onClick={() => setSelectedType("both")}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <img className="smallWhatsLogo" src={whatsIcon} alt="" />
                <Typography
                  className={`textName ${
                    selectedType === "both" ? "textName--selected" : ""
                  }`}
                >
                  &
                </Typography>
                <img
                  className="smallEmailLogo"
                  src={
                    selectedType === "both" ? whiteEmailIcon : blackEmailIcon
                  }
                  alt=""
                />
              </Box>
              <Box>
                <Typography
                  className={`textName ${
                    selectedType === "both" ? "textName--selected" : ""
                  }`}
                >
                  {t("follow-up-info.4")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box className="followUpContainerConfirm" marginTop="3rem">
            {(selectedType === "text" || selectedType === "both") && (
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    color: "#888888",
                    textAlign: "start",
                    fontSize: "1.2rem",
                    margin: "0 2rem",
                  }}
                >
                  {t("follow-up-info.5")}
                </Typography>
                <Box className="phoneContainer">
                  <Box className="flagContainer">
                    <img src={USflagIcon} alt="" className="phoneFlag" />
                    <Typography>+1</Typography>
                  </Box>
                  <PhoneInput
                    country={"us"}
                    value={phoneNumber}
                    onlyCountries={["us"]}
                    disableDropdown
                    onChange={(phone) => setPhoneNumber(phone)}
                    disableCountryCode
                    placeholder="e.g. 212 212 2122"
                    containerClass="phoneContainerClass"
                    inputClass="phoneInputClass"
                    buttonClass="phoneButtonClass"
                  />
                </Box>
                {phoneNumber && !validPhoneNumber(phoneNumber) && (
                  <Box
                    bgcolor="#e8eaf4"
                    padding="16px 10px"
                    margin="4px 0"
                    borderRadius="10px"
                  >
                    <Typography
                      display="flex"
                      gap="6px"
                      alignItems="center"
                      width="100%"
                      margin="0px 10px"
                    >
                      {t("follow-up-info.10")}
                    </Typography>
                  </Box>
                )}
                {hintStatus && hintMessage && (
                  <Box
                    bgcolor="#e8eaf4"
                    padding="16px 10px"
                    margin="4px 0"
                    borderRadius="10px"
                  >
                    <Typography
                      display="flex"
                      gap="6px"
                      alignItems="center"
                      margin="0px 10px"
                    >
                      {hintStatus === "success" ? (
                        <CheckCircle style={{ color: "green" }} />
                      ) : (
                        <Error style={{ color: "red" }} />
                      )}
                      {hintMessage}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}

            {(showContinueBtn ||
              (selectedType && selectedType === "email")) && (
              <Button
                variant="contained"
                className="btn"
                sx={{ width: "270px" }}
                onClick={saveHandler}
              >
                {t("common.3")}
              </Button>
            )}

            {!showContinueBtn && selectedType && selectedType !== "email" && (
              <Button
                variant="contained"
                className="btn"
                sx={{ width: "270px" }}
                onClick={checkValidPhoneNumber}
                disabled={!validPhoneNumber(phoneNumber)}
              >
                {t("common.24")}
              </Button>
            )}
          </Box>
          {(selectedType === "text" || selectedType === "both") && (
            <Typography
              fontSize="14px"
              width="100%"
              maxWidth="800px"
              margin="10px auto 3rem"
            >
              {t("user-account.22")}
              <Link to="/privacy">{t("user-account.23")}</Link>
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={0} />
      </Box>
    </>
  );
};

export default FollowUpInfo;
