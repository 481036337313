import { useEffect } from 'react';

const events = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

interface AutoLogoutProps {
  children: JSX.Element | JSX.Element[];
  token: any;
}

const AutoLogout: React.FC<AutoLogoutProps> = ({ token, children }) => {
  let timer: any;
  const timeout = 24 * 60 * 60 * 1000;

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleTimer();
      });
    });
  }, []);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, timeout);
  };

  const logoutAction = () => {
    const authToken = localStorage.getItem('access-token');
    if (authToken) {
      localStorage.clear();
      window.location.pathname = '/sign-in';
    }
  };
  return <>{children}</>;
};

export default AutoLogout;
