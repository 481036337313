import React, { useEffect } from "react";
// import DietIntroduction from "./DietIntroduction";
import DietStart from "./DietStart";
import DietTypeTime from "./DietTimeType";
import DietFoodDrinks from "./DietFoodDrinks";
import DietSummary from "./DietSummary";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Case, Switch } from "../../../components/switch/Switch";
import { Box } from "@mui/material";
import DietTimeType from "./DietTimeType";

const dietTypes = [
  // {
  //   type: "introdction",
  //   element: DietIntroduction,
  // },
  {
    type: "start",
    element: DietStart,
  },
  {
    type: "timeAndType",
    element: DietTimeType,
  },
  {
    type: "foodAndDrinks",
    element: DietFoodDrinks,
  },
  {
    path: "summary",
    element: DietSummary,
  },
];

interface SearchProps {
  // assessmentStep?: number;
  type?: string;
}

const DietContainer = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { type }: SearchProps = queryString.parse(search, {
    parseNumbers: true,
  });

  useEffect(() => {
    if (!type) {
      navigate("/assessment?assessmentStep=0&type=start", {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="dietContainer" width="100%">
      <Switch test={type || ""} defaultComponent={<div />}>
        {/* <Case value="introduction">
          <DietIntroduction />
        </Case> */}
        <Case value="start">
          <DietStart />
        </Case>
        <Case value="timeAndType">
          <DietTypeTime />
        </Case>
        <Case value="foodAndDrinks">
          <DietFoodDrinks />
        </Case>
        <Case value="summary">
          <DietSummary />
        </Case>
      </Switch>
    </Box>
  );
};

export default DietContainer;
