import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  IconButton,
  Button,
  AlertColor,
} from "@mui/material";
import VideoEmbed from "../../components/video-embed/VideoEmbed";
import "./SignIn.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import ForgotPasswordModal from "../../components/forgotPasswordModal/ForgotPasswordModal";
import { authSignIn, getUserData } from "../../features/auth/authSlice";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import { checkEmpty } from "../../utils/common/checkEmpty";
import { store } from "../../app/store";
import { authForgotPassword } from "../../features/auth/asyncAction";
import { redirectFromSignIn } from "../../utils/common/redirect";
import { useAppSelector } from "../../app/hooks";
import { useTranslation } from "react-i18next";
import {
  getGlobalLanguage,
  getPreviousPaths,
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import {
  signInPbIcon,
  signInEnIcon,
  signInEsIcon,
  signInSwIcon,
  sign_intro_videos,
} from "../../utils/common/global";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import CustomLoader from "../../components/custom-loader/CustomLoader";
import { BeatLoader } from "react-spinners";

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = useAppSelector(getGlobalLanguage);
  const authToken = localStorage.getItem("access-token");
  const userData = useAppSelector(getUserData);
  const previousPaths = useAppSelector(getPreviousPaths);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("info");
  const [forgetEmail, setforgetEmail] = useState<string>("");
  const showMemberStatus = useAppSelector(showMember);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const signInHandler = async (ev: any) => {
    ev.preventDefault();
    const data = {
      email,
      password,
    };
    const state = checkEmpty(data);
    if (state) {
      const res = await store.dispatch(authSignIn(data));
      const { payload = { status: "error", message: "Something went wrong" } } =
        res;
      if (payload?.status === "success") {
        const { role_id = null, isMove = 0 } = payload.data;
        const { token } = payload;
        localStorage.setItem("access-token", token);
        setTimeout(() => {
          previousPaths &&
          previousPaths[previousPaths?.length - 2]?.includes("follow-up")
            ? navigate(previousPaths[previousPaths?.length - 2], {
                replace: true,
              })
            : redirectFromSignIn(navigate, role_id, isMove);
        }, 2000);
      } else {
        setShowMessage(true);
        setMessageType(payload.status);
        setMessage(payload.message);
        setIsSubmitting(false);
      }
    } else {
      setShowMessage(true);
      setMessage(t("common.16"));
      setMessageType("error");
    }
  };

  const forgotPasswordHandler = async (ev: any) => {
    ev.preventDefault();
    const { message, status } = await authForgotPassword({
      email: forgetEmail,
    });
    setShowMessage(true);
    setMessageType(status);
    setMessage(message);
    setShowForgotModal(false);
  };

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    if (authToken && userData?.role_id) {
      redirectFromSignIn(navigate, userData?.role_id, userData?.isMove);
    } else {
      setIsPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSignInIcon = () => {
    switch (language) {
      case "en":
        return signInEnIcon;
      case "es":
        return signInEsIcon;
      case "pb":
        return signInPbIcon;
      case "sw":
        return signInSwIcon;
      default:
        return signInEnIcon;
    }
  };

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <LanguageSupport showTranslateComponent />
      <Box className="signInPage">
        <ForgotPasswordModal
          open={showForgotModal}
          submitHandler={forgotPasswordHandler}
          closeHandler={() => setShowForgotModal(false)}
          email={forgetEmail}
          setEmail={setforgetEmail}
        />
        {isPageLoading ? (
          <CustomLoader />
        ) : (
          <>
            <Box className="signInPage__intro">
              <Typography
                textAlign="center"
                fontSize="1.1rem"
                fontWeight="500"
                margin="0.2rem 0.5rem"
              >
                {t("sign-in.1")}
              </Typography>
              <Typography
                textAlign="center"
                fontSize="1.1rem"
                fontWeight="500"
                margin="0.2rem 0.5rem"
              >
                {t("sign-in.2")}
              </Typography>
              <Typography
                textAlign="center"
                fontSize="1.1rem"
                fontWeight="500"
                margin="0.2rem 0.5rem"
              >
                {t("sign-in.16")}
              </Typography>
              <VideoEmbed embedId={sign_intro_videos[language]} />
            </Box>
            <Paper className="signInPage__signIn">
              <Box className="imgHintContainer">
                <img
                  src={renderSignInIcon()}
                  alt=""
                  width="100%"
                  height="auto"
                  className="imgHint"
                />
              </Box>
              <Typography variant="h4" style={{ marginBottom: "1rem" }}>
                {t("sign-in.3")}
              </Typography>
              <Typography style={{ color: "#00000080" }}>
                {t("sign-in.4")}
              </Typography>
              <Box
                component="form"
                className="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <FormControl fullWidth>
                  <TextField
                    label={t("sign-in.5")}
                    type="email"
                    variant="standard"
                    value={email}
                    onChange={(ev: any) => setEmail(ev.target.value)}
                    placeholder="example@email.com"
                    style={{ width: "100%" }}
                    error={messageType === "error" && !email ? true : false}
                    required
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label={t("sign-in.6")}
                    style={{ width: "100%" }}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(ev: any) => setPassword(ev.target.value)}
                    variant="standard"
                    placeholder="Enter your password"
                    error={messageType === "error" && !password ? true : false}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Typography
                  className="forgot-password"
                  onClick={() => setShowForgotModal(true)}
                >
                  {t("sign-in.7")}
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  className="login-btn"
                  onClick={(ev) => {
                    setIsSubmitting(true);
                    signInHandler(ev);
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <BeatLoader color="#0279c8" />
                  ) : (
                    t("sign-in.8")
                  )}
                </Button>
                <Typography className="sign-up">
                  {t("sign-in.9")} ?{" "}
                  <Link to="/sign-up">{t("sign-in.10")}</Link>
                </Typography>
              </Box>
            </Paper>
          </>
        )}
      </Box>
    </>
  );
};

export default SignIn;
