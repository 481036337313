import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import authReducer from "../features/auth/authSlice";
import userReducer from "../features/user/userSlice";
import managerReducer from "../features/manager/managerSlice";
import globalReducer from "../features/global/globalSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  global: globalReducer,
  auth: authReducer,
  user: userReducer,
  manager: managerReducer,
});

export const getRootReducer = (state: any, action: any) => {
  return rootReducer(state, action);
};

const persistedReducer = persistReducer<RootReducer>(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type RootReducer = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
