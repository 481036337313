import React, { useEffect } from "react";
import { Box } from "@mui/material";
import "./ActionPlanningTutorial.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VideoEmbed from "../../components/video-embed/VideoEmbed";
import { useAppSelector } from "../../app/hooks";
import { getCurrentMember } from "../../features/user/userSlice";
import {
  getGlobalLanguage,
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";
import { Button } from "rsuite";
import { action_planning_video } from "../../utils/common/global";

const ActionPlanningTutorial = () => {
  const { t } = useTranslation();
  //   const customWidth = useWidthSize();
  const navigate = useNavigate();
  const language = useAppSelector(getGlobalLanguage);
  //   const userData = useAppSelector(getUserData);
  const memberData = useAppSelector(getCurrentMember);
  const showMemberStatus = useAppSelector(showMember);

  //   const urlId =
  //     getAge(memberData.birth_date) >= 9
  //       ? caries_video[2][language]
  //       : caries_video[1][language];

  useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="actionPlanningTutorialPage">
        <Box className="actionPlanningTutorialPage__contentContainer">
          <Box className="subMain">
            <VideoEmbed
              className="video"
              embedId={action_planning_video[language]}
              height="490px"
              border="3px solid #9EA6B"
            />
            <Button
              className="btn"
              onClick={() => navigate(`/member-details/${memberData.user_id}`)}
            >
              {t("common.3")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ActionPlanningTutorial;
