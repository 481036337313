import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, AlertColor } from "@mui/material";
import "./EditSite.scss";
import { useTranslation } from "react-i18next";
import { CloseRounded } from "@mui/icons-material";
import ConfirmationModal, {
  SubmitParams,
} from "../../components/confirmation-modal/ConfirmationModal";
import DeleteSiteModal from "../../components/delete-site-modal/DeleteSiteModal";
import { useAppSelector } from "../../app/hooks";
import { getUserData } from "../../features/auth/authSlice";
import {
  fetchAllSites,
  fetchSitesBymId,
  getManagerData,
} from "../../features/manager/managerSlice";
import {
  checkPCUnderSiteStatus,
  deleteSite,
  editSite,
  excludeSite,
} from "../../features/manager/asyncActions";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import { store } from "../../app/store";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import { useNavigate } from "react-router-dom";

const EditSite: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const manager = useAppSelector(getManagerData);
  const [showDeleteSiteDialog, setShowDeleteSiteDialog] = useState(false);
  const [showEditSiteDialog, setShowEditSiteDialog] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState<string>("");
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const [nickname, setNickname] = useState("");
  const [showErrorMessage, setShowErrormessage] =
    React.useState<boolean>(false);
  const showMemberStatus = useAppSelector(showMember);

  const deleteSiteHandler = async (siteId: any) => {
    const { PCExist } = await checkPCUnderSiteStatus(siteId);
    if (PCExist) {
      setShowErrormessage(true);
    } else {
      const { message, status } = await deleteSite(siteId);
      await store.dispatch(fetchAllSites({}));
      await store.dispatch(fetchSitesBymId(userData.group_id));
      setShowMessage(true);
      setMessage(message);
      setMessageType(status);
      setShowErrormessage(false);
      setShowDeleteSiteDialog(false);
      setSelectedSiteId("");
    }
  };
  const excludeSiteHandler = async (siteId: any) => {
    const { message, status } = await excludeSite({
      managerId: userData.group_id,
      siteId,
    });
    await store.dispatch(fetchAllSites({}));
    await store.dispatch(fetchSitesBymId(userData.group_id));
    setShowMessage(true);
    setMessage(message);
    setMessageType(status);
    setShowErrormessage(false);
    setShowDeleteSiteDialog(false);
    setSelectedSiteId("");
  };

  const submitParams: SubmitParams = {
    btnContent: t("common.3"),
    submitHandler: async () => {
      await updateSiteHandler(nickname);
      await store.dispatch(fetchAllSites({}));
      await store.dispatch(fetchSitesBymId(userData.group_id));
      setShowEditSiteDialog(false);
    },
  };

  const updateSiteHandler = async (name: string) => {
    const { message, status } = await editSite({
      siteId: selectedSiteId,
      description: nickname,
    });
    setShowMessage(true);
    setMessage(message);
    setMessageType(status);
    setShowEditSiteDialog(false);
    setNickname("");
  };

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <DeleteSiteModal
        showError={showErrorMessage}
        open={showDeleteSiteDialog}
        closeHandler={() => {
          setShowDeleteSiteDialog(false);
          setShowErrormessage(false);
          setSelectedSiteId("");
        }}
        removeHandler={() => excludeSiteHandler(selectedSiteId)}
        deleteHandler={() => deleteSiteHandler(selectedSiteId)}
      />

      <ConfirmationModal
        open={showEditSiteDialog}
        closeHandler={() => {
          setShowEditSiteDialog(false);
        }}
        title={t("manager-dashboard.8")}
        submitParams={submitParams}
      >
        <Box className="addSiteContainer">
          <Typography className="addSiteTitle">
            {t("manager-dashboard.9")}
          </Typography>
          <input
            type="text"
            name="nickname"
            placeholder={t("manager-dashboard.6")}
            className="input"
            value={nickname}
            onChange={(ev: any) => setNickname(ev.target.value)}
          />
        </Box>
      </ConfirmationModal>
      <LanguageSupport
        showBackArrowComponent
        showTranslateComponent
        backFunc={() => navigate("/manager-dashboard")}
      />
      <Box className="editSitePage">
        <Box className="editSitePage__membersContainer">
          <Grid
            container
            spacing={3}
            sx={{ marginTop: "1rem" }}
            className="gridContainer"
          >
            {manager.allSitesBymId.map((site: any) => (
              <Grid
                item
                key={site.sites_id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              >
                <Box className="siteContainer">
                  <CloseRounded
                    sx={{ alignSelf: "flex-end", cursor: "pointer" }}
                    onClick={() => {
                      setShowDeleteSiteDialog(true);
                      setSelectedSiteId(site.sites_id);
                    }}
                  />
                  <Box className="site">
                    <Typography className="site__title">
                      {site.description}
                    </Typography>
                    <Typography className="site__desc">
                      <strong>code:</strong> {site.unique_code}
                    </Typography>
                    <Typography
                      className="site__editLink"
                      onClick={() => {
                        setShowEditSiteDialog(true);
                        setSelectedSiteId(site.sites_id);
                        setNickname(site.description);
                      }}
                    >
                      {t("manager-dashboard.7")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default EditSite;
