import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import useAxios from '../../utils/common/useAxios';
// import { RootState, AppThunk } from '../../app/store';

interface ManagerState {
  allManagers: Array<any>;
  managersUnderSites: Array<any>;
  allSitesBymId: Array<any>;
  allSites: Array<any>;
  loading: boolean;
  error: string;
}

const initialState: ManagerState = {
  allManagers: [],
  allSites: [],
  managersUnderSites: [],
  allSitesBymId: [],
  loading: false,
  error: '',
};

export const fetchAllSites = createAsyncThunk(
  'manager/allSites',
  async (body: any) => {
    const response = await useAxios({
      method: 'GET',
      url: '/sites/allSites',
      data: body,
    });
    return response.data;
  }
);
export const fetchManagersUnderSites = createAsyncThunk(
  'manager/managersUnderSites',
  async (managerId: string) => {
    const response = await useAxios({
      method: 'GET',
      url: `/sites/managersUnderSites/${managerId}`,
      data: {},
    });
    return response.data;
  }
);
export const fetchSitesBymId = createAsyncThunk(
  'manager/getAllSitesBymId',
  async (managerId: string) => {
    const response = await useAxios({
      method: 'GET',
      url: `/sites/getAllSitesBymId/${managerId}`,
      data: {},
    });
    return response.data;
  }
);

export const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllSites.fulfilled as any]: (state, action) => {
      state.loading = false;
      state.allSites = action.payload;
    },
    [fetchManagersUnderSites.fulfilled as any]: (state, action) => {
      state.loading = false;
      state.managersUnderSites = action.payload;
    },
    [fetchSitesBymId.fulfilled as any]: (state, action) => {
      state.loading = false;
      state.allSitesBymId = action.payload;
    },
  },
});
export const getManagerData = (state: RootState) => state.manager;
export const getAllSites = (state: RootState) => state.manager.allSites;
export const getManagersUnderSites = (state: RootState) =>
  state.manager.managersUnderSites;
export const getAllSitesBymId = (state: RootState) =>
  state.manager.allSitesBymId;

export default managerSlice.reducer;
