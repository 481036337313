import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import "./ShowMemberDetails.scss";
import { useTranslation } from "react-i18next";
import ActiveGoal from "../../components/activeGoal/ActiveGoal";
import PreviousGoal from "../../components/previousGoal/PreviousGoal";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCurrentActivePlan,
  getMember,
  getPreviousPlans,
} from "../../features/user/asyncActions";
import moment from "moment";
import { useAppSelector } from "../../app/hooks";
import {
  getGlobalLanguage,
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import CustomBackButton from "../../components/custom-bread-crumbs/CustomBackButton";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";
import CustomLoader from "../../components/custom-loader/CustomLoader";

const ShowMemberDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = useAppSelector(getGlobalLanguage);
  const { siteId, familyId, pcId, memberId, pcType } = useParams();
  const [memberData, setMemberData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeGoal, setActiveGoal] = useState<any>({});
  const [previousGoals, setPreviousGoals] = useState<any>([]);
  const showMemberStatus = useAppSelector(showMember);

  const durationValues: any = {
    // '8': t('duration.1'),
    "2": t("duration.2"),
    "4": t("duration.3"),
    "6": t("duration.4"),
  };

  const fetchMemberData = async () => {
    const { data } = await getMember(memberId);
    setMemberData(data);
  };
  const fetchCurrentActiveGoal = async () => {
    const { data } = await getCurrentActivePlan(memberId);
    setActiveGoal(data);
  };
  const fetchPreviousGoals = async () => {
    const { data } = await getPreviousPlans(memberId);
    setPreviousGoals(data);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  useEffect(() => {
    fetchMemberData();
    fetchCurrentActiveGoal();
    fetchPreviousGoals();
    !showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="showMemberDetailsPage">
        <Box className="showMemberDetailsPage__intro">
          <CustomBackButton btnContent={t("navigation.17")} btnUrl={-1} />
          {/* <Box className="userInfoContainer">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                alt=""
                src={teethIcon}
                sx={{ width: 28, height: 28 }}
                style={{
                  marginRight: '8px',
                  border: '2px solid #005994',
                }}
              />
              {memberData?.first_Name && memberData?.last_name && (
                <Typography
                  sx={{
                    fontSize: '1.1rem',
                    fontWeight: '500',
                    color: '#07120D',
                  }}
                >
                  {memberData?.first_Name} {memberData?.last_name}
                </Typography>
              )}
            </Box>
            <Typography sx={{ color: '#333333' }}>
              {t('user-dashboard.2')}: {memberData.email}
            </Typography>
          </Box> */}
        </Box>
        <Box className="showMemberDetailsPage__contentContainer">
          <Box className="subHeader">
            <Typography sx={{ fontSize: "1.5rem", color: "#07120D" }}>
              {memberData?.first_name} {memberData?.last_name}
            </Typography>
          </Box>
          <Box className="subMain">
            <Box className="subMain__memberInfo">
              <Box>
                <Typography>{t("user-dashboard.8")}</Typography>
                <Typography
                  fontSize="1.3rem"
                  fontWeight="500"
                  sx={{
                    opacity: "0.6",
                  }}
                >
                  {memberData?.first_name}
                </Typography>
              </Box>
              <Box>
                <Typography>{t("user-dashboard.9")}</Typography>
                <Typography
                  fontSize="1.3rem"
                  fontWeight="500"
                  sx={{
                    opacity: "0.6",
                  }}
                >
                  {memberData?.last_name}
                </Typography>
              </Box>
              <Box>
                <Typography>{t("user-dashboard.10")}</Typography>
                <Typography
                  fontSize="1.3rem"
                  fontWeight="500"
                  sx={{
                    opacity: "0.6",
                  }}
                >
                  {moment(memberData?.birth_date).format("MM-DD-YYYY")}
                </Typography>
              </Box>
            </Box>
            {isLoading ? (
              <CustomLoader />
            ) : (
              <Box className="subMain__goalsContainer">
                <Box className="goals">
                  {memberData?.first_name && (
                    <Typography
                      fontSize="1.75rem"
                      color="#005994"
                      fontWeight="700"
                      marginY="1rem"
                    >
                      {language === "en"
                        ? `${memberData?.first_name}${t("member-details.7")}`
                        : `${t("member-details.7")} ${memberData?.first_name}`}
                    </Typography>
                  )}
                  {activeGoal && (
                    <ActiveGoal
                      goalId={activeGoal.goal_id}
                      responsible={activeGoal.who}
                      startDate={moment(activeGoal.start_date).format(
                        "MM-DD-YYYY"
                      )}
                      endDate={moment(activeGoal.start_date)
                        .add(
                          activeGoal?.how_long ? activeGoal.how_long * 7 : 0,
                          "days"
                        )
                        .format("MM-DD-YYYY")}
                    />
                  )}
                  {!activeGoal && (
                    <Typography
                      variant="h4"
                      fontWeight="700"
                      textAlign="center"
                    >
                      {t("member-details.10")}
                    </Typography>
                  )}
                  <Typography color="#005994" marginY="1rem">
                    {t("member-details.9")}
                  </Typography>
                  <Box className="previousGoals">
                    {previousGoals &&
                      previousGoals.map((previousGoal: any, idx: number) => (
                        <PreviousGoal
                          key={idx}
                          goalId={previousGoal.goal_id}
                          responsible={previousGoal.who}
                          startDate={moment(previousGoal.start_date).format(
                            "MM-DD-YYYY"
                          )}
                          endDate={durationValues[previousGoal.how_long]}
                          isDone={previousGoal.result === "yes"}
                          completed={previousGoal?.iscompleted}
                        />
                      ))}
                    {previousGoals.length === 0 && (
                      <Typography
                        variant="h4"
                        fontWeight="700"
                        textAlign="center"
                      >
                        {t("member-details.11")}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className="btnContainer">
                  <Button
                    className="btn showRiskBtn"
                    variant="contained"
                    onClick={() => navigate(`/risk-areas/${memberId}`)}
                  >
                    {t("member-details.14")}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ShowMemberDetails;
