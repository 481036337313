import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import "./ActionPlanning.scss";
import CustomStepper from "../../components/customStepper/CustomStepper";
import { useNavigate } from "react-router-dom";
import { teethIcon } from "../../utils/common/global";
import { useAppSelector } from "../../app/hooks";
import { getCurrentMember } from "../../features/user/userSlice";
import { useTranslation } from "react-i18next";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import { store } from "../../app/store";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import AudioSupport from "../../components/audio-support/AudioSupport";

const ActionPlanningIntro = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const memberData = useAppSelector(getCurrentMember);
  const showMemberStatus = useAppSelector(showMember);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  React.useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="actionPlanningPage">
        <Box className="actionPlanningPage__contentContainer">
          <Box className="subMain">
            <Box className="introContainer">
              <Typography className="introTitle" variant="h2" fontSize="3rem">
                {t("action-planning.1")}
              </Typography>
              <Typography className="introUsername">
                {memberData?.first_name} {memberData?.last_name}
              </Typography>
              <Box className="intro">
                <img src={teethIcon} alt="" className="teethIcon" />
                <Box className="textContainer">
                  <AudioSupport
                    audioText={t("action-planning.2")}
                    showText={false}
                    width="fit-content"
                    padding="4px 0"
                  />
                  <Typography
                    className="text"
                    variant="body1"
                    fontSize="1.5rem"
                  >
                    {t("action-planning.2")}
                  </Typography>
                </Box>
              </Box>
              <Box className="btnContainer">
                <Button
                  className="btn backBtn"
                  onClick={() => navigate(-1)}
                  variant="contained"
                >
                  {t("common.13")}
                </Button>
                <Button
                  className="btn continueBtn"
                  onClick={() => navigate("/action-planning")}
                  variant="outlined"
                >
                  {t("common.3")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={3} />
      </Box>
    </>
  );
};

export default ActionPlanningIntro;
