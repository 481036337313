export const redirectFromSignIn = (
  navigate: any,
  role_id?: number,
  isMove?: any
) => {
  if (!role_id || !isMove) {
    navigate("/");
  }
  switch (role_id) {
    case 1:
      navigate("/manager-dashboard");
      break;
    case 2:
      navigate("/provider-dashboard");
      break;
    case 3:
      navigate("/counselor-dashboard");
      break;
    case 4:
      if (isMove !== null) {
        navigate("/user-dashboard");
      } else {
        navigate("/signup-new/welcome");
      }
      break;
    case 5:
      navigate("/administrator-dashboard");
      break;
  }
};

export const redirectFromAccount = (role_id: number, navigate: any) => {
  switch (role_id) {
    case 1:
      navigate("/manager-account");
      break;
    case 2:
      navigate("/provider-account");
      break;
    case 3:
      navigate("/counselor-account");
      break;
    case 4:
      navigate("/user-account");
      break;
    case 5:
      navigate("/administrator-account");
      break;
  }
};
export const redirectFromHomeLink = (role_id: number, isMove: any): any => {
  switch (role_id) {
    case 1:
      return "/manager-dashboard";
    case 2:
      return "/provider-dashboard";
    case 3:
      return "/counselor-dashboard";
    case 4:
      if (isMove === 1) {
        return "/user-dashboard";
      } else {
        return "/signup-new/welcome";
      }
    case 5:
      return "/administrator-dashboard";
    default:
      return "/sign-in";
  }
};
