import React, { useEffect, useState } from "react";
import { Box, Typography, Button, AlertColor } from "@mui/material";
import "./MemberDetails.scss";
import { useTranslation } from "react-i18next";
import CustomStepper from "../../components/customStepper/CustomStepper";
import CustomDialog from "../../components/customDialog/CustomDialog";
import ActiveGoal from "../../components/activeGoal/ActiveGoal";
import PreviousGoal from "../../components/previousGoal/PreviousGoal";
import ConfirmationModal from "../../components/confirmation-modal/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteMember,
  getCurrentActivePlan,
  getMember,
  getPreviousPlans,
  removeInactivePlanFromRemaining,
  updateMember,
  updateMemberTrackingStatus,
  updatePlanToCompleted,
} from "../../features/user/asyncActions";
import moment from "moment";
import { useAppSelector } from "../../app/hooks";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import { store } from "../../app/store";
import {
  getCurrentMember,
  saveRiskAndGoal,
  updateMemberData,
} from "../../features/user/userSlice";
import {
  getGlobalLanguage,
  getMode,
  risksAssessmentAnswers,
  showMember,
  updateSelectedAnswers,
  updateShowMember,
} from "../../features/global/globalSlice";
import { getUserData } from "../../features/auth/authSlice";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { isEmpty } from "lodash";
import CustomLoader from "../../components/custom-loader/CustomLoader";

const MemberDetails = () => {
  const { t } = useTranslation();
  const language = useAppSelector(getGlobalLanguage);
  const durationValues: any = {
    "0": t("duration.1"),
    "2": t("duration.2"),
    "4": t("duration.3"),
    "6": t("duration.4"),
  };
  const navigate = useNavigate();
  const { memberId } = useParams();
  const { notificationFlag } = useAppSelector(getUserData);

  const memberData = useAppSelector(getCurrentMember);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showEditMemberDialog, setShowEditMemberDialog] = useState(false);
  const [showGoalCompletedDialog, setShowGoalCompletedDialog] = useState(false);
  const [showDeleteMemberDialog, setShowDeleteMemberDialog] = useState(false);
  const [activeGoal, setActiveGoal] = useState<any>({});
  const [previousGoals, setPreviousGoals] = useState<any>([]);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const showMemberStatus = useAppSelector(showMember);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  const updatePlanToCompletedHandler = async () => {
    const { message, status } = await updatePlanToCompleted(
      activeGoal?.plan_id
    );
    await removeInactivePlanFromRemaining(activeGoal?.plan_id);
    await updateMemberTrackingStatus(memberId, 3);
    setShowMessage(true);
    setMessageType(status);
    setMessage(message);
    await fetchCurrentActiveGoal();
  };

  const fetchMemberData = async () => {
    const { data } = await getMember(memberId);
    store.dispatch(updateMemberData(data));
  };
  const fetchCurrentActiveGoal = async () => {
    const { data } = await getCurrentActivePlan(memberId);
    if (data?.iscompleted && !data?.isPrevious) {
      setShowGoalCompletedDialog(true);
      await updateMemberTrackingStatus(memberId, 3);
    }
    if (data)
      store.dispatch(
        saveRiskAndGoal({
          goalId: data.goal_id,
          goalName: data.name,
          riskAreaName: data.risk_name,
        })
      );
    setActiveGoal(data ?? {});
  };
  const fetchPreviousGoals = async () => {
    const { data } = await getPreviousPlans(memberId);
    setPreviousGoals(data);
  };

  const startAssessmentHandler = async () => {
    store.dispatch(updateMemberData(memberData));
    store.dispatch(updateSelectedAnswers(risksAssessmentAnswers));
    navigate("/start-assessment");
  };

  const updateMemberHandler = async (data: any) => {
    const { message, status } = await updateMember({
      memberId: memberData.user_id,
      memberData: {
        first_name: data?.first_name,
        last_name: data?.last_name,
        birth_date: data?.birth_date,
      },
    });
    setShowMessage(true);
    setMessage(message);
    setMessageType(status);
    setShowEditMemberDialog(false);
    await fetchMemberData();
  };

  useEffect(() => {
    if (memberId) {
      fetchMemberData();
      fetchCurrentActiveGoal();
      fetchPreviousGoals();
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <CustomDialog
        open={showEditMemberDialog}
        type="edit"
        closeHandler={() => {
          setShowEditMemberDialog(false);
        }}
        title={t("member-details.21")}
        data={{
          firstName: memberData?.first_name,
          lastName: memberData?.last_name,
          birthDate: moment(memberData?.birth_date).format("YYYY-MM-DD"),
        }}
        saveHandler={updateMemberHandler}
      />
      <ConfirmationModal
        title={t("member-details.15")}
        open={showGoalCompletedDialog}
        closeHandler={(ev, raison = "backdropClick") => {
          if (raison !== "backdropClick") setShowGoalCompletedDialog(false);
        }}
        showCancelButton={false}
        HideCloseButton
        props={{
          disableEscapeKeyDown: true,
        }}
        submitParams={{
          btnContent: t("member-details.17"),
          submitHandler: async (ev) => {
            ev.preventDefault();
            navigate(`/follow-up/${activeGoal.plan_id}?currentPage=1`);
          },
        }}
      >
        <Typography
          sx={{
            mt: 2,
            fontSize: "20px",
            color: "#787D86",
            textAlign: "center",
          }}
        >
          {t("member-details.16")}
        </Typography>
      </ConfirmationModal>
      <ConfirmationModal
        title={t("member-details.18")}
        open={showDeleteMemberDialog}
        closeHandler={() => setShowDeleteMemberDialog(false)}
        submitParams={{
          btnContent: t("member-details.20"),
          submitHandler: async (ev) => {
            ev.preventDefault();
            const { message, status } = await deleteMember(memberId);
            setShowMessage(true);
            setMessage(message);
            setMessageType(status);
            setShowEditMemberDialog(false);
            navigate("/user-dashboard");
          },
        }}
      >
        <Typography
          sx={{
            mt: 2,
            fontSize: "20px",
            color: "#787D86",
            textAlign: "center",
          }}
        >
          {t("member-details.19")}
        </Typography>
      </ConfirmationModal>
      <LanguageSupport
        showBackArrowComponent
        showTranslateComponent
        showCustomBackBtn
        customBackBtnContent={t("common.1")}
      />
      <Box className="memberDetailsPage">
        <Box className="memberDetailsPage__contentContainer">
          <Box className="subHeader">
            {memberData?.first_Name && memberData.last_name && (
              <Typography sx={{ fontSize: "1.5rem", color: "#07120D" }}>
                {memberData.first_Name} {memberData.last_name}
              </Typography>
            )}
            <Box className="btnContainer">
              <Button
                variant="outlined"
                className="editMemberBtn"
                sx={{ width: "270px" }}
                onClick={() => setShowEditMemberDialog(true)}
              >
                {t("member-details.1")}
              </Button>
              <Button
                variant="outlined"
                className="deleteMemberBtn"
                sx={{ width: "270px" }}
                onClick={() => setShowDeleteMemberDialog(true)}
              >
                {t("member-details.2")}
              </Button>
            </Box>
          </Box>
          <Box className="subMain">
            <Box className="subMain__memberInfo">
              <Box>
                <Typography> {t("member-details.4")}</Typography>
                <Typography
                  fontSize="1.3rem"
                  fontWeight="500"
                  sx={{
                    opacity: "0.6",
                  }}
                >
                  {memberData?.first_name}
                </Typography>
              </Box>
              <Box>
                <Typography> {t("member-details.5")}</Typography>
                <Typography
                  fontSize="1.3rem"
                  fontWeight="500"
                  sx={{
                    opacity: "0.6",
                  }}
                >
                  {memberData?.last_name}
                </Typography>
              </Box>
              <Box>
                <Typography> {t("member-details.6")}</Typography>
                <Typography
                  fontSize="1.3rem"
                  fontWeight="500"
                  sx={{
                    opacity: "0.6",
                  }}
                >
                  {moment(memberData?.birth_date).format("MM-DD-YYYY")}
                </Typography>
              </Box>
            </Box>
            {isLoading ? (
              <CustomLoader />
            ) : (
              <Box className="subMain__goalsContainer">
                <Box className="goals">
                  <Typography
                    fontSize="1.75rem"
                    color="#005994"
                    fontWeight="700"
                    margin="1rem 0"
                  >
                    {language === "en"
                      ? `${memberData?.first_name}${t("member-details.7")}`
                      : `${t("member-details.7")} ${memberData?.first_name}`}
                  </Typography>
                  {!isEmpty(activeGoal) && (
                    <ActiveGoal
                      goalId={activeGoal.goal_id}
                      responsible={activeGoal.who}
                      startDate={moment(activeGoal.start_date).format(
                        "MM-DD-YYYY"
                      )}
                      endDate={moment(activeGoal.start_date)
                        .add(
                          activeGoal?.how_long ? activeGoal.how_long * 7 : 0,
                          "days"
                        )
                        .format("MM-DD-YYYY")}
                      showNotifMethod={true}
                      notificationMethod={notificationFlag}
                      showCompletedBtn={true}
                      updatePlanToCompleted={updatePlanToCompletedHandler}
                    />
                  )}
                  {!activeGoal && (
                    <Typography
                      variant="h4"
                      fontWeight="700"
                      textAlign="center"
                    >
                      {t("member-details.10")}
                    </Typography>
                  )}
                  <Typography color="#005994" margin="1rem 0">
                    {t("member-details.9")}
                  </Typography>
                  <Box className="previousGoals">
                    {previousGoals?.length > 0 &&
                      previousGoals.map((previousGoal: any, idx: number) => (
                        <PreviousGoal
                          key={idx}
                          goalId={previousGoal.goal_id}
                          responsible={previousGoal.who}
                          startDate={moment(previousGoal.start_date).format(
                            "MM-DD-YYYY"
                          )}
                          endDate={durationValues[previousGoal.how_long]}
                          isDone={previousGoal.result === "yes"}
                          completed={previousGoal?.iscompleted}
                        />
                      ))}
                    {previousGoals?.length === 0 && (
                      <Typography
                        variant="h4"
                        fontWeight="700"
                        textAlign="center"
                      >
                        {t("member-details.11")}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className="btnContainer">
                  {isEmpty(activeGoal) && isEmpty(previousGoals) ? (
                    <Button
                      className="btn redoRiskBtn"
                      variant="contained"
                      onClick={startAssessmentHandler}
                    >
                      {t("member-details.12")}
                    </Button>
                  ) : (
                    <>
                      <Button
                        className="btn redoRiskBtn"
                        variant="contained"
                        onClick={startAssessmentHandler}
                      >
                        {!activeGoal
                          ? t("member-details.12")
                          : t("member-details.13")}
                      </Button>
                      <Button
                        className="btn showRiskBtn"
                        variant="contained"
                        onClick={() => navigate(`/risk-areas/${memberId}`)}
                      >
                        {t("member-details.14")}
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={0} />
      </Box>
    </>
  );
};

export default MemberDetails;
