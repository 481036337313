import React, { useState } from "react";
import {
  AlertColor,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "./ResetPassword.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import {
  authResetPassword,
  checkUnmatchedPwd,
} from "../../features/auth/asyncAction";
import {
  CheckCircle,
  Error,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import { useAppSelector } from "../../app/hooks";
import {
  checkLetterCaseInPassword,
  checkNumberSymbolInPassword,
  checkPasswordLength,
  validPassword,
} from "../../utils/common/checkValidator";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId, roleId } = useParams();
  const [password, setPassword] = useState("");
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const [hideDuration, setHideDuration] = React.useState<number>(3000);
  const [passwordError, setPasswordError] = useState(false);
  const showMemberStatus = useAppSelector(showMember);
  const [showPassword, setShowPassword] = useState(false);

  const resetPasswordHandler = async (ev: any) => {
    ev.preventDefault();
    const validPasswordStatus = validPassword(password);
    // logic for checking if the new password is different from the old password
    const unmatchedPwdData = await checkUnmatchedPwd({
      groupId,
      newPassword: password,
    });
    if (password) {
      if (validPasswordStatus) {
        if (unmatchedPwdData?.isUnmatchedPwd) {
          const resp = await authResetPassword({ roleId, groupId, password });
          setMessage(resp.message);
          setShowMessage(true);
          setMessageType(resp.status);
          setHideDuration(3000);
          if (resp.status === "success") {
            setTimeout(() => {
              navigate("/sign-in");
            }, 3000);
          }
        } else {
          setMessage(t("common.29"));
          setShowMessage(true);
          setMessageType("error");
          setHideDuration(5000);
        }
      } else {
        setPasswordError(true);
      }
    } else {
      setMessage(t("common.16"));
      setShowMessage(true);
      setMessageType("error");
    }
  };

  React.useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
        hideDuration={hideDuration}
      />
      <LanguageSupport showTranslateComponent />
      <Box className="resetPasswordPage">
        <Typography className="firstTypo" variant="h1">
          {t("reset-password.1")}
        </Typography>
        <Typography className="secondTypo" variant="h3">
          {t("reset-password.2")}
        </Typography>
        <Typography className="thirdTypo">{t("reset-password.3")}</Typography>
        {/* <TextField
          placeholder={t("reset-password.4")}
          className="passwordInput"
          autoComplete="off"
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
        /> */}
        <TextField
          label={t("reset-password.4")}
          // placeholder={t("reset-password.4")}
          className="passwordInput"
          autoComplete="off"
          autoCorrect="off"
          variant="outlined"
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          type={showPassword ? "text" : "password"}
          InputProps={{
            autoCorrect: "off",
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {password && !validPassword(password) && (
          <Box
            bgcolor="#e8eaf4"
            padding="16px 10px"
            margin="4px 0"
            marginTop="1rem"
            borderRadius="10px"
            maxWidth="410px"
            width="100%"
          >
            <Typography marginBottom="10px">{t("sign-up.16")}</Typography>
            <Typography
              display="flex"
              gap="6px"
              alignItems="start"
              marginLeft="1rem"
              marginBottom="4px"
            >
              {checkLetterCaseInPassword(password) ? (
                <CheckCircle style={{ color: "green" }} />
              ) : (
                <Error style={{ color: "red" }} />
              )}
              {t("sign-up.17")}
            </Typography>
            <Typography
              display="flex"
              gap="6px"
              alignItems="start"
              marginLeft="1rem"
              marginBottom="4px"
            >
              {checkNumberSymbolInPassword(password) ? (
                <CheckCircle style={{ color: "green" }} />
              ) : (
                <Error style={{ color: "red" }} />
              )}
              {t("sign-up.18")}
            </Typography>
            <Typography
              display="flex"
              gap="6px"
              alignItems="start"
              marginLeft="1rem"
              marginBottom="4px"
            >
              {checkPasswordLength(password) ? (
                <CheckCircle style={{ color: "green" }} />
              ) : (
                <Error style={{ color: "red" }} />
              )}
              {t("sign-up.19")}
            </Typography>
          </Box>
        )}
        <Button
          className="btn"
          variant="contained"
          onClick={resetPasswordHandler}
          disabled={!validPassword(password)}
        >
          {t("reset-password.1")}
        </Button>
      </Box>
    </>
  );
};

export default ResetPasswordPage;
