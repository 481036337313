import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "./UserAccount.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getUserData } from "../../../features/auth/authSlice";
import { fetchPcsByFamilyId } from "../../../features/user/asyncActions";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import { store } from "../../../app/store";

const UserAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const [userProviders, setUserProviders] = useState<any>([]);
  const [userCounselors, setUserCounselors] = useState<any>([]);
  const showMemberStatus = useAppSelector(showMember);

  const langages: any = {
    "0": t("common.30"),
    "1": t("common.31"),
    "2": t("common.32"),
    "3": t("common.33"),
  };

  const prefferedMethod: any = {
    "1": t("user-account.18"),
    "2": t("user-account.19"),
    "3": t("user-account.24"),
  };

  const fetchUserProviders = async () => {
    const { data } = await fetchPcsByFamilyId({
      fromId: userData?.group_id,
      roleId: "2",
    });

    setUserProviders(data);
  };

  const fetchUserCounselors = async () => {
    const { data } = await fetchPcsByFamilyId({
      fromId: userData?.group_id,
      roleId: "3",
    });
    setUserCounselors(data);
  };

  useEffect(() => {
    fetchUserProviders();
    fetchUserCounselors();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="userAccountPage">
        <Box className="userAccountPage__contentContainer">
          <Box className="subHeader">
            <Box
              display="flex"
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              flexDirection={{ xs: "column", sm: "row" }}
              columnGap="6rem"
              rowGap="1rem"
            >
              {userData?.first_Name && userData?.last_name && (
                <Typography sx={{ fontSize: "1.5rem", color: "#07120D" }}>
                  {userData.first_Name} {userData.last_name}
                </Typography>
              )}
              <Typography fontSize="1.5rem" color="#07120D">
                ID : {userData?.group_id}
              </Typography>
            </Box>
            <Button
              variant="contained"
              className="btn containedBtn"
              onClick={() => navigate("/edit-user-account")}
            >
              {t("user-account.1")}
            </Button>
          </Box>
          <Typography className="subTitle__info">
            {t("user-account.2")}
          </Typography>
          <Box className="subMain">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.3")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData?.first_Name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {" "}
                    {t("user-account.4")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData?.last_name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {" "}
                    {t("user-account.5")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.phone_number || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {" "}
                    {t("user-account.6")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData?.email || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {" "}
                    {t("user-account.7")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    ***********
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {" "}
                    {t("user-account.8")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.zip_code || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.10")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {prefferedMethod[userData?.notificationFlag as string]}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {" "}
                    {t("user-account.9")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {langages[userData?.lan_id as any]}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {userData?.authorized_code ? (
            <Typography className="subTitle__info">
              <span style={{ color: "#07120D", fontWeight: "normal" }}>{`${t(
                "user-account.21"
              )} : `}</span>
              {`${userData?.authorized_code}`}
            </Typography>
          ) : null}

          <Typography className="subTitle__info">
            {t("user-account.11")}
          </Typography>

          <Box className="subMain">
            {userProviders?.length > 0 &&
              userProviders?.map((provider: any, idx: number) => (
                <Box
                  key={provider.group_id}
                  display="flex"
                  marginBottom="1.5rem"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <Typography className="title" sx={{ minWidth: "250px" }}>
                    {t("user-account.12")} {idx + 1}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      minWidth: "250px",
                      opacity: "0.6",
                    }}
                  >
                    {provider?.first_Name} {provider?.last_name}
                  </Typography>
                </Box>
              ))}
            {userProviders.length === 0 && (
              <Typography sx={{ margin: "1rem 0" }}>
                {t("user-account.13")}
              </Typography>
            )}
            {userCounselors.length > 0 &&
              userCounselors.map((counselor: any, idx: number) => (
                <Box
                  key={counselor.group_id}
                  display="flex"
                  marginBottom="1.5rem"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  <Typography className="title" sx={{ minWidth: "250px" }}>
                    {t("user-account.14")} {idx + 1}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                      minWidth: "250px",
                    }}
                  >
                    {counselor?.first_Name} {counselor?.last_name}
                  </Typography>
                </Box>
              ))}
            {userCounselors.length === 0 && (
              <Typography sx={{ margin: "1rem 0" }}>
                {t("user-account.15")}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserAccount;
