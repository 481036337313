import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import "./StartAssessment.scss";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getCurrentMember } from "../../features/user/userSlice";
import {
  risksAssessmentAnswers,
  showMember,
  updateSelectedAnswers,
  updateShowMember,
} from "../../features/global/globalSlice";
import {
  infoIcon,
  questionGuideIcon,
  teethIcon,
} from "../../utils/common/global";
import { PlayCircleFilledRounded } from "@mui/icons-material";
import AudioSupport from "../../components/audio-support/AudioSupport";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";
import { updateMemberTrackingStatus } from "../../features/user/asyncActions";

const StartAssessment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const memberData = useAppSelector(getCurrentMember);
  const showMemberStatus = useAppSelector(showMember);

  useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(true));
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent showBackArrowComponent />
      <Box className="startAssessmentPage">
        <Box className="startAssessmentPage__contentContainer">
          <Box width="100%" paddingTop={4}>
            <AudioSupport
              audioText={`${t("start-assessment.1")} ${memberData.first_name} ${
                memberData.last_name
              } ${t("start-assessment.2")} ${t("start-assessment.3")} ${t(
                "start-assessment.4"
              )} ${t("start-assessment.9")} ${t("start-assessment.5")} ${t(
                "start-assessment.4"
              )} ${t("start-assessment.10")} ${t("start-assessment.6")} ${t(
                "start-assessment.4"
              )} ${t("start-assessment.11")} ${t(
                "start-assessment.7"
              )}              
              `}
            />
          </Box>
          <Box className="contentContainer">
            <Typography className="title" variant="h3">
              {t("start-assessment.1")}
            </Typography>
            <Typography className="user">
              {memberData.first_name} {memberData.last_name}
            </Typography>
            <Box className="content">
              <Typography className="contentText">
                {t("start-assessment.2")}
              </Typography>
              <Typography className="contentText">
                {t("start-assessment.3")}
              </Typography>
              <Typography
                className="contentText"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                {t("start-assessment.4")}
                <img src={infoIcon} alt="" style={{ margin: "0 6px" }} />
                {t("start-assessment.5")}
              </Typography>
              <Typography
                className="contentText"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                {t("start-assessment.4")}
                <img
                  src={questionGuideIcon}
                  alt=""
                  style={{ margin: "0 6px" }}
                />
                {t("start-assessment.6")}
              </Typography>
              <Typography
                className="contentText"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
              >
                {t("start-assessment.4")}
                <PlayCircleFilledRounded style={{ margin: "0 6px" }} />
                {t("start-assessment.7")}
              </Typography>
              <img src={teethIcon} alt="" className="teethIcon" />
            </Box>
            <Button
              className="btn"
              onClick={async () => {
                store.dispatch(updateSelectedAnswers(risksAssessmentAnswers));
                await updateMemberTrackingStatus(memberData.user_id, 1);
                navigate("/assessment?assessmentStep=0");
              }}
            >
              {t("start-assessment.8")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StartAssessment;
