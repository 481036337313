import customAxios from "../../utils/common/useAxios";

export const getPCUnderSite = async (siteId: any, roleId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/getPCUnderSite/${siteId}/${roleId}`,
    data: {},
  });
  return response;
};
export const getNotSiteAssignedPcs = async (roleId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/getNotSiteAssignedPcs/${roleId}`,
    data: {},
  });
  return response;
};

export const addPcToSite = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/sites/addPcToSite`,
    data: body,
  });
  return response;
};

export const removePcFromSite = async (body: any) => {
  const response = await customAxios({
    method: "delete",
    url: `/sites/removePcFromSite`,
    data: body,
  });
  return response;
};

export const fetchAllProviders = async (authorizedCode: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/pcs/allProviders/${authorizedCode}`,
    data: {},
  });
  return response;
};

export const fetchAllCounselors = async (authorizedCode: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/pcs/allCounselors/${authorizedCode}`,
    data: {},
  });
  return response;
};
export const getPcReportData = async (pcId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/pcs/getPcReportData/${pcId}`,
    data: {},
  });
  return response;
};
export const getSiteReportData = async (siteId: any) => {
  const response = await customAxios({
    method: "GET",
    url: `/sites/getSiteReportData/${siteId}`,
    data: {},
  });
  return response;
};
