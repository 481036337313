import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import "./ActiveGoal.scss";
import { useTranslation } from "react-i18next";
import { goalLogo, notifications } from "../../utils/common/global";

interface GoalProps {
  goalId: string;
  responsible: string;
  startDate: string;
  endDate: any;
  showNotifMethod?: boolean;
  notificationMethod?: any;
  showCompletedBtn?: boolean;
  updatePlanToCompleted?: () => void;
}

const ActiveGoal: React.FunctionComponent<GoalProps> = ({
  goalId,
  responsible,
  startDate,
  endDate,
  showNotifMethod = false,
  notificationMethod,
  showCompletedBtn = false,
  updatePlanToCompleted = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <Box className="activeGoal">
      <Box className="activeGoal__title">
        <img src={goalLogo} alt="" style={{ width: "40px", height: "40px" }} />
        {t("active-goal.1")}
      </Box>
      <Box className="activeGoal__content">
        <Box key={1} className="goalStep">
          <Box className="ruleIcon" />
          <Typography fontWeight="500" fontSize="1.1rem">
            {t(`all_goals.${goalId}`)}
          </Typography>
        </Box>
        <Box key={2} className="goalStep">
          <Box className="ruleIcon" />
          <Typography fontWeight="500" fontSize="1.1rem">
            {responsible} {t("active-goal.2")}
          </Typography>
        </Box>
        <Box key={3} className="goalStep">
          <Box className="ruleIcon" />
          <Typography fontWeight="500" fontSize="1.1rem">
            {t("active-goal.3")} {startDate}
          </Typography>
        </Box>
        <Box key={4} className="goalStep">
          <Box className="ruleIcon" />
          <Typography fontWeight="500" fontSize="1.1rem">
            {t("active-goal.4")} {endDate}
          </Typography>
        </Box>
      </Box>
      {showCompletedBtn && (
        <Button
          variant="contained"
          className="btn"
          sx={{ margin: "10px auto", width: "fit-content" }}
          onClick={updatePlanToCompleted}
        >
          {t("active-goal.6")}
        </Button>
      )}

      {showNotifMethod && (
        <Box
          display="flex"
          columnGap="0,5rem"
          justifyContent="center"
          margin="0.5rem"
        >
          <InfoOutlined />
          <Typography marginLeft="0.5rem">
            {t("active-goal.5")} {notifications[notificationMethod]}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ActiveGoal;
