import React, { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import { Drawer } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Header.scss";
import MainLogo from "../mainLogo/MainLogo";
import { useAppSelector } from "../../app/hooks";
import { getUserData } from "../../features/auth/authSlice";
import {
  redirectFromAccount,
  redirectFromHomeLink,
} from "../../utils/common/redirect";
import { persistor, store } from "../../app/store";
import { teethIcon } from "../../utils/common/global";
import { globalSignOut, showMember } from "../../features/global/globalSlice";
import { getCurrentMember } from "../../features/user/userSlice";

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authToken = localStorage.getItem("access-token");
  const showMemberStatus = useAppSelector(showMember);
  const memberData = useAppSelector(getCurrentMember);
  const userData = useAppSelector(getUserData);
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [customDropdown, setCustomDropdown] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleSlider = () => {
    setIsOpen(!isOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleSignOut = () => {
    localStorage.removeItem("access-token");
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    store.dispatch(globalSignOut({}));
    navigate("/sign-in");
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          minHeight: "70px",
          backgroundColor: "#CCDEEA",
          color: "#000",
        }}
      >
        <Container maxWidth="xl" sx={{ height: "100%" }}>
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  redirectFromHomeLink(userData?.role_id, userData?.isMove)
                )
              }
            >
              <MainLogo />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleSlider}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                className="drawer"
                open={isOpen}
                anchor="left"
                onClose={toggleSlider}
                sx={{
                  display: { xs: "block", md: "flex" },
                  width: "30%",
                }}
              >
                <Box
                  sx={{
                    padding: "1.5rem",
                  }}
                >
                  <Box
                    className="drawer__logo"
                    onClick={() => {
                      navigate("/");
                      toggleSlider();
                    }}
                  >
                    <MainLogo />
                  </Box>
                  <Box
                    onClick={() => {
                      navigate(
                        redirectFromHomeLink(
                          userData?.role_id,
                          userData?.isMove
                        )
                      );
                      toggleSlider();
                    }}
                    sx={{
                      marginTop: "1rem",
                    }}
                  >
                    <Button className="drawer__btn">{t("navbar.1")}</Button>
                  </Box>
                  {authToken ? (
                    <Box
                      onClick={() => {
                        navigate("/resources");
                        toggleSlider();
                      }}
                      sx={{
                        marginTop: "1rem",
                      }}
                    >
                      <Button className="drawer__btn">{t("navbar.2")}</Button>
                    </Box>
                  ) : null}

                  <Box
                    onClick={() => {
                      navigate("/about-us");
                      toggleSlider();
                    }}
                    sx={{
                      marginTop: "1rem",
                    }}
                  >
                    <Button className="drawer__btn">{t("navbar.5")}</Button>
                  </Box>
                </Box>
              </Drawer>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <MainLogo />
              </Box>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <div className="header__navlink">
                <NavLink
                  to={redirectFromHomeLink(userData?.role_id, userData?.isMove)}
                  className={({ isActive }) =>
                    isActive ? "navlink selected" : "navlink"
                  }
                >
                  {t("navbar.1")}
                </NavLink>
                {authToken ? (
                  <NavLink
                    to="/resources"
                    className={({ isActive }) =>
                      isActive ? "navlink selected" : "navlink"
                    }
                  >
                    {t("navbar.2")}
                  </NavLink>
                ) : null}

                <NavLink
                  to="/about-us"
                  className={({ isActive }) =>
                    isActive ? "navlink selected" : "navlink"
                  }
                >
                  {t("navbar.5")}
                </NavLink>
              </div>
            </Box>
            {showMemberStatus && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", sm: "none", md: "flex" },
                }}
                justifyContent={{ md: "end" }}
              >
                <Typography
                  marginRight="2rem"
                  fontSize="1.4rem"
                  fontWeight="600"
                  color="#005994"
                >
                  {memberData?.first_name}
                </Typography>
              </Box>
            )}

            {authToken && (
              <Box sx={{ flexGrow: 0 }}>
                <div
                  className="header__profileMenu"
                  onClick={() => setCustomDropdown(!customDropdown)}
                >
                  <Avatar
                    alt=""
                    src={teethIcon}
                    style={{
                      marginRight: "8px",
                      border: "2px solid #005994",
                    }}
                  />
                  <Box sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}>
                    <span className="username">
                      {userData?.first_Name}{" "}
                      {userData?.last_name?.slice(0, 1)?.toUpperCase()}
                    </span>
                    <span>
                      <KeyboardArrowDown />
                    </span>
                  </Box>
                </div>
                {customDropdown && (
                  <div className="customDropdown">
                    <div
                      className="menu__item"
                      onClick={() => {
                        redirectFromAccount(userData?.role_id, navigate);
                        setCustomDropdown(false);
                      }}
                    >
                      <Typography textAlign="center">
                        {t("navbar.3")}
                      </Typography>
                    </div>
                    <div
                      className="menu__item"
                      onClick={() => {
                        handleSignOut();
                        setCustomDropdown(false);
                      }}
                    >
                      <Typography textAlign="center">
                        {t("navbar.4")}
                      </Typography>
                    </div>
                  </div>
                )}
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    key="account"
                    onClick={() => {
                      redirectFromAccount(userData?.role_id, navigate);
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign="center">{t("navbar.3")}</Typography>
                  </MenuItem>
                  <MenuItem
                    key="singOut"
                    onClick={() => {
                      handleSignOut();
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign="center">{t("navbar.4")}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
          {showMemberStatus && (
            <Toolbar
              disableGutters
              sx={{
                display: { xs: "flex", sm: "flex", md: "none" },
                borderTop: "1px solid #ccc",
              }}
            >
              <Box width="100%">
                <Typography
                  fontSize="1.3rem"
                  fontWeight="600"
                  color="#005994"
                  width="100%"
                  textAlign="end"
                  paddingRight="1rem"
                >
                  {memberData?.first_name}
                </Typography>
              </Box>
            </Toolbar>
          )}
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
