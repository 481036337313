const CryptoJS = require("crypto-js");

export const cryptedCodeGenerator = (code: any) => {
  if (!code) return "";
  const cryptedCodeStr = CryptoJS.AES.encrypt(
    code,
    "My Smile Buddy secret key"
  ).toString();
  return cryptedCodeStr;
};

export const decryptedCodeGenerator = (encryptedCode: any) => {
  if (!encryptedCode) return "";
  const bytes = CryptoJS.AES.decrypt(
    encryptedCode,
    "My Smile Buddy secret key"
  );
  const decryptedCodeStr = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedCodeStr;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const digitsOnly = phoneNumber.replace(/\D/g, "");

  if (digitsOnly.length === 10) {
    return `+1${digitsOnly}`;
  } else {
    return phoneNumber;
  }
};

export const normalizePhoneNumber = (phoneNumber: string) => {
  let digitsOnly = phoneNumber.replace(/\D/g, "");

  // Remove "+1" if it exists
  if (digitsOnly.startsWith("1")) {
    digitsOnly = digitsOnly.slice(1);
  }

  // Check if the remaining number has exactly 10 digits
  if (digitsOnly.length === 10) {
    return digitsOnly;
  } else {
    return phoneNumber;
  }
};
