import React, { useState } from 'react';
import { Box } from '@mui/material';
import { teethIcon } from '../../utils/common/global';
import { CloseRounded } from '@mui/icons-material';
import './GuidePopup.scss';
import AudioSupport from '../audio-support/AudioSupport';
type GuideProps = {
  text: any;
  top: string;
  left: string;
  width: string;
  show?: number;
  activeGuide?: boolean;
  setShow?: (val: number) => void;
  audioText?: string;
};

const GuidePopup = ({
  text,
  width,
  top,
  left,
  show = 0,
  setShow,
  activeGuide = true,
  audioText = '',
}: GuideProps) => {
  const [showGuide, setShowGuide] = useState(activeGuide);
  return (
    <>
      {showGuide && (
        <Box
          className="guidePopupContainer"
          position="absolute"
          top="0"
          left="0"
        >
          <Box
            display="flex"
            sx={{ marginTop: top, marginLeft: left }}
            className="guidePopup"
            width={width}
          >
            <img src={teethIcon} alt="" className="teethIcon" />
            <Box className="guide">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <AudioSupport
                  showText={false}
                  width="fit-content"
                  padding="4px 0"
                  audioText={audioText}
                />
                <CloseRounded
                  className="closeIcon"
                  onClick={() => {
                    setShowGuide(false);
                    setShow?.(show + 1);
                  }}
                />
              </Box>
              {text}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default GuidePopup;
