import React, { useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "./LicenseDetails.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import moment from "moment";
import { fetchLicense } from "../../../features/administrator/asyncAction";
import { getAllSitesByMangerId } from "../../../features/manager/asyncActions";
import { isEmpty } from "lodash";

const LicenseDetails: React.FC = () => {
  const { t } = useTranslation();
  const { licenseId } = useParams();
  const navigate = useNavigate();
  const [license, setLicense] = useState<any>({});
  const [sites, setSites] = React.useState<any>([]);
  const [registredUsers, setRegistredUsers] = useState(0);

  const getLicense = async () => {
    const { data } = await fetchLicense(licenseId);
    setLicense(data);
  };

  const getSites = async () => {
    const { data } = await getAllSitesByMangerId(license.manager_id);
    setSites(data);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if (!licenseId) navigate("/administrator-dashboard");
    getLicense();
  }, []);

  React.useEffect(() => {
    if (licenseId && !isEmpty(license)) getSites();
  }, [license]);

  React.useEffect(() => {
    if (sites.length > 0) {
      const registredUsers = sites?.reduce(
        (accumulator: any, site: any) => accumulator + site?.total_users,
        0
      );
      setRegistredUsers(registredUsers);
    }
  }, [sites]);

  return (
    <>
      <LanguageSupport showTranslateComponent showBackArrowComponent />
      <Box className="licenseDetailsPage">
        <Box className="licenseDetailsPage__container">
          <Typography className="licenseDetailsPage__title" variant="h3">
            {t("license-details.1")}
          </Typography>
          <Box
            display="flex"
            marginTop="2rem"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{
              xs: "column-reverse",
              md: "row",
            }}
          >
            <Typography
              marginRight="auto"
              variant="h5"
              fontWeight="700"
              color="#005994"
            >
              {t("license-details.2")}
            </Typography>
            <Button
              variant="contained"
              className="btn"
              onClick={() =>
                navigate(`/edit-license-details/${license.license_id}`)
              }
            >
              {t("license-details.3")}
            </Button>
          </Box>

          <Box className="license-information">
            <Typography className="info">
              <strong>{t("license-details.4")}: </strong>
              {license.name}
            </Typography>
            <Typography className="info">
              <strong>{t("license-details.5")}: </strong>
              {moment(license.start_date).format("MM/DD/YYYY")}
            </Typography>
            <Typography className="info">
              <strong>{t("license-details.6")}: </strong>
              {moment(license.start_date)
                .add(license.duration, "months")
                .format("MM/DD/YYYY")}
            </Typography>
            <Typography className="info">
              <strong>{t("license-details.7")}: </strong>
              {license.duration} {t("license-details.17")}
            </Typography>
            <Typography className="info">
              <strong>{t("license-details.8")}: </strong>
              {license.total_users}
            </Typography>
            <Typography className="info">
              <strong>{t("license-details.9")}: </strong>
              {registredUsers}
            </Typography>
            <Typography className="info">
              <strong>{t("license-details.10")}: </strong>
              {parseInt(license.total_users) - registredUsers}
            </Typography>
          </Box>

          <Typography
            marginTop="2rem"
            marginBottom="0.7rem"
            marginRight="auto"
            variant="h5"
            fontWeight="700"
            color="#005994"
          >
            {t("license-details.11")}
          </Typography>

          <Box className="license-information">
            <Typography className="info">
              <strong>{t("license-details.12")}: </strong>
              {license.first_Name &&
                license.last_name &&
                `${license.first_Name} ${license.last_name}`}
            </Typography>
            <Typography className="info">
              <strong>{t("license-details.13")}: </strong>
              {license.phone_number}
            </Typography>
            <Typography className="info">
              <strong>{t("license-details.14")}: </strong>
              {license.email}
            </Typography>
          </Box>

          <Typography
            className="licenseDetailsPage__title"
            marginTop="3rem"
            variant="h3"
          >
            {t("license-details.15")}
          </Typography>
          <Grid
            container
            spacing={3}
            sx={{ marginTop: "1rem" }}
            className="gridContainer"
          >
            {sites?.length > 0 &&
              sites?.map((site: any) => (
                <Grid
                  item
                  key={site.sites_id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                >
                  <Box className="site">
                    <Typography className="site__title">
                      {site.description}
                    </Typography>
                    <Typography className="site__desc">
                      <strong>code:</strong> {site.unique_code}
                    </Typography>
                    <Typography className="site__desc">
                      <strong>{t("manager-dashboard.30")}:</strong>{" "}
                      {site.total_users}
                    </Typography>
                    <Typography
                      className="details"
                      onClick={() => navigate(`/site-details/${site.sites_id}`)}
                    >
                      see details
                    </Typography>
                  </Box>
                </Grid>
              ))}
            {sites?.length === 0 && (
              <Typography
                variant="h4"
                width="100%"
                fontWeight="700"
                textAlign="center"
                marginTop="2rem"
                paddingBottom="3rem"
              >
                {t("license-details.16")}
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default LicenseDetails;
