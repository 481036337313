import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, AlertColor } from "@mui/material";
import "./ManagerDashboard.scss";
import { useTranslation } from "react-i18next";
import { AddRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ConfirmationModal, {
  SubmitParams,
} from "../../../components/confirmation-modal/ConfirmationModal";
import { useAppSelector } from "../../../app/hooks";
import { getUserData } from "../../../features/auth/authSlice";
import {
  fetchAllSites,
  fetchSitesBymId,
  getAllSitesBymId,
} from "../../../features/manager/managerSlice";
import { store } from "../../../app/store";
import { addNewSite } from "../../../features/manager/asyncActions";
import CustomAlert from "../../../components/custom-alert/CustomAlert";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import randomstring from "randomstring";
import { fetchLicenseByMId } from "../../../features/administrator/asyncAction";
import moment from "moment";
import { isEmpty } from "lodash";

const ManagerDashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const sites = useAppSelector(getAllSitesBymId);
  const [showAddSiteDialog, setShowAddSiteDialog] = useState(false);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const [nickname, setNickname] = useState("");
  const showMemberStatus = useAppSelector(showMember);
  const [license, setLicense] = useState<any>({});
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [newSite, setnewSite] = useState<any>({});

  const getLicense = async () => {
    const { data } = await fetchLicenseByMId(userData.group_id);
    setLicense(data);
  };

  const submitParams: SubmitParams = {
    btnContent: currentStep === 0 ? t("common.3") : t("common.1"),
    submitHandler: async (ev) => {
      if (currentStep === 0) {
        await addSiteHandler(nickname);
        await fetchSitesByManagerId();
        await fetchSites();
        setCurrentStep(1);
      } else {
        setNickname("");
        setShowAddSiteDialog(false);
        setCurrentStep(0);
      }
    },
    disabledRules: nickname?.length <= 2,
  };

  const addSiteHandler = async (nickname: string) => {
    const data = await addNewSite({
      managerId: userData.group_id,
      description: nickname,
      unique_code: randomstring.generate({
        length: 8,
      }),
    });
    const { message, status, newSite } = data;
    if (!isEmpty(newSite)) {
      setnewSite(newSite);
    }
    setShowMessage(true);
    setMessage(message);
    setMessageType(status);
  };

  const fetchSitesByManagerId = async () => {
    const data = await store.dispatch(fetchSitesBymId(userData.group_id));
    return data;
  };
  const fetchSites = async () => {
    const data = await store.dispatch(fetchAllSites({}));
    return data;
  };

  useEffect(() => {
    fetchSitesByManagerId();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    getLicense();
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <ConfirmationModal
        open={showAddSiteDialog}
        closeHandler={() => {
          setShowAddSiteDialog(false);
        }}
        title={
          currentStep === 0
            ? t("manager-dashboard.4")
            : t("manager-dashboard.31")
        }
        submitParams={submitParams}
        showCancelButton={currentStep === 0 ? true : false}
        showIcon={false}
      >
        {currentStep === 0 ? (
          <>
            <Box className="addSiteContainer">
              <Typography
                className="addSiteTitle"
                textAlign="start"
                width="300px"
              >
                {t("manager-dashboard.5")}
              </Typography>
              <input
                type="text"
                name="nickname"
                placeholder={t("manager-dashboard.6")}
                className="input"
                value={nickname}
                onChange={(ev: any) => setNickname(ev.target.value)}
              />
            </Box>
          </>
        ) : (
          <Box className="addSiteContainer">
            <Typography className="addSiteTitle" width="100%" textAlign="start">
              {t("manager-dashboard.32")}:{" "}
              <strong>{newSite?.description}</strong>
            </Typography>
            <Typography className="addSiteTitle" width="100%" textAlign="start">
              {t("manager-dashboard.33")}:{" "}
              <strong>{newSite?.unique_code}</strong>
            </Typography>
            <Typography
              className="addSiteTitle"
              marginTop="1rem"
              width="100%"
              textAlign="start"
            >
              {t("manager-dashboard.34")}: <strong></strong>
            </Typography>
          </Box>
        )}
      </ConfirmationModal>
      <Box className="managerDashboardPage">
        <Box className="managerDashboardPage__container">
          <Box className="subHeader">
            <Typography
              className="title"
              variant="h4"
              display="flex"
              justifyContent="space-between"
            >
              {t("manager-dashboard.28")}
            </Typography>
            <Button
              variant="contained"
              className="btn containedBtn"
              style={{ marginLeft: "auto" }}
              onClick={() => setShowAddSiteDialog(true)}
            >
              {t("manager-dashboard.3")}
            </Button>
          </Box>

          <Grid
            container
            spacing={3}
            className="gridContainer"
            style={{
              marginBottom: "4rem",
            }}
          >
            {sites?.map((site: any) => (
              <Grid
                item
                key={site.sites_id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              >
                <Box className="site">
                  <Typography className="site__title">
                    {/* {t("navigation.6")} {site.sites_id} */}
                    {site.description}
                  </Typography>
                  <Typography className="site__desc">
                    <strong>{t("manager-dashboard.29")}:</strong>{" "}
                    {site.unique_code}
                  </Typography>
                  <Typography className="site__desc">
                    <strong>{t("manager-dashboard.30")}:</strong>{" "}
                    {site.total_users}
                  </Typography>
                  <Typography
                    className="details"
                    onClick={() => navigate(`/site-details/${site.sites_id}`)}
                  >
                    see details
                  </Typography>
                </Box>
              </Grid>
            ))}
            <Grid item key={"add-new"} xs={12} sm={6} md={4} lg={3} xl={3}>
              <Box
                className="addNew"
                onClick={() => setShowAddSiteDialog(true)}
              >
                <AddRounded sx={{ fontSize: "1.5rem" }} className="addIcon" />
              </Box>
            </Grid>
          </Grid>

          {!isEmpty(license) && (
            <>
              <Typography className="title" variant="h4" marginTop="1rem">
                {t("manager-dashboard.23")}
              </Typography>
              <Grid
                container
                spacing={3}
                sx={{ marginTop: "1rem", marginBottom: "4rem" }}
                className="license-info"
              >
                <Grid item key={1} xs={12} sm={6} md={4} lg={4} xl={4}>
                  <Typography className="license-info__title">
                    <strong>{t("manager-dashboard.24")}: </strong>{" "}
                    {license.name}
                  </Typography>
                </Grid>
                <Grid item key={1} xs={12} sm={6} md={4} lg={4} xl={4}>
                  <Typography className="license-info__title">
                    <strong>{t("manager-dashboard.25")}: </strong>
                    {moment(license.start_date)
                      .add(license.duration, "months")
                      .format("MM/DD/YYYY")}
                  </Typography>
                </Grid>
                <Grid item key={1} xs={12} sm={6} md={4} lg={4} xl={4}></Grid>
                <Grid item key={1} xs={12} sm={6} md={4} lg={4} xl={4}>
                  <Typography className="license-info__title">
                    <strong>{t("manager-dashboard.26")}: </strong>
                    {sites?.reduce(
                      (accumulator, site) => accumulator + site?.total_users,
                      0
                    )}
                  </Typography>
                </Grid>
                <Grid item key={1} xs={12} sm={6} md={4} lg={4} xl={4}>
                  <Typography className="license-info__title">
                    <strong>{t("manager-dashboard.27")}: </strong>
                    {parseInt(license.total_users) -
                      sites?.reduce(
                        (accumulator, site) => accumulator + site?.total_users,
                        0
                      )}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ManagerDashboard;
