import * as React from "react";
import { Box, Typography } from "@mui/material";
import { goalLogo, teethIcon } from "../../utils/common/global";
import "./PreviousGoal.scss";
import { useTranslation } from "react-i18next";

interface GoalProps {
  goalId: string;
  responsible: string;
  startDate: string;
  endDate: string;
  isDone?: boolean;
  completed: boolean;
}

const PreviousGoal: React.FunctionComponent<GoalProps> = ({
  goalId,
  responsible,
  startDate,
  endDate,
  isDone = false,
  completed,
}) => {
  const { t } = useTranslation();
  return (
    <Box className="previousGoal">
      <Box className="previousGoal__title">
        <img src={goalLogo} alt="" style={{ width: "40px", height: "40px" }} />
        {t("previous-goal.1")}
        {isDone && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={teethIcon}
              alt=""
              style={{ width: "30px", height: "30px" }}
            />
            <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
              Done
            </Typography>
          </Box>
        )}
      </Box>
      <Box className="previousGoal__content">
        <Box key={1} className="goalStep">
          <Box className="ruleIcon" />
          <Typography fontWeight="500" fontSize="1.1rem">
            {t(`all_goals.${goalId}`)} -{" "}
            {`${completed ? t("previous-goal.5") : t("previous-goal.6")}`}
          </Typography>
        </Box>
        <Box key={2} className="goalStep">
          <Box className="ruleIcon" />
          <Typography fontWeight="500" fontSize="1.1rem">
            {responsible} {t("previous-goal.2")}
          </Typography>
        </Box>
        <Box key={3} className="goalStep">
          <Box className="ruleIcon" />
          <Typography fontWeight="500" fontSize="1.1rem">
            {t("previous-goal.3")} {startDate}
          </Typography>
        </Box>
        <Box key={4} className="goalStep">
          <Box className="ruleIcon" />
          <Typography fontWeight="500" fontSize="1.1rem">
            {t("previous-goal.4")} {endDate}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PreviousGoal;
