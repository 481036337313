import * as React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getGlobalLanguage } from "../../features/global/globalSlice";
import { firstFollowUpFlyer } from "../../utils/common/global";
import { BeatLoader } from "react-spinners";

const FollowUpFlyers: React.FC = () => {
  const { goalId } = useParams();
  const [loading, setLoading] = React.useState(true);
  const language = useAppSelector(getGlobalLanguage);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [language]);

  return (
    <Box padding="1rem">
      {loading ? (
        <Box display="flex" alignItems="center" minHeight={400}>
          <BeatLoader color="#1976d2" size={25} />
        </Box>
      ) : (
        <img
          src={firstFollowUpFlyer(language, goalId ?? "")}
          style={{
            maxWidth: "70%",
            margin: "2.5rem auto",
            height: "auto",
            objectFit: "contain",
            display: `${loading ? "none" : "block"}`,
          }}
          alt=""
        />
      )}
    </Box>
  );
};

export default FollowUpFlyers;
