import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rsuite';
import './CookieUI.scss';
import { useTranslation } from 'react-i18next';
type Props = {
  cookies: any;
  setCookie: (
    name: 'access_token' | 'refresh_token',
    val: string,
    options: any
  ) => void;
};

const CookieUI = ({ cookies, setCookie }: Props) => {
  const { t } = useTranslation();
  const acceptCookieHandler = () => {
    let expires = new Date();
    expires.setTime(expires.getTime() + 1000 * 3600);
    setCookie('access_token', 'msb_test', { path: '/', expires });
    setCookie('refresh_token', 'msb_test', { path: '/', expires });
  };

  const navigate = useNavigate();
  return (
    <Box className="cookie">
      <Box className="cookie__info">
        <Typography className="title">{t('cookie-consent.1')}</Typography>
        <Typography className="desc">{t('cookie-consent.2')}</Typography>
      </Box>
      <Box className="cookie__btnGroup">
        <Button className="btn contained" onClick={acceptCookieHandler}>
          {t('cookie-consent.3')}
        </Button>
        <Button className="btn outlined" onClick={() => navigate('/privacy')}>
          {t('cookie-consent.4')}
        </Button>
      </Box>
    </Box>
  );
};

export default CookieUI;
