import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import "./Overview.scss";
import { useTranslation } from "react-i18next";
import CustomStepper from "../../components/customStepper/CustomStepper";
import StepInformation from "../../components/stepInformation/StepInformation";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import { teethIcon } from "../../utils/common/global";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import AudioSupport from "../../components/audio-support/AudioSupport";
import { store } from "../../app/store";

const Overview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showMemberStatus = useAppSelector(showMember);

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <AudioSupport
        audioText={`${t("overview.6")} :
      1. ${t("steps.1")}: ${t("overview.1")}
      2. ${t("steps.2")}: ${t("overview.2")}
      3. ${t("steps.3")}: ${t("overview.3")}
      4. ${t("steps.4")}: ${t("overview.4")}
      5. ${t("steps.5")}: ${t("overview.5")}
      `}
      />
      <Box className="overviewPage">
        <Box className="overviewPage__progressSection">
          <img src={teethIcon} alt="" className="logo" />
          <Box className="progressContainer">
            <Typography sx={{ color: "#787D86", textAlign: "center" }}>
              {t("overview.6")}
            </Typography>
            <CustomStepper activeStep={2} />
          </Box>
        </Box>
        <Box className="overviewPage__information">
          <Box sx={{ marginBottom: "2.5rem" }}>
            {Array.from(Array(5).keys()).map((_, idx) => (
              <StepInformation
                key={idx}
                title={t(`steps.${idx + 1}`)}
                desc={t(`overview.${idx + 1}`)}
                idx={idx + 1}
              />
            ))}
          </Box>
          <Button
            variant="contained"
            className="btn"
            sx={{ width: "270px", alignSelf: "center" }}
            onClick={() => navigate("/signup-new/pc-relation-info")}
          >
            {t("common.3")}
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={0} />
      </Box>
    </>
  );
};

export default Overview;
