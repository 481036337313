import validator from "validator";

export const checkPasswordLength = (password: string) =>
  validator.isLength(password, { min: 8 });

export const checkNumberSymbolInPassword = (password: string) =>
  validator.isStrongPassword(password, {
    minLength: 0,
    minLowercase: 0,
    minUppercase: 0,
    minNumbers: 1,
    minSymbols: 1,
  });

export const checkLetterCaseInPassword = (password: string) =>
  validator.isStrongPassword(password, {
    minLength: 0,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 0,
    minSymbols: 0,
  });

export const validPassword = (password: string) =>
  validator.isStrongPassword(password, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  });

export const validPhoneNumber = (phone: string) =>
  validator.isMobilePhone(phone, "en-US");

export const validEmail = (email: string) => validator.isEmail(email);
