import * as React from "react";
import AWS from "aws-sdk";

import { PlayArrow, Pause } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./AudioSupport.scss";
import { useAppSelector } from "../../app/hooks";
import { getGlobalLanguage } from "../../features/global/globalSlice";
import { useTranslation } from "react-i18next";

AWS.config.update({ region: process.env.REACT_APP_AWS_REGION });

const polly = new AWS.Polly({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

interface AudioSupportProps {
  width?: string;
  showText?: boolean;
  padding?: string;
  audioText?: string;
}

const AudioSupport: React.FC<AudioSupportProps> = ({
  width = "100%",
  showText = true,
  padding = "1.5rem",
  audioText,
}) => {
  const { t } = useTranslation();
  const language = useAppSelector(getGlobalLanguage);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [playing, setPlaying] = React.useState<boolean>(false);
  const audioRef = React.useRef<any>();
  const audioSourceRef = React.useRef<any>();

  const startAudio = async () => {
    try {
      setIsLoading(true);
      pauseAudio();
      const params: any = {
        OutputFormat: "mp3",
        Text: audioText ?? "audio not available",
        VoiceId: "Joanna",
      };

      const response: any = await polly.synthesizeSpeech(params).promise();
      const audioData: ArrayBuffer = response?.AudioStream?.buffer;
      const audioContextRef = new (window.AudioContext ||
        window.webkitAudioContext)();
      const audioBuffer = await audioContextRef.decodeAudioData(audioData);
      audioSourceRef.current = audioContextRef.createBufferSource();
      audioSourceRef.current.buffer = audioBuffer;
      audioSourceRef.current.connect(audioContextRef.destination);
      audioSourceRef.current.start();

      setPlaying(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error generating or playing audio:", error);
    }
  };

  const pauseAudio = () => {
    audioSourceRef?.current?.stop();
    setPlaying(false);
  };

  React.useEffect(() => {
    pauseAudio();
  }, [language]);

  React.useEffect(() => {
    const audioElement = audioRef.current;
    audioElement?.addEventListener("ended", () => setPlaying(false));
    return () => {
      audioElement?.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return (
    <Box className="audioSupport" width={width} padding={padding}>
      <Box
        className="audio"
        onClick={() => (playing ? pauseAudio() : startAudio())}
      >
        <Box className="audioContainer">
          {isLoading ? (
            <CircularProgress
              style={{ color: "#fff" }}
              className="audioIcon"
              size="12px"
            />
          ) : (
            <>
              {playing ? (
                <Pause className="audioIcon" />
              ) : (
                <PlayArrow className="audioIcon" />
              )}
            </>
          )}
        </Box>

        {showText && (
          <Typography className="audioText">{t("common.15")}</Typography>
        )}
      </Box>
      <audio ref={audioRef} />
    </Box>
  );
};

export default AudioSupport;
