import React, { useEffect } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "./AdministratorAccount.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getUserData } from "../../../features/auth/authSlice";
import { store } from "../../../app/store";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";

const AdministratorAccount = () => {
  const { t } = useTranslation();
  const userData = useAppSelector(getUserData);
  const navigate = useNavigate();
  const showMemberStatus = useAppSelector(showMember);

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="administratorAccountPage">
        <Box className="administratorAccountPage__contentContainer">
          <Box className="subHeader">
            <Box
              display="flex"
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              flexDirection={{ xs: "column", sm: "row" }}
              columnGap="6rem"
              rowGap="1rem"
            >
              {userData?.first_Name && userData.last_name && (
                <Typography fontSize="1.5rem" color="#07120D">
                  {userData.first_Name} {userData.last_name}
                </Typography>
              )}
              <Typography fontSize="1.5rem" color="#07120D">
                ID : {userData?.group_id}
              </Typography>
            </Box>

            <Button
              variant="contained"
              className="btn containedBtn"
              onClick={() => navigate("/edit-administrator-account")}
            >
              {t("common.6")}
            </Button>
          </Box>
          <Typography className="subTitle__info">
            {t("user-account.2")}
          </Typography>
          <Box className="subMain">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.3")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.first_Name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.4")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.last_name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.5")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.phone_number || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.6")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData?.email || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.7")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    ***********
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdministratorAccount;
