import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import useAxios from '../../utils/common/useAxios';
// import { RootState, AppThunk } from '../../app/store';

interface userState {
  allFamilyMembers: Array<any>;
  familiesUnderPcId: Array<any>;
  activeRiskAndGoal: any;
  currentMemberData: any;
  message: string;
  status: string;
}

const initialState: userState = {
  allFamilyMembers: [],
  familiesUnderPcId: [],
  currentMemberData: {},
  activeRiskAndGoal: {},
  message: '',
  status: '',
};

export const addPcByFamily = createAsyncThunk(
  'user/addPcByFamily',
  async (body: any) => {
    const response = await useAxios({
      method: 'POST',
      url: `/users/addPcByFamily`,
      data: body,
    });
    return response;
  }
);
export const allFamilyMembers = createAsyncThunk(
  'user/AllfamilyMembers',
  async (familyId: any) => {
    const response = await useAxios({
      method: 'GET',
      url: `/users/getAllfamilyMembers/${familyId}`,
      data: {},
    });
    return response;
  }
);

export const fetchFamiliesUnderPcId = createAsyncThunk(
  'user/familiesUnderPcId',
  async (pcId: any) => {
    const response = await useAxios({
      method: 'GET',
      url: `/group/getFamiliesUnderPCId/${pcId}`,
      data: {},
    });
    return response;
  }
);

export const saveZipcode = createAsyncThunk(
  'user/saveZipcode',
  async (body: any) => {
    const response = await useAxios({
      method: 'POST',
      url: 'users/saveZipcode',
      data: body,
    });
    return response;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveRiskAndGoal: (state, action) => {
      state.activeRiskAndGoal = action.payload;
    },
    updateMemberData: (state, action) => {
      state.currentMemberData = action.payload;
    },
  },
  extraReducers: {
    [allFamilyMembers.fulfilled as any]: (state, action) => {
      const { data } = action.payload;
      state.allFamilyMembers = data;
    },
    [addPcByFamily.fulfilled as any]: (state, action) => {
      const { message, status } = action.payload;
      state.message = message;
      state.status = status;
    },

    [saveZipcode.fulfilled as any]: (state, action) => {
      const { message, status } = action.payload;
      state.message = message;
      state.status = status;
    },

    [fetchFamiliesUnderPcId.fulfilled as any]: (state, action) => {
      state.familiesUnderPcId = action.payload.data;
    },
  },
});

export const { saveRiskAndGoal, updateMemberData } = userSlice.actions;

export const getAllfamilyMembers = (state: RootState) =>
  state.user.allFamilyMembers;

export const getCurrentMember = (state: RootState) =>
  state.user.currentMemberData;
export const getFamiliesUnderPCId = (state: RootState) =>
  state.user.familiesUnderPcId;

export const getActiveRiskAndGoal = (state: RootState) =>
  state.user.activeRiskAndGoal;
export default userSlice.reducer;
