import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import "../common/GoalSettings.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getCurrentMember } from "../../../features/user/userSlice";
import { getUserData, loadUserData } from "../../../features/auth/authSlice";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import {
  getAllRiskAreas,
  getRiskAreasByMemberId,
  updateIsMoveStatus,
} from "../../../features/user/asyncActions";
import { store } from "../../../app/store";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import AudioSupport from "../../../components/audio-support/AudioSupport";
import CustomStepper from "../../../components/customStepper/CustomStepper";
import { teethIcon } from "../../../utils/common/global";

const SettingsOverview = () => {
  const navigate = useNavigate();
  const memberData = useAppSelector(getCurrentMember);
  const [allRiskAreas, setAllRiskAreas] = React.useState<any>([]);
  const [memberRiskAreas, setMemberRiskAreas] = React.useState<any>([]);
  const userData = useAppSelector(getUserData);
  const showMemberStatus = useAppSelector(showMember);
  const { t } = useTranslation();

  const fetchAllRisksArea = async () => {
    const data = await getAllRiskAreas(memberData?.user_id);
    if (data) setAllRiskAreas(data);
  };

  const fetchMemberRisksArea = async () => {
    const data = await getRiskAreasByMemberId(memberData?.user_id);
    if (data) setMemberRiskAreas(data);
  };

  const safeAreas = allRiskAreas.filter((area: any) => {
    let exist = true;
    if (memberRiskAreas.length === 0) return exist;
    for (const riskArea of memberRiskAreas) {
      if (area.risk_id === riskArea.risk_id) exist = false;
    }
    return exist;
  });

  React.useEffect(() => {
    fetchAllRisksArea();
    fetchMemberRisksArea();
    if (userData?.isMove === 0) updateIsMoveStatus(userData?.group_id);
    store.dispatch(loadUserData(userData?.group_id));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  React.useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(true));
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="goalSettingsPage">
        <Box className="goalSettingsPage__contentContainer">
          <Box className="subHeader">
            <AudioSupport
              audioText={`${t("goal-settings-intro.1")} ${
                memberData?.first_name
              } ${memberData?.last_name}  ${t("goal-settings-intro.7")} ${t(
                "goal-settings-intro.8"
              )} `}
            />
          </Box>
          <Box className="subMain">
            <Box className="secondIntroContainer">
              <Typography className="intro__title" variant="h2" fontSize="40px">
                {t("goal-settings-intro.1")}
              </Typography>
              <Typography className="intro__username">
                {memberData?.first_name} {memberData?.last_name}
              </Typography>
              <Box className="intro">
                <Box display={{ xs: "none", sm: "none", md: "flex" }}>
                  <img src={teethIcon} alt="" className="teethIcon" />
                </Box>
                <Box className="textContainer">
                  <Typography
                    className="text"
                    variant="body1"
                    fontSize="1.5rem"
                  >
                    {t("goal-settings-intro.7")}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    columnGap="3rem"
                    rowGap="2rem"
                    alignItems={{
                      xs: "flex-start",
                      sm: "flex-start",
                      md: "center",
                    }}
                    width="100%"
                    flexDirection={{
                      xs: "column",
                      sm: "column",
                      md: "row",
                    }}
                  >
                    <Typography
                      className="text"
                      variant="body1"
                      fontSize="1.5rem"
                    >
                      {t("goal-settings-intro.8")}
                    </Typography>
                    <Button
                      className="btn"
                      sx={{
                        alignSelf: {
                          xs: "flex-end",
                          sm: "flex-end",
                          md: "auto",
                        },
                      }}
                      onClick={() =>
                        safeAreas?.length > 0
                          ? navigate("/goal-settings/safe-areas")
                          : navigate("/goal-settings/risk-areas")
                      }
                    >
                      {t("common.12")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={2} />
      </Box>
    </>
  );
};

export default SettingsOverview;
