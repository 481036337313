import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Grid } from "@mui/material";
import "./ShowFamilyMembersSite.scss";
import { useTranslation } from "react-i18next";
import { teethIcon, whiteMemberLogo } from "../../utils/common/global";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  allFamilyMembers,
  getAllfamilyMembers,
} from "../../features/user/userSlice";
import { store } from "../../app/store";
import { getAge } from "../../utils/common/getAge";
import { getUserData } from "../../features/auth/authSlice";
import CustomBackButton from "../../components/custom-bread-crumbs/CustomBackButton";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import CustomLoader from "../../components/custom-loader/CustomLoader";

const ShowFamilyMembersSite = () => {
  const { t } = useTranslation();
  const userData = useAppSelector(getUserData);
  const familyMembers = useAppSelector(getAllfamilyMembers);
  const showMemberStatus = useAppSelector(showMember);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const { familyId } = useParams();

  const familiesUnderPCId = async () => {
    if (familyId) await store.dispatch(allFamilyMembers(familyId));
  };

  useEffect(() => {
    familiesUnderPCId();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="showFamilyMembersSitePage">
        <Box className="showFamilyMembersSitePage__intro">
          {/* <CustomBackButton btnContent={t("navigation.16")} btnUrl={-1} /> */}
          {/* <Box className="userInfoContainer">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                alt=""
                src={teethIcon}
                sx={{ width: 28, height: 28 }}
                style={{
                  marginRight: "8px",
                  border: "2px solid #005994",
                }}
              />
              {userData?.first_Name && userData?.last_name && (
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: "500",
                    color: "#07120D",
                  }}
                >
                  {userData.first_Name} {userData.last_name}
                </Typography>
              )}
            </Box>
            <Typography sx={{ color: "#333333" }}>{userData.email}</Typography>
          </Box> */}
        </Box>
        <Box className="showFamilyMembersSitePage__membersContainer">
          <Box className="subHeader">
            <Typography sx={{ fontSize: "1.5rem", color: "#07120D" }}>
              {t("manager-dashboard.18")}
            </Typography>
          </Box>
          {isLoading ? (
            <CustomLoader />
          ) : (
            <Grid
              container
              spacing={3}
              sx={{ marginTop: "1rem" }}
              className="gridContainer"
            >
              {familyMembers.length > 0 ? (
                familyMembers.map((family) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className="member">
                      <Avatar
                        className="memberLogo"
                        src={whiteMemberLogo}
                        alt=""
                      />
                      <Typography className="memberName">
                        {family.first_name} {family.last_name}
                      </Typography>
                      <Typography className="age">
                        {t("user-dashboard.5")} : {getAge(family.birth_date)}
                      </Typography>
                      <Typography
                        className="details"
                        onClick={() =>
                          navigate(`/member-details/${family.user_id}`)
                        }
                      >
                        {t("user-dashboard.6")}
                      </Typography>
                    </div>
                  </Grid>
                ))
              ) : (
                <Typography
                  variant="h4"
                  width="100%"
                  fontWeight="700"
                  textAlign="center"
                  marginTop="2rem"
                >
                  {t("manager-dashboard.22")}
                </Typography>
              )}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ShowFamilyMembersSite;
