import * as React from "react";
import { Box, Typography, Button, AlertColor } from "@mui/material";
import "./FeedingContent.scss";
import { infoIcon, teethIcon } from "../../../utils/common/global";
import CustomAlert from "../../../components/custom-alert/CustomAlert";
import { useTranslation } from "react-i18next";
import {
  getGlobalLanguage,
  getSelectedAnswers,
  updateSelectedAnswers,
} from "../../../features/global/globalSlice";
import { useAppSelector } from "../../../app/hooks";
import { useWidthSize } from "../../../utils/hooks/resizeHook";
import ConfirmationModal from "../../../components/confirmation-modal/ConfirmationModal";
import CustomSupportText from "../../../components/custom-support-text/CustomSupportText";
import { useNavigate } from "react-router-dom";
import { store } from "../../../app/store";
import AudioSupport from "../../../components/audio-support/AudioSupport";

interface FeedingContentProps {
  setFeedingList?: (val: any) => void;
  questionsWithChoices?: Array<any>;
  selectedQuest: any;
  setSelectedQuest: (val: any) => void;
  showFeedingIntro: boolean;
  setShowFeedingIntro: (val: any) => void;
}

const FeedingContent = ({
  questionsWithChoices,
  setFeedingList,
  selectedQuest,
  setSelectedQuest,
  showFeedingIntro,
  setShowFeedingIntro,
}: FeedingContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const customWidth = useWidthSize();
  const [showBubbleInfo, setShowBubbleInfo] = React.useState(false);
  const [showModalInfo, setShowModalInfo] = React.useState(false);
  const [selectedChoices, setSelectedChoices] = React.useState<any>([]);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const language = useAppSelector(getGlobalLanguage);
  const selectedAnswers = useAppSelector(getSelectedAnswers);

  const selectedChoicesHandler = (id: string, selectedChoice: any) => {
    const foundedChoice = selectedChoices.find(
      (choice: any) => choice.questionID === id
    );
    if (foundedChoice) {
      const newSelectedChoices = selectedChoices.filter(
        (choice: any) => choice.questionID !== id
      );
      if (foundedChoice.choiceID !== selectedChoice.choiceID) {
        setSelectedChoices([
          ...newSelectedChoices,
          { questionID: id, ...selectedChoice },
        ]);
      } else {
        setSelectedChoices([...newSelectedChoices]);
      }
    } else {
      setSelectedChoices([
        ...selectedChoices,
        { questionID: id, ...selectedChoice },
      ]);
    }
  };

  const continueHandler = async (ev: any) => {
    ev.preventDefault();
    if (questionsWithChoices?.length === selectedChoices.length) {
      const NewSelectedChoices = selectedChoices
        .map((choice: any) => choice.choiceID)
        .sort((a: any, b: any) => a - b);
      setFeedingList?.(NewSelectedChoices);
      store.dispatch(
        updateSelectedAnswers({
          ...selectedAnswers,
          feedingAnswers: selectedChoices,
          feeding: NewSelectedChoices,
        })
      );
      navigate("/assessment?assessmentStep=2");
    } else {
      setShowMessage(true);
      setMessage(t("common.23"));
      setMessageType("error");
    }
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    selectedAnswers?.feedingAnswers &&
      setSelectedChoices(selectedAnswers.feedingAnswers);
    setFeedingList?.(selectedAnswers?.feeding ? selectedAnswers?.feeding : []);
  }, [showFeedingIntro]);

  return (
    <Box className="feedingContainer">
      <ConfirmationModal
        title=""
        open={showModalInfo}
        closeHandler={() => {
          setShowModalInfo(false);
          setSelectedQuest(null);
        }}
        showCancelButton={false}
        showSubmitButton={false}
      >
        <Box className="bubbleText">
          <CustomSupportText questionId={selectedQuest?.questionID} />
        </Box>
      </ConfirmationModal>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      {showFeedingIntro ? (
        <Box className="feedingContent">
          <AudioSupport
            audioText={`${t("feeding-intro.1")} ${t("feeding-intro.2")}`}
          />
          <Box className="feedingIntro">
            <img src={teethIcon} alt="" className="feedingteethIcon" />
            <Box className="feedingTextContainer">
              <Typography
                variant="h4"
                fontWeight="600"
                fontSize="2rem"
                color="#005994"
                className="feedingIntroTitle"
              >
                {t("feeding-intro.1")}
              </Typography>
              <Typography
                variant="h5"
                fontSize="1.75rem"
                className="feedingIntroText"
              >
                {t("feeding-intro.2")}
              </Typography>
            </Box>
          </Box>
          <Button
            className="btn"
            variant="contained"
            onClick={() => setShowFeedingIntro(false)}
          >
            {t("common.11")}
          </Button>
        </Box>
      ) : (
        <>
          <Box className="content">
            <Box className="feedingContent">
              {questionsWithChoices?.map((quest) => (
                <Box className="questionBox" key={quest.questionID}>
                  <AudioSupport
                    audioText={t(`questions.${quest.questionID}`)}
                    width="fit-content"
                  />
                  <Typography className="questionTitle">
                    {t(`questions.${quest.questionID}`)}
                  </Typography>

                  {selectedQuest?.questionID !== quest.questionID && (
                    <img
                      src={infoIcon}
                      alt=""
                      width={30}
                      height={30}
                      style={{
                        objectFit: "contain",
                        marginLeft: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (customWidth > 1100) {
                          setShowBubbleInfo(true);
                        } else {
                          setShowModalInfo(true);
                        }
                        setSelectedQuest(quest);
                      }}
                    />
                  )}

                  <Box className="choicesBtnContainer">
                    {quest?.choices.map((choice: any) => (
                      <Button
                        key={choice.choiceID}
                        className={`choiceBtn ${
                          selectedChoices.find(
                            (item: any) =>
                              item.questionID === quest.questionID &&
                              item.choiceID === choice.choiceID
                          ) && "choiceBtn--selected"
                        }`}
                        onClick={() =>
                          selectedChoicesHandler(quest.questionID, choice)
                        }
                      >
                        {t(`choices.${choice.choiceID}`)}
                      </Button>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              className="bubblesContainer"
              display={showBubbleInfo ? "block" : "none"}
            >
              {questionsWithChoices?.map((quest) => (
                <Box
                  key={quest?.questionID}
                  className="bubbleTextContainer"
                  visibility={
                    selectedQuest?.questionID === quest?.questionID
                      ? "inherit"
                      : "hidden"
                  }
                >
                  <img
                    src={infoIcon}
                    alt=""
                    width={30}
                    height={30}
                    style={{
                      objectFit: "contain",
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowBubbleInfo(false);
                      setSelectedQuest(null);
                    }}
                  />
                  <Box className="bubbleText">
                    <CustomSupportText questionId={quest?.questionID} />
                  </Box>
                  <img src={teethIcon} alt="" />
                </Box>
              ))}
            </Box>
          </Box>
          <Button
            className="btn"
            variant="contained"
            onClick={(ev) => continueHandler(ev)}
          >
            {t("common.9")}
          </Button>
        </>
      )}
    </Box>
  );
};

export default FeedingContent;
