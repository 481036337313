import React from "react";
import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { getCurrentMember } from "../../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { ArrowDropDown, ArrowDropUp, HelpRounded } from "@mui/icons-material";
import { isEmpty, includes, capitalize } from "lodash";
import FoodDrinksTableModal from "../../../components/foodDrinksTableModal/FoodDrinksTableModal";
import {
  ImagesArrayFood,
  ImagesArrayDrink,
} from "../../../utils/common/global";
import {
  getSelectedAnswers,
  updateSelectedAnswers,
} from "../../../features/global/globalSlice";
import CloseIcon from "@mui/icons-material/Close";
import { store } from "../../../app/store";

const mealTypes = [
  {
    label: " Breakfast",
    value: "breakfast",
  },
  {
    label: " Lunch",
    value: "lunch",
  },
  {
    label: " Dinner",
    value: "dinner",
  },
  {
    label: " Snack",
    value: "snack",
  },
];

const DietFoodDrinks = () => {
  const { t } = useTranslation();
  const memberData = useAppSelector(getCurrentMember);
  const navigate = useNavigate();
  const [selectedFoodOrDrinkType, setSelectedFoodOrDrinkType] =
    React.useState("food");
  const [selectedFood, setSelectedFood] = React.useState<any>({});
  const [selectedDrink, setSelectedDrink] = React.useState<any>({});
  const [showFoodDrinksModal, setShowFoodDrinksModal] = React.useState(false);
  const [drinkAnchorEl, setDrinkAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const drinkOpen = Boolean(drinkAnchorEl);
  const [foodAnchorEl, setFoodAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const selectedAnswers = useAppSelector(getSelectedAnswers);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  const foodOpen = Boolean(foodAnchorEl);
  const [selectedTime, setSelectedTime] = React.useState<any>(
    selectedAnswers?.activeTime
      ? selectedAnswers?.activeTime
      : {
          name: "6:00 am",
          label: "6 AM",
          isSelect: false,
          values: [],
        }
  );
  const [selectedMeal, setSelectedMeal] = React.useState<any>(
    mealTypes.find(
      (m: any) =>
        m.value ===
        selectedAnswers?.timeAndType?.[selectedAnswers?.activeTime?.name]
    )
  );

  const [timeArray, setTimeArray] = React.useState([
    { name: "6:00 am", label: "6 AM", isSelect: false, values: [] },
    { name: "7:00 am", label: "7 AM", isSelect: false, values: [] },
    { name: "8:00 am", label: "8 AM", isSelect: false, values: [] },
    { name: "9:00 am", label: "9 AM", isSelect: false, values: [] },
    { name: "10:00 am", label: "10 AM", isSelect: false, values: [] },
    { name: "11:00 am", label: "11 AM", isSelect: false, values: [] },
    { name: "12:00 pm", label: "12 PM", isSelect: false, values: [] },
    { name: "1:00 pm", label: "1 PM", isSelect: false, values: [] },
    { name: "2:00 pm", label: "2 PM", isSelect: false, values: [] },
    { name: "3:00 pm", label: "3 PM", isSelect: false, values: [] },
    { name: "4:00 pm", label: "4 PM", isSelect: false, values: [] },
    { name: "5:00 pm", label: "5 PM", isSelect: false, values: [] },
    { name: "6:00 pm", label: "6 PM", isSelect: false, values: [] },
    { name: "7:00 pm", label: "7 PM", isSelect: false, values: [] },
    { name: "8:00 pm", label: "8 PM", isSelect: false, values: [] },
    { name: "9:00 pm", label: "9 PM", isSelect: false, values: [] },
    { name: "10:00 pm", label: "10 PM", isSelect: false, values: [] },
    { name: "11:00 pm", label: "11 PM", isSelect: false, values: [] },
    { name: "12:00 am", label: "12 AM", isSelect: false, values: [] },
    { name: "1:00 am", label: "1 AM", isSelect: false, values: [] },
    { name: "2:00 am", label: "2 AM", isSelect: false, values: [] },
    { name: "3:00 am", label: "3 AM", isSelect: false, values: [] },
    { name: "4:00 am", label: "4 AM", isSelect: false, values: [] },
    { name: "5:00 am", label: "5 AM", isSelect: false, values: [] },
  ]);

  const getSelectedFoodDrinks = () => {
    let activeTime: any = {};
    activeTime = timeArray.find((val) => val.name === selectedTime.name);
    return activeTime?.values ? activeTime?.values : [];
  };

  const foodHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFoodAnchorEl(event.currentTarget);
  };
  const foodHandleClose = () => {
    setFoodAnchorEl(null);
  };

  const drinkHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDrinkAnchorEl(event.currentTarget);
  };
  const drinkHandleClose = () => {
    setDrinkAnchorEl(null);
  };

  const toggleImagesInTimeArray = (imgId: any, type?: string) => {
    if (!imgId) return;
    let activeTime: any = {};
    let newValues: any = [];
    activeTime = timeArray.find((val) => val.name === selectedTime.name);
    const idx = activeTime.values.indexOf(imgId);

    if (idx > -1) {
      newValues = [...activeTime.values.filter((val: any) => val !== imgId)];
    } else {
      newValues = [...activeTime.values, imgId];
    }

    let newArray: any = [];
    timeArray.forEach((val, i) => {
      if (val.name === activeTime.name) {
        newArray = [
          ...newArray,
          { name: val.name, label: val.label, values: newValues },
        ];
      } else {
        newArray = [
          ...newArray,
          { name: val.name, label: val.label, values: val.values },
        ];
      }
    });

    setTimeArray(newArray);
    if (type) {
      type === "food" ? setSelectedFood({}) : setSelectedDrink({});
    }
  };

  const getImageURL = (imageId: string) => {
    const selectImage = [...ImagesArrayFood, ...ImagesArrayDrink].find(
      (image: any) => image.imageId === imageId
    );
    return selectImage?.image ? selectImage.image : "";
  };

  const continueHandler = async (ev: any) => {
    ev.preventDefault();
    store.dispatch(
      updateSelectedAnswers({
        ...selectedAnswers,
        timeArray: [...timeArray],
      })
    );
  };

  React.useEffect(() => {
    selectedAnswers?.timeArray?.length > 0 &&
      setTimeArray(selectedAnswers.timeArray);
  }, []);

  return (
    <>
      <FoodDrinksTableModal
        open={showFoodDrinksModal}
        closeHandler={() => setShowFoodDrinksModal(false)}
        selectedFoodOrDrinkType={selectedFoodOrDrinkType}
      />
      <div className="foodAndDrinks">
        <Typography variant="h3">{`${t("new-diet.13")} ${
          memberData?.first_name ? memberData?.first_name : ""
        } ${t("new-diet.14")} ${selectedAnswers?.activeTime?.label} ${t(
          "new-diet.15"
        )} ${capitalize(
          selectedAnswers?.timeAndType?.[selectedAnswers?.activeTime?.name]
        )}`}</Typography>
        <Typography variant="h5">
          {" "}
          {`${t("new-diet.16")} ${
            memberData?.first_name ? memberData?.first_name : ""
          } ${t("new-diet.17")}`}
        </Typography>
        <Box
          display="flex"
          mt="2rem"
          gap="4rem"
          flexDirection={{
            xs: "column",
            sm: "column",
            md: "row",
          }}
        >
          <Box display="flex" gap="1rem">
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap="2rem"
            >
              <Box display="flex" alignItems="center" gap="1rem">
                <Box ml="33px" className="foodDrinkBtn foodDrinkBtn--selected">
                  {t("new-diet.30")}
                </Box>
                <HelpRounded
                  sx={{ color: "#8C004B", cursor: "pointer" }}
                  onClick={() => {
                    setSelectedFoodOrDrinkType("food");
                    setShowFoodDrinksModal(true);
                  }}
                />
              </Box>
              <Box className="language__dropdown">
                <span
                  id="basic-button"
                  aria-controls={foodOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={foodOpen ? "true" : undefined}
                  onClick={foodHandleClick}
                  className="category-menu"
                >
                  <Box
                    display="flex"
                    width="250px"
                    maxWidth="320px"
                    minHeight="42px"
                    borderRadius="10px"
                    border="2px solid #005994"
                    style={{
                      cursor: "pointer",
                    }}
                    flex="1"
                  >
                    <Typography
                      variant="subtitle1"
                      color="#787D86"
                      fontSize="1.1rem"
                      fontWeight="700"
                      flex="1"
                      textAlign="center"
                      margin="auto 0"
                    >
                      {!isEmpty(selectedFood)
                        ? t(`food-and-drinks.${selectedFood.imageId}`)
                        : "------------------"}
                    </Typography>
                    <Box
                      bgcolor="#005994"
                      borderRadius="0 6px 6px 0"
                      display="flex"
                      alignItems="center"
                    >
                      {foodOpen ? (
                        <ArrowDropUp style={{ color: "#fff" }} />
                      ) : (
                        <ArrowDropDown style={{ color: "#fff" }} />
                      )}
                    </Box>
                  </Box>
                </span>
                <Menu
                  id="basic-menu"
                  anchorEl={foodAnchorEl}
                  open={foodOpen}
                  onClose={foodHandleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    width: "250px",
                    maxHeight: "300px",
                  }}
                  className="dropdown__menu"
                >
                  {ImagesArrayFood.map((image: any) => (
                    <MenuItem
                      key={image.imageId}
                      onClick={() => {
                        setSelectedFood(image);
                        foodHandleClose();
                      }}
                      disabled={includes(
                        getSelectedFoodDrinks(),
                        image.imageId
                      )}
                    >
                      {t(`food-and-drinks.${image.imageId}`)}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box
                display={{
                  xs: "block",
                  sm: "none",
                }}
              >
                <Button
                  className="add-btn"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    toggleImagesInTimeArray(selectedFood.imageId, "food")
                  }
                  style={{ marginTop: "-16px" }}
                >
                  {t("common.5")}
                </Button>
              </Box>
              <Box className="selected-food-drinks-category">
                {!isEmpty(selectedFood) ? (
                  <img src={selectedFood.image} alt="" />
                ) : (
                  <Typography variant="body1">{t("new-diet.18")}</Typography>
                )}
              </Box>
            </Box>
            <Box
              display={{
                xs: "none",
                sm: "block",
              }}
            >
              <Button
                className="add-btn"
                variant="contained"
                color="primary"
                onClick={() =>
                  toggleImagesInTimeArray(selectedFood.imageId, "food")
                }
                style={{ marginTop: "84px" }}
              >
                {t("common.5")}
              </Button>
            </Box>
          </Box>
          <Box display="flex" gap="1rem">
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap="2rem"
            >
              <Box display="flex" alignItems="center" gap="1rem">
                <Box ml="33px" className="foodDrinkBtn foodDrinkBtn--selected">
                  {t("new-diet.31")}
                </Box>
                <HelpRounded
                  sx={{ color: "#8C004B", cursor: "pointer" }}
                  onClick={() => {
                    setSelectedFoodOrDrinkType("drink");
                    setShowFoodDrinksModal(true);
                  }}
                />
              </Box>
              <Box className="language__dropdown">
                <span
                  id="basic-button"
                  aria-controls={drinkOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={drinkOpen ? "true" : undefined}
                  onClick={drinkHandleClick}
                  className="category-menu"
                >
                  <Box
                    display="flex"
                    width="250px"
                    maxWidth="320px"
                    minHeight="42px"
                    borderRadius="10px"
                    border="2px solid #005994"
                    style={{
                      cursor: "pointer",
                    }}
                    flex="1"
                  >
                    <Typography
                      variant="subtitle1"
                      color="#787D86"
                      fontSize="1.1rem"
                      fontWeight="700"
                      flex="1"
                      textAlign="center"
                      margin="auto 0"
                    >
                      {!isEmpty(selectedDrink)
                        ? t(`food-and-drinks.${selectedDrink.imageId}`)
                        : "------------------"}
                    </Typography>
                    <Box
                      bgcolor="#005994"
                      borderRadius="0 6px 6px 0"
                      display="flex"
                      alignItems="center"
                    >
                      {drinkOpen ? (
                        <ArrowDropUp style={{ color: "#fff" }} />
                      ) : (
                        <ArrowDropDown style={{ color: "#fff" }} />
                      )}
                    </Box>
                  </Box>
                </span>
                <Menu
                  id="basic-menu"
                  anchorEl={drinkAnchorEl}
                  open={drinkOpen}
                  onClose={drinkHandleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    width: "250px",
                    maxHeight: "300px",
                  }}
                  className="dropdown__menu"
                >
                  {ImagesArrayDrink.map((image: any) => (
                    <MenuItem
                      key={image.imageId}
                      onClick={() => {
                        setSelectedDrink(image);
                        drinkHandleClose();
                      }}
                      disabled={includes(
                        getSelectedFoodDrinks(),
                        image.imageId
                      )}
                    >
                      {t(`food-and-drinks.${image.imageId}`)}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box
                display={{
                  xs: "block",
                  sm: "none",
                }}
              >
                <Button
                  className="add-btn"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    toggleImagesInTimeArray(selectedDrink.imageId, "drink")
                  }
                  style={{ marginTop: "-16px" }}
                >
                  {t("common.5")}
                </Button>
              </Box>
              <Box className="selected-food-drinks-category">
                {!isEmpty(selectedDrink) ? (
                  <img src={selectedDrink.image} alt="" />
                ) : (
                  <Typography variant="body1">{t("new-diet.18")}</Typography>
                )}
              </Box>
            </Box>

            <Box
              display={{
                xs: "none",
                sm: "block",
              }}
            >
              <Button
                className="add-btn"
                variant="contained"
                color="primary"
                onClick={() =>
                  toggleImagesInTimeArray(selectedDrink.imageId, "drink")
                }
                style={{ marginTop: "84px" }}
              >
                {t("common.5")}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box my="1.5rem" width="100%" height="5px" bgcolor="#364E9A" />

        <Typography variant="h5" mb="1.5rem">
          {" "}
          {`${memberData?.first_name ? memberData?.first_name : ""} ${t(
            "new-diet.19"
          )}`}
        </Typography>

        <Grid
          container
          className="imagesContainer"
          spacing={{ xs: 3, md: 2, lg: 0 }}
          rowGap={4}
        >
          {!isEmpty(getSelectedFoodDrinks()) ? (
            getSelectedFoodDrinks()
              .filter((i: any) => i)
              .map((imageId: string) => (
                <Grid
                  key={imageId}
                  item
                  className="gridItem"
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  margin="auto"
                >
                  <Box
                    className="selected-food-drinks-category"
                    position="relative"
                  >
                    <Box
                      width="36px"
                      height="36px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      position="absolute"
                      top="-18px"
                      right="-18px"
                      bgcolor="#364E9A"
                      borderRadius="50%"
                      sx={{ cursor: "pointer" }}
                      onClick={() => toggleImagesInTimeArray(imageId)}
                    >
                      <CloseIcon style={{ color: "#fff" }} />
                    </Box>
                    {!isEmpty(getSelectedFoodDrinks()) ? (
                      <img src={getImageURL(imageId)} alt="" />
                    ) : (
                      <Typography variant="body1">
                        {t("new-diet.18")}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              ))
          ) : (
            <Box className="selected-food-drinks-category mx-auto">
              <Typography variant="body1">{t("new-diet.18")}</Typography>
            </Box>
          )}
        </Grid>

        <Box
          width="100%"
          display="flex"
          flexDirection={{
            xs: "column",
            md: "row",
          }}
          alignItems={{
            xs: "center",
            md: "flex-end",
            lg: "flex-end",
          }}
          justifyContent={{
            md: "space-between",
          }}
          mt="3rem"
          gap="1rem"
        >
          <Button
            className="btn"
            variant="contained"
            color="primary"
            style={{
              marginTop: "1rem",
              maxWidth: "500px",
            }}
            sx={{
              marginLeft: {
                xs: "0",
                lg: "28%",
              },
              marginRight: {
                xs: "0",
                lg: "auto",
              },
            }}
            onClick={(ev) => {
              continueHandler(ev);
              navigate("/assessment?assessmentStep=0&type=timeAndType");
            }}
          >
            {`${t("new-diet.20")} ${
              memberData?.first_name ? memberData?.first_name : ""
            } ${t("new-diet.21")}`}
          </Button>

          {isCompleted ? (
            <Box
              display="flex"
              // alignItems="center"
              gap="0.5rem"
              position="relative"
            >
              <Box
                width="100%"
                height="120px"
                display="flex"
                alignItems="start"
                justifyContent="center"
                position="absolute"
                top="-25px"
                right="0"
                bgcolor="#C8D8E8"
                borderRadius="18px"
                sx={{ cursor: "pointer" }}
                // onClick={() => toggleImagesInTimeArray(imageId)}
              >
                <Typography
                  variant="body2"
                  padding="0 1rem"
                  mt="1rem"
                  color="#000000"
                >
                  {`${t("new-diet.22")} ${
                    memberData?.first_name ? memberData?.first_name : ""
                  } ${t("new-diet.33")}`}
                </Typography>
              </Box>

              <Button
                className="btn"
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "auto",
                  width: "120px",
                  minWidth: "120px",
                }}
                onClick={(ev) => {
                  continueHandler(ev);
                  navigate("/assessment?assessmentStep=0&type=summary");
                  setIsCompleted(true);
                }}
              >
                {t("common.35")}
              </Button>
              <Button
                className="secondary-btn"
                variant="contained"
                color="primary"
                style={{ width: "120px" }}
                sx={{
                  marginLeft: {
                    xs: "0",
                    lg: "auto",
                  },
                }}
                onClick={() => setIsCompleted(false)}
              >
                {t("common.36")}
              </Button>
            </Box>
          ) : (
            <Button
              className="secondary-btn"
              variant="contained"
              color="primary"
              style={{ marginTop: "1rem" }}
              sx={{
                marginLeft: {
                  xs: "0",
                  lg: "auto",
                },
              }}
              onClick={() =>
                // navigate("/assessment?assessmentStep=0&type=foodAndDrinks")
                setIsCompleted(true)
              }
            >
              {t("common.34")}
            </Button>
          )}
        </Box>
      </div>
    </>
  );
};

export default DietFoodDrinks;
