import * as React from "react";
import { Box } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import ActionPlanning from "./pages/action-planning/ActionPlanning";
import Assessment from "./pages/assessment/Assessment";
import CavityTutorial from "./pages/cavity-tutorial/CavityTutorial";
import ManagerDashboard from "./pages/dashboards/managerDashboard/ManagerDashboard";
import UserDashboard from "./pages/dashboards/userDashboard/UserDashboard";
import EditSite from "./pages/edit-site/EditSite";
import ErrorPage from "./pages/error-page/ErrorPage";
import FollowUpInfo from "./pages/followUpInfo/FollowUpInfo";
import MemberDetails from "./pages/memberDetails/MemberDetails";
import Overview from "./pages/overview/Overview";
import PcRelationInfo from "./pages/pcRelationInfo/PCrelationInfo";
import ItemResources from "./pages/resources/ItemResources";
import Resources from "./pages/resources/Resources";
import RiskAreas from "./pages/risk-areas/RiskAreas";
import SignIn from "./pages/signIn/SignIn";
import SignUp from "./pages/signUp/SignUp";
import SiteDetails from "./pages/site-details/SiteDetails";
import StartAssessment from "./pages/startAssessment/StartAssessment";
import UserFollowUp from "./pages/userFollowUp/UserFollowUp";
import Welcome from "./pages/welcome/Welcome";
import "rsuite/dist/rsuite.min.css";
import EditSiteDetails from "./pages/edit-site-details/EditSiteDetails";
import ShowFamilies from "./pages/show-families/ShowFamilies";
import ShowFamilyMembers from "./pages/show-family-members/ShowFamilyMembers";
import ShowMemberDetails from "./pages/show-member-details/ShowMemberDetails";
import ManagerAccount from "./pages/accounts/manager-account/ManagerAccount";
import ProviderAccount from "./pages/accounts/provider-account/ProviderAccount";
import EditManagerAccount from "./pages/edit-accounts/edit-manager-account/EditManagerAccount";
import EditProviderAccount from "./pages/edit-accounts/edit-provider-account/EditProviderAccount";
import UserAccount from "./pages/accounts/user-account/UserAccount";
import EditUserAccount from "./pages/edit-accounts/edit-user-account/EditUserAccount";
import CounselorAccount from "./pages/accounts/counselor-account/CounselorAccount";
import EditCounselorAccount from "./pages/edit-accounts/edit-counselor-account/EditCounselorAccount";
import ProviderDashboard from "./pages/dashboards/providerDashboard/ProviderDashboard";
import CounselorDashboard from "./pages/dashboards/counselorDashboard/CounselorDashboard";
import PrivacyPage from "./pages/privacy/PrivacyPage";
import { useAppSelector } from "./app/hooks";
import { getUserData } from "./features/auth/authSlice";
import ShowFamilyMembersPC from "./pages/show-family-members-pc/ShowFamilyMembersPC";
import ShowMemberDetailsPC from "./pages/show-member-details-pc/ShowMemberDetailsPC";
import CookieUI from "./components/cookie-ui/CookieUI";
import { useCookies } from "react-cookie";
import HomePage from "./pages/homePage/HomePage";
import { useTranslation } from "react-i18next";
import {
  getGlobalLanguage,
  getPreviousPaths,
  updatePreviousPaths,
} from "./features/global/globalSlice";
import RiskAreasPC from "./pages/risk-areas-pc/RiskAreasPC";
import RiskAreasManager from "./pages/risk-areas-manager/RiskAreasManager";
import SuccessEmailVerificationPage from "./pages/success-email-verification/SuccessEmailVerification";
import EmailVerificationPage from "./pages/email-verification/EmailVerification";
import ResetPasswordPage from "./pages/reset-password/ResetPassword";
import AutoLogout from "./components/auto-logout/AutoLogout";
import AboutUs from "./pages/about-us/AboutUs";
import FollowUpFlyer from "./pages/follow-up-flyers/FollowUpFlyers";
import ActionPlanningTutorial from "./pages/action-planning-tutorial/ActionPlanningTutorial";
import { store } from "./app/store";
import UserReport from "./pages/user-report/UserReport";
import SettingsIntro from "./pages/goal-settings/pages/SettingsIntro";
import SettingsSafeAreas from "./pages/goal-settings/pages/SettingsSafeAreas";
import SettingsRiskAreas from "./pages/goal-settings/pages/SettingsRiskAreas";
import ActionPlanningIntro from "./pages/action-planning/ActionPlanningIntro";
import AdministratorDashboard from "./pages/dashboards/administratorDashboard/AdministratorDashboard";
import NewLicense from "./pages/licenses/new-license/NewLicense";
import LicenseDetails from "./pages/licenses/license-details/LicenseDetails";
import EditLicenseDetails from "./pages/licenses/edit-license-details/EditLicenseDetails";
import ShowFamilyMembersSite from "./pages/show-family-members-site/ShowFamilyMembersSite";
import EditAdministratorAccount from "./pages/edit-accounts/edit-administrator-account/EditAdministratorAccount";
import AdministratorAccount from "./pages/accounts/administrator-account/AdministratorAccount";
import LicenseCheck from "./components/license-check/LicenseCheck";
import ExpiredLicensePage from "./pages/expired-license-page/ExpiredLicensePage";
import SettingsOverview from "./pages/goal-settings/pages/SettingsOverview";
import SettingsStartGoal from "./pages/goal-settings/pages/SettingsStartGoal";
import buildInfo from "./build-info.json";

const checkAndUpdateBuildValue = async () => {
  try {
    const storedBuildValue = localStorage.getItem("buildDateTime");
    if (buildInfo.buildDateTime !== storedBuildValue) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      localStorage.clear();
      localStorage.setItem("buildDateTime", buildInfo.buildDateTime);
      window.location.reload();
    }
  } catch (error) {
    console.error("Error fetching build info:", error);
  }
};

function App() {
  const { role_id } = useAppSelector(getUserData) || {};
  const language = useAppSelector(getGlobalLanguage);
  const previousPaths = useAppSelector(getPreviousPaths);
  const location = useLocation();
  const authToken = localStorage.getItem("access-token");
  const [cookies, setCookie] = useCookies(["access_token", "refresh_token"]);
  const { i18n } = useTranslation();

  React.useEffect(() => {
    checkAndUpdateBuildValue();
  }, []);

  React.useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  React.useEffect(() => {
    if (
      previousPaths &&
      previousPaths[previousPaths?.length - 1] !== location.pathname
    ) {
      store.dispatch(
        updatePreviousPaths([...previousPaths, location.pathname])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="App">
      <AutoLogout token={authToken}>
        <Header />
        <Box className="main">
          <Routes>
            {authToken && (
              <>
                {role_id === 1 && (
                  <>
                    <Route
                      path="/manager-dashboard"
                      element={
                        <LicenseCheck>
                          <ManagerDashboard />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/edit-site"
                      element={
                        <LicenseCheck>
                          <EditSite />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/site-details/:siteId"
                      element={
                        <LicenseCheck>
                          <SiteDetails />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/pc/:pcId"
                      element={
                        <LicenseCheck>
                          <ShowFamilies />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/family/:familyId"
                      element={
                        <LicenseCheck>
                          <ShowFamilyMembersSite />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/member-details/:memberId"
                      element={
                        <LicenseCheck>
                          <ShowMemberDetails />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/edit-site-details/:siteId"
                      element={
                        <LicenseCheck>
                          <EditSiteDetails />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/manager-account"
                      element={
                        <LicenseCheck>
                          <ManagerAccount />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/edit-manager-account"
                      element={
                        <LicenseCheck>
                          <EditManagerAccount />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/edit-user-account"
                      element={
                        <LicenseCheck>
                          <EditUserAccount />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/risk-areas/:memberId"
                      element={
                        <LicenseCheck>
                          <RiskAreasManager />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/user-report/:type/:id"
                      element={
                        <LicenseCheck>
                          <UserReport />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/license-expired"
                      element={<ExpiredLicensePage />}
                    />
                  </>
                )}
                {role_id === 2 && (
                  <>
                    <Route
                      path="/provider-dashboard"
                      element={
                        <LicenseCheck>
                          <ProviderDashboard />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/show-families/:id"
                      element={
                        <LicenseCheck>
                          <ShowFamilies />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/show-member-details/:pcType/:familyId/:memberId"
                      element={
                        <LicenseCheck>
                          <ShowMemberDetailsPC />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/provider-account"
                      element={
                        <LicenseCheck>
                          <ProviderAccount />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/show-family-members/:pcType/:familyId"
                      element={
                        <LicenseCheck>
                          <ShowFamilyMembersPC />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/edit-provider-account"
                      element={
                        <LicenseCheck>
                          <EditProviderAccount />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/risk-areas/:pcType/:familyId/:memberId"
                      element={
                        <LicenseCheck>
                          <RiskAreasPC />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/user-report/:type/:id"
                      element={
                        <LicenseCheck>
                          <UserReport />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/license-expired"
                      element={<ExpiredLicensePage />}
                    />
                  </>
                )}
                {role_id === 3 && (
                  <>
                    <Route
                      path="/counselor-dashboard"
                      element={
                        <LicenseCheck>
                          <CounselorDashboard />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/show-families/:id"
                      element={
                        <LicenseCheck>
                          <ShowFamilies />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/show-family-members/:pcType/:familyId"
                      element={
                        <LicenseCheck>
                          <ShowFamilyMembersPC />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/show-member-details/:pcType/:familyId/:memberId"
                      element={
                        <LicenseCheck>
                          <ShowMemberDetailsPC />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/counselor-account"
                      element={
                        <LicenseCheck>
                          <CounselorAccount />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/edit-counselor-account"
                      element={
                        <LicenseCheck>
                          <EditCounselorAccount />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/risk-areas/:pcType/:familyId/:memberId"
                      element={
                        <LicenseCheck>
                          <RiskAreasPC />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/user-report/:type/:id"
                      element={
                        <LicenseCheck>
                          <UserReport />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/license-expired"
                      element={<ExpiredLicensePage />}
                    />
                  </>
                )}
                {role_id === 4 && (
                  <>
                    <Route
                      path="/signup-new/welcome"
                      element={
                        <LicenseCheck>
                          <Welcome />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/signup-new/overview"
                      element={
                        <LicenseCheck>
                          <Overview />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/signup-new/pc-relation-info"
                      element={
                        <LicenseCheck>
                          <PcRelationInfo />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/signup-new/follow-up-info"
                      element={
                        <LicenseCheck>
                          <FollowUpInfo />
                        </LicenseCheck>
                      }
                    />

                    <Route
                      path="/user-dashboard"
                      element={
                        <LicenseCheck>
                          <UserDashboard />
                        </LicenseCheck>
                      }
                    />

                    <Route
                      path="/show-families/:id"
                      element={
                        <LicenseCheck>
                          <ShowFamilies />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/show-family-members/:id"
                      element={
                        <LicenseCheck>
                          <ShowFamilyMembers />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/show-member-details/:id"
                      element={
                        <LicenseCheck>
                          <ShowMemberDetails />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/user-account"
                      element={
                        <LicenseCheck>
                          <UserAccount />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/edit-user-account"
                      element={
                        <LicenseCheck>
                          <EditUserAccount />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/member-details/:memberId"
                      element={
                        <LicenseCheck>
                          <MemberDetails />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/risk-areas/:memberId"
                      element={
                        <LicenseCheck>
                          <RiskAreas />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/follow-up/:planId"
                      element={
                        <LicenseCheck>
                          <UserFollowUp />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/cavity-tutorial"
                      element={
                        <LicenseCheck>
                          <CavityTutorial />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/action-planning-tutorial"
                      element={
                        <LicenseCheck>
                          <ActionPlanningTutorial />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/start-assessment"
                      element={
                        <LicenseCheck>
                          <StartAssessment />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/assessment"
                      element={
                        <LicenseCheck>
                          <Assessment />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/goal-settings/intro"
                      element={
                        <LicenseCheck>
                          <SettingsIntro />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/goal-settings/overview"
                      element={
                        <LicenseCheck>
                          <SettingsOverview />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/goal-settings/safe-areas"
                      element={
                        <LicenseCheck>
                          <SettingsSafeAreas />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/goal-settings/risk-areas"
                      element={
                        <LicenseCheck>
                          <SettingsRiskAreas />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/goal-settings/start-goal"
                      element={
                        <LicenseCheck>
                          <SettingsStartGoal />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/action-planning/intro"
                      element={
                        <LicenseCheck>
                          <ActionPlanningIntro />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/action-planning"
                      element={
                        <LicenseCheck>
                          <ActionPlanning />
                        </LicenseCheck>
                      }
                    />
                    <Route
                      path="/license-expired"
                      element={<ExpiredLicensePage />}
                    />
                  </>
                )}
                {role_id === 5 && (
                  <>
                    <Route
                      path="/administrator-dashboard"
                      element={<AdministratorDashboard />}
                    />
                    <Route path="/new-license" element={<NewLicense />} />
                    <Route
                      path="/license-details/:licenseId"
                      element={<LicenseDetails />}
                    />
                    <Route
                      path="/edit-license-details/:licenseId"
                      element={<EditLicenseDetails />}
                    />
                    <Route
                      path="/administrator-account"
                      element={<AdministratorAccount />}
                    />
                    <Route
                      path="/edit-administrator-account"
                      element={<EditAdministratorAccount />}
                    />
                    <Route
                      path="/site-details/:siteId"
                      element={<SiteDetails />}
                    />
                    <Route path="/pc/:pcId" element={<ShowFamilies />} />
                    <Route
                      path="/family/:familyId"
                      element={<ShowFamilyMembersSite />}
                    />
                    <Route
                      path="/member-details/:memberId"
                      element={<ShowMemberDetails />}
                    />
                    <Route
                      path="/risk-areas/:memberId"
                      element={<RiskAreasManager />}
                    />
                    <Route
                      path="/user-report/:type/:id"
                      element={
                        <LicenseCheck>
                          <UserReport />
                        </LicenseCheck>
                      }
                    />
                  </>
                )}
                <Route path="/resources" element={<Resources />} />
              </>
            )}
            {!role_id && (
              <>
                <Route
                  path="/success-email-verification/:roleId/:groupId"
                  element={<SuccessEmailVerificationPage />}
                />
                <Route
                  path="/email-verification/:roleId/:groupId"
                  element={<EmailVerificationPage />}
                />
                <Route
                  path="/reset-password/:roleId/:groupId"
                  element={<ResetPasswordPage />}
                />
              </>
            )}
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />

            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/resources/:goalId/" element={<ItemResources />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/" element={<HomePage />} />
            <Route
              path="/follow-up/:lanId/:goalId/:id"
              element={<FollowUpFlyer />}
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Box>
        <Footer />
        <>
          {location.pathname === "/sign-in" &&
            cookies["access_token"] !== "msb_test" &&
            cookies["refresh_token"] !== "msb_test" && (
              <CookieUI cookies={cookies} setCookie={setCookie} />
            )}
        </>
      </AutoLogout>
    </div>
  );
}

export default App;
