import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./diet.scss";
import { useAppSelector } from "../../../app/hooks";
import { getCurrentMember } from "../../../features/user/userSlice";
import { useNavigate } from "react-router-dom";

const DietStart = () => {
  const { t } = useTranslation();
  const memberData = useAppSelector(getCurrentMember);
  const navigate = useNavigate();
  return (
    <div className="introduction">
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        gap="3rem"
        alignItems="center"
        justifyContent="center"
        flex="1"
      >
        <Typography
          variant="h3"
          fontSize="36px"
          fontWeight="400"
          color="#364E9A"
          textAlign="center"
          marginTop="2rem"
        >
          {`${t("new-diet.4")} ${
            memberData?.first_name ? memberData?.first_name : ""
          } ${t("new-diet.5")} ${
            memberData?.first_name ? memberData?.first_name : ""
          } ${t("new-diet.6")} ${
            memberData?.first_name ? memberData?.first_name : ""
          } ${t("new-diet.7")}`}
        </Typography>
        <Typography
          variant="h3"
          fontSize="36px"
          fontWeight="400"
          color="#364E9A"
          textAlign="center"
        >
          {`${t("new-diet.2")} ${
            memberData?.first_name ? memberData?.first_name : ""
          } ${t("new-diet.3")}`}
        </Typography>
      </Box>

      <Button
        className="btn"
        variant="contained"
        color="primary"
        onClick={() =>
          navigate("/assessment?assessmentStep=0&type=timeAndType")
        }
      >
        {t("common.12")}
      </Button>
    </div>
  );
};

export default DietStart;
