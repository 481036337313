import * as React from "react";

interface SwitchProps {
  test: string | number;
  defaultComponent: JSX.Element;
  children: JSX.Element[];
}
interface CaseProps {
  value: number | string;
  children: JSX.Element;
}

export const Switch: React.FC<SwitchProps> = ({
  test,
  defaultComponent,
  children,
}) => {
  const foundedComponent = children.find(
    (child) => child.props["value"] === test
  );
  return foundedComponent ? foundedComponent : defaultComponent;
};

export const Case: React.FC<CaseProps> = ({ children, value }) => {
  return <>{children}</>;
};
