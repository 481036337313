import * as React from "react";
import "./Footer.scss";

interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = (props) => {
  return (
    <div className="footer__container">
      <div className="footer__content">&copy; 2022 mySmileBuddy</div>
      <div className="footer__content">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe2lCtLogk6xv8wFa6uI0nCGTz19M6WNmh16cwJ8CQolhoTZg/viewform"
          target="_blank"
          rel="noreferrer"
        >
          Feedback
        </a>
      </div>
    </div>
  );
};

export default Footer;
