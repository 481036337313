import { Box, Typography } from '@mui/material';
import * as React from 'react';
import './StepInformation.scss';

interface StepInformationProps {
  title?: string;
  desc?: string;
  idx?: number;
}

const StepInformation: React.FC<StepInformationProps> = ({
  title,
  desc,
  idx,
}) => {
  return (
    <Box className='step-information'>
      <Typography className='title'>{`${idx}. ${title}: `}</Typography>
      <Typography className='desc'>{desc}</Typography>
    </Box>
  );
};

export default StepInformation;
