import React, { useEffect } from "react";
import { Box } from "@mui/material";
import "./HomePage.scss";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getUserData } from "../../features/auth/authSlice";
import { redirectFromHomeLink } from "../../utils/common/redirect";

const HomePage = () => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("access-token");
  const userData = useAppSelector(getUserData);

  useEffect(() => {
    if (!authToken || !userData?.role_id) navigate("/sign-in");
    else {
      navigate(redirectFromHomeLink(userData?.role_id, userData?.isMove));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box className="HomePage" />;
};

export default HomePage;
