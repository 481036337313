import * as React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./followUpContent.scss";
import { useAppSelector } from "../../app/hooks";
import {
  getCurrentMember,
  saveRiskAndGoal,
  updateMemberData,
} from "../../features/user/userSlice";
import {
  createAssessmentRecord,
  getCurrentActivePlan,
  getMember,
  getPlanByPlanId,
  saveFollowUpResult,
  updatePlanToIsPrevious,
} from "../../features/user/asyncActions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  survey2,
  survey1,
  durationLabels,
  notifications,
} from "../../utils/common/global";
import { store } from "../../app/store";
import { getUserData } from "../../features/auth/authSlice";

interface FollowUpContentProps {
  planId: string;
  currentPage: any;
  acheivedStatus?: any;
}

const FollowUpContent = ({
  planId,
  currentPage,
  acheivedStatus,
}: FollowUpContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [goalDifficultyRes, setGoalDifficultyRes] = React.useState("");
  const [response, setResponse] = React.useState("");
  const [C2Input, setC2Input] = React.useState("");
  const [C3Input, setC3Input] = React.useState("");
  const [C4Input, setC4Input] = React.useState("");
  const [elseInput, setElseInput] = React.useState("");
  const [plan, setPlan] = React.useState<any>({});
  const { notificationFlag } = useAppSelector(getUserData);

  const [showError, setShowError] = React.useState(false);
  const memberData = useAppSelector(getCurrentMember);

  const fetchCurrentActiveGoal = async () => {
    const { data } = await getCurrentActivePlan(memberData.user_id);
    setPlan(data);
    store.dispatch(
      saveRiskAndGoal({
        goalId: data.goal_id,
        goalName: data.name,
        riskAreaName: data.risk_name,
      })
    );
  };

  const fetchMemberPlan = async () => {
    const { data: plan } = await getPlanByPlanId(planId);
    const { data: memberPlanData } = await getCurrentActivePlan(plan.member_id);
    const { data } = await getMember(plan.member_id);
    setPlan(memberPlanData);
    store.dispatch(updateMemberData(data));
    store.dispatch(
      saveRiskAndGoal({
        goalId: memberPlanData.goal_id,
        goalName: memberPlanData.name,
        riskAreaName: memberPlanData.risk_name,
      })
    );
  };

  React.useEffect(() => {
    if (memberData?.user_id) {
      fetchCurrentActiveGoal();
    } else {
      fetchMemberPlan();
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGoalDifficultyRes((event.target as HTMLInputElement).value);
  };

  const saveHandler = async (ev: any) => {
    ev.preventDefault();
    const validResult: any = {};
    const result: any = {
      "1": response,
      "2": C2Input,
      "3": goalDifficultyRes !== "else" ? goalDifficultyRes : elseInput,
      "4": C3Input,
      "5": C4Input,
    };
    for (const key in result) {
      if (result[key]) validResult[key] = result[key];
    }
    await saveFollowUpResult({
      planId,
      result: validResult,
    });
    await updatePlanToIsPrevious({
      planId,
      result: response,
    });
    // TODO: Add the logic for create a records in the assessments table
    await createAssessmentRecord({
      planId,
      memberId: memberData?.user_id,
    });
    navigate(
      `/follow-up/${planId}?currentPage=${
        parseInt(currentPage) + 1
      }&acheivedStatus=${acheivedStatus}`
    );
  };

  const FirstPageContent = () => (
    <Box className="container">
      <Box className="container__content">
        <Typography className="title" variant="h3">
          {t("follow-up.2")}
        </Typography>
        <Box className="container__btnContainer">
          <Button
            variant="contained"
            className="C1__btn"
            onClick={() => {
              setResponse("yes");
              navigate(`/follow-up/${planId}?currentPage=2&acheivedStatus=yes`);
            }}
          >
            {t("follow-up.3")}
          </Button>
          <Button
            variant="contained"
            className="C1__btn"
            onClick={() => {
              setResponse("somewhat");
              navigate(
                `/follow-up/${planId}?currentPage=2&acheivedStatus=somewhat`
              );
            }}
          >
            {t("follow-up.4")}
          </Button>
          <Button
            variant="contained"
            className="C1__btn"
            onClick={() => {
              setResponse("no");
              navigate(`/follow-up/${planId}?currentPage=2&acheivedStatus=no`);
            }}
          >
            {t("follow-up.5")}
          </Button>
        </Box>
      </Box>
      <Paper className="goalSummary">
        <Typography className="title" variant="h3">
          {" "}
          {t("follow-up.6")}
        </Typography>
        <Typography className="goalTitle">
          {t(`all_goals.${plan?.goal_id}`)}
        </Typography>
        <Box className="goalSteps">
          <Box className="goalStep">
            <Box className="ruleIcon" />
            <Typography>
              {t("follow-up.7")} {plan?.who}
            </Typography>
          </Box>
          <Box className="goalStep">
            <Box className="ruleIcon" />
            <Typography>
              {t("follow-up.8")} {moment(plan?.start_date).format("DD-MM-YYYY")}
            </Typography>
          </Box>
          <Box className="goalStep">
            <Box className="ruleIcon" />
            <Typography>
              {t("follow-up.9")} {durationLabels[plan?.how_long]}
            </Typography>
          </Box>
          <Box className="goalStep">
            <Box className="ruleIcon" />
            <Typography>
              {t("follow-up.10")} {notifications[notificationFlag]}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
  const SecondPageContent = () => (
    <Box className="container">
      <Box className="container__content">
        <Typography className="title" variant="h3">
          {t("follow-up.11")}
        </Typography>
        {showError && (
          <Typography
            sx={{
              fontSize: "1.1rem",
              color: "red",
            }}
          >
            {t("common.16")}
          </Typography>
        )}
        <RadioGroup
          name="controlled-group"
          value={goalDifficultyRes}
          onChange={handleChange}
        >
          <FormControlLabel
            className="radioItem"
            value="I forgot Something"
            control={<Radio />}
            label={t("follow-up.12")}
          />
          <FormControlLabel
            className="radioItem"
            value="I didn't think it was important"
            control={<Radio />}
            label={t("follow-up.13")}
          />
          <FormControlLabel
            className="radioItem"
            value="I couldn't find time"
            control={<Radio />}
            label={t("follow-up.14")}
          />
          <FormControlLabel
            className="radioItem"
            value="Too expensive"
            control={<Radio />}
            label={t("follow-up.15")}
          />
          <Box
            display="flex"
            alignItems="flex-start"
            flexDirection={{
              xs: "column",
              sm: "column",
              md: "row",
            }}
          >
            <FormControlLabel
              className="radioItem"
              value="else"
              control={<Radio className="radio" />}
              label={t("follow-up.16")}
            />
            <textarea
              className="C2__textarea"
              placeholder={t("follow-up.17")}
              value={elseInput}
              onChange={(ev: any) => setElseInput(ev.target.value)}
              disabled={goalDifficultyRes !== "else"}
              rows={4}
            />
          </Box>
        </RadioGroup>
        <Box className="container__btnContainer">
          <Button
            variant="contained"
            className="C2__btn"
            onClick={() => {
              if (
                (goalDifficultyRes && goalDifficultyRes !== "else") ||
                (goalDifficultyRes === "else" && elseInput)
              ) {
                navigate(
                  `/follow-up/${planId}?currentPage=${
                    parseInt(currentPage) + 1
                  }&acheivedStatus=${acheivedStatus}`
                );
              } else {
                setShowError(true);
              }
            }}
          >
            {t("common.3")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "0 1rem",
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "300px",
          minHeight: "420px",
        }}
      >
        <img src={survey1} alt="" style={{ width: "300px" }} />
      </Box>
    </Box>
  );
  const YesSecondPageContent = () => (
    <Box className="container">
      <Box className="container__content">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: "0.5rem",
          }}
        >
          <Typography className="title" variant="h3">
            {t("follow-up.18")}
          </Typography>
          <Typography className="congra__title">{t("follow-up.19")}</Typography>
          <Typography className="subtitle">{t("follow-up.20")}</Typography>
        </Box>
        <textarea
          className="C3__textarea"
          value={C2Input}
          onChange={(ev: any) => setC2Input(ev.target.value)}
          rows={4}
        />
        <Box className="container__btnContainer">
          <Button variant="contained" className="C2__btn" onClick={saveHandler}>
            {t("common.14")}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          width: "fit-content",
          padding: "0 1rem",
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "300px",
          minHeight: "420px",
        }}
      >
        <img src={survey2} alt="" style={{ width: "300px" }} />
      </Box>
    </Box>
  );
  const YesThirdPageContent = () => (
    <Box className="container">
      <Box className="container__content">
        <Typography className="title" variant="h3">
          {t("follow-up.21")}
        </Typography>
        <Box className="container__btnContainer">
          <Button
            variant="contained"
            className="C2__btn"
            onClick={() => navigate(`/goal-settings/start-goal`)}
          >
            {t("follow-up.22")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "0 1rem",
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "300px",
          minHeight: "420px",
        }}
      >
        <img src={survey1} alt="" style={{ width: "300px" }} />
      </Box>
    </Box>
  );
  const ThirdPageContent = () => (
    <Box className="container">
      <Box className="container__content">
        <Typography className="title" variant="h3">
          {t("follow-up.23")}
        </Typography>
        <textarea
          className="C3__textarea"
          value={C3Input}
          onChange={(ev: any) => setC3Input(ev.target.value)}
          rows={4}
        />
        <Box className="container__btnContainer">
          <Button
            variant="contained"
            className="C2__btn"
            onClick={() =>
              navigate(
                `/follow-up/${planId}?currentPage=${
                  parseInt(currentPage) + 1
                }&acheivedStatus=${acheivedStatus}`
              )
            }
          >
            {t("common.9")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "0 1rem",
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "300px",
          minHeight: "420px",
        }}
      >
        <img src={survey1} alt="" style={{ width: "300px" }} />
      </Box>
    </Box>
  );
  const FourthPageContent = () => (
    <Box className="container">
      <Box className="container__content">
        <Typography className="title" variant="h3">
          {t("follow-up.24")}
        </Typography>
        <textarea
          className="C3__textarea"
          value={C4Input}
          onChange={(ev: any) => setC4Input(ev.target.value)}
        />
        <Box className="container__btnContainer">
          <Button
            variant="contained"
            className="C2__btn"
            onClick={(ev: any) => saveHandler(ev)}
          >
            {t("common.14")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "0 1rem",
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "300px",
          minHeight: "420px",
        }}
      >
        <img src={survey1} alt="" style={{ width: "300px" }} />
      </Box>
    </Box>
  );
  const FifthPageContent = () => (
    <Box className="container">
      <Box className="container__content">
        <Typography className="title" variant="h3">
          {t("follow-up.25")}
        </Typography>
        <Box className="container__btnContainer">
          <Button
            variant="contained"
            className="C2__btn"
            onClick={() => navigate("/action-planning/intro")}
          >
            {t("follow-up.26")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "0 1rem",
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "300px",
          minHeight: "420px",
        }}
      >
        <img src={survey1} alt="" style={{ width: "300px" }} />
      </Box>
    </Box>
  );

  switch (currentPage) {
    case "1":
      return FirstPageContent();
    case "2":
      if (acheivedStatus === "yes") {
        return YesSecondPageContent();
      } else if (acheivedStatus === "somewhat" || acheivedStatus === "no") {
        return SecondPageContent();
      }
      return null;
    case "3":
      if (acheivedStatus === "yes") {
        return YesThirdPageContent();
      } else if (acheivedStatus === "somewhat") {
        return FourthPageContent();
      } else if (acheivedStatus === "no") {
        return ThirdPageContent();
      }
      return null;
    case "4":
      if (acheivedStatus === "somewhat") {
        return FifthPageContent();
      } else if (acheivedStatus === "no") {
        return FourthPageContent();
      }
      return null;
    case "5":
      if (acheivedStatus === "no") {
        return FifthPageContent();
      }
      return null;
    default:
      return null;
  }
};

export default FollowUpContent;
