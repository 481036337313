import customAxios from "../../utils/common/useAxios";

export const authSignUp = async (body: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/auth/signUp",
    data: body,
  });
  return response;
};
export const authValidUser = async (body: any) => {
  const result = await customAxios({
    method: "POST",
    url: "/auth/validUser",
    data: body,
  });
  return result;
};

export const authForgotPassword = async ({ email }: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/auth/forgotPassword",
    data: { email },
  });
  return response;
};
export const authEmailVerified = async ({ roleId, groupId }: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/auth/EmailVerified",
    data: { roleId, groupId },
  });
  return response;
};
export const AuthResendEmailVerification = async ({ roleId, groupId }: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/auth/resendEmail",
    data: { roleId, groupId },
  });
  return response;
};

export const authResetPassword = async ({ roleId, groupId, password }: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/auth/resetPassword",
    data: { roleId, groupId, password },
  });
  return response;
};

export const checkUnmatchedPwd = async ({ groupId, newPassword }: any) => {
  const response = await customAxios({
    method: "POST",
    url: "/auth/checkUnmatchedPwd",
    data: { groupId, newPassword },
  });
  return response;
};

export const sendVerifyCode = async (
  phoneNumber: any,
  cryptedCode: any,
  lanId: any
) => {
  const response = await customAxios({
    method: "POST",
    url: `/auth/sendVerifyCode`,
    data: {
      phoneNumber,
      cryptedCode,
      lanId,
    },
  });
  return response;
};

export const authUpdatePassword = async ({
  groupId,
  oldPassword,
  newPassword,
}: any) => {
  const response = await customAxios({
    method: "POST",
    url: `/auth/updatePassword/${groupId}`,
    data: { groupId, oldPassword, newPassword },
  });
  return response;
};
