import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Grid } from "@mui/material";
import "./ShowFamilies.scss";
import { useTranslation } from "react-i18next";
import { whiteMemberLogo } from "../../utils/common/global";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "../../app/store";
import {
  fetchFamiliesUnderPcId,
  getFamiliesUnderPCId,
} from "../../features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import CustomBackButton from "../../components/custom-bread-crumbs/CustomBackButton";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import CustomLoader from "../../components/custom-loader/CustomLoader";

const ShowFamilies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { siteId, pcId, pcType } = useParams();
  const families = useAppSelector(getFamiliesUnderPCId);
  const showMemberStatus = useAppSelector(showMember);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const familiesUnderPCId = async () => {
    if (pcId) await store.dispatch(fetchFamiliesUnderPcId(pcId));
  };

  useEffect(() => {
    familiesUnderPCId();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="showFamiliesPage">
        <Box className="showFamiliesPage__intro">
          {/* <CustomBackButton
            btnContent={`${
              pcType === "Provider"
                ? t("navigation.14").toLowerCase()
                : t("navigation.15").toLowerCase()
            }`}
            btnUrl={`/site-details/${siteId}`}
          /> */}
          {/* <Box className="userInfoContainer">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                alt=""
                src={teethIcon}
                sx={{ width: 28, height: 28 }}
                style={{
                  marginRight: '8px',
                  border: '2px solid #005994',
                }}
              />
              <Typography
                sx={{ fontSize: '1.1rem', fontWeight: '500', color: '#07120D' }}
              >
                Joe Morgan
              </Typography>
            </Box>
            <Typography sx={{ color: '#333333' }}>
              {t('sign-in.14')}: Joemorgan01@gmail.com
            </Typography>
          </Box> */}
        </Box>
        <Box className="showFamiliesPage__membersContainer">
          <Box className="subHeader">
            <Typography sx={{ fontSize: "1.5rem", color: "#07120D" }}>
              {t("manager-dashboard.17")}
            </Typography>
          </Box>
          {isLoading ? (
            <CustomLoader />
          ) : (
            <Grid container spacing={3} sx={{ marginTop: "1rem" }}>
              {families.length > 0 ? (
                families.map((family: any) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                    key={family?.group_id}
                  >
                    <div className="member">
                      <Avatar
                        className="memberLogo"
                        src={whiteMemberLogo}
                        alt=""
                      />
                      <Typography className="memberName">
                        {/* {t("navigation.4")} #{group_id} */}
                        {`${family?.first_Name ?? "null"} ${
                          family?.last_name ?? "null"
                        }`}
                      </Typography>
                      <Typography
                        className="details"
                        onClick={() => navigate(`/family/${family.group_id}`)}
                      >
                        {t("user-dashboard.6")}
                      </Typography>
                    </div>
                  </Grid>
                ))
              ) : (
                <Typography
                  variant="h4"
                  width="100%"
                  fontWeight="700"
                  textAlign="center"
                  marginTop="2rem"
                >
                  {t("manager-dashboard.21")}
                </Typography>
              )}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ShowFamilies;
