import axios from 'axios';
interface AxiosParams {
  method: string;
  headers?: any;
  url: string;
  data: any;
}

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const customAxios = async (axiosParams: AxiosParams) => {
  try {
    const result = await axios.request(axiosParams);
    return result.data;
  } catch (error) {
    return { error };
  }
};

export default customAxios;
