import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import './PrivacyPage.scss';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSupport from '../../components/language-support/LanguageSupport';
import {
  showMember,
  updateShowMember,
} from '../../features/global/globalSlice';
import { store } from '../../app/store';
import { useAppSelector } from '../../app/hooks';

const PrivacyPage = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(['access_token', 'refresh_token']);
  const navigate = useNavigate();
  const showMemberStatus = useAppSelector(showMember);

  const acceptCookieHandler = () => {
    let expires = new Date();
    expires.setTime(expires.getTime() + 1000 * 3600);
    setCookie('access_token', 'msb_test', { path: '/', expires });
    setCookie('refresh_token', 'msb_test', { path: '/', expires });
    navigate('/sign-in');
  };

  React.useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <Box className="privacyPage">
        <Typography className="privacyTitle">{t('privacy.1')}</Typography>
        <Typography className="desc">{t('privacy.2')}</Typography>
        <Typography className="subTitle"> {t('privacy.3')} </Typography>
        <Typography className="italicTitle">{t('privacy.4')}</Typography>
        <Typography className="desc">{t('privacy.5')}</Typography>
        <Typography className="desc">{t('privacy.6')}</Typography>
        <Typography className="italicTitle">{t('privacy.7')}</Typography>
        <Typography className="desc">{t('privacy.8')}</Typography>
        <Typography className="desc">{t('privacy.9')}</Typography>
        <Typography className="subTitle">{t('privacy.10')}</Typography>
        <Typography className="desc">{t('privacy.11')}</Typography>
        <Typography className="subTitle">{t('privacy.12')}</Typography>
        <Typography className="desc">{t('privacy.13')}</Typography>
        <Typography className="subTitle">{t('privacy.14')}</Typography>
        <Typography className="desc">{t('privacy.15')}</Typography>
        <Typography className="subTitle">{t('privacy.16')}</Typography>
        <Typography className="desc">{t('privacy.17')}</Typography>
        <Typography className="subTitle">{t('privacy.18')}</Typography>
        <Typography className="desc">{t('privacy.19')}</Typography>
        <Typography className="subTitle">{t('privacy.20')}</Typography>
        <Typography className="desc">{t('privacy.21')}</Typography>
        <Typography className="subTitle">{t('privacy.22')}</Typography>
        <Typography className="desc">{t('privacy.23')}</Typography>
        <Typography className="desc">{t('privacy.24')}</Typography>
      </Box>
      <Button className="privacyBtn" onClick={acceptCookieHandler}>
        {t('common.20')}
      </Button>
    </>
  );
};

export default PrivacyPage;
