import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import "./CavityTutorial.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VideoEmbed from "../../components/video-embed/VideoEmbed";
import { useAppSelector } from "../../app/hooks";
import { getCurrentMember } from "../../features/user/userSlice";
import { caries_video, teethIcon } from "../../utils/common/global";
import GuidePopup from "../../components/guide-popup/GuidePopup";
import {
  getGlobalLanguage,
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import { useWidthSize } from "../../utils/hooks/resizeHook";
import ConfirmationModal from "../../components/confirmation-modal/ConfirmationModal";
import { getAge } from "../../utils/common/getAge";
import { getUserData } from "../../features/auth/authSlice";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";

const CavityTutorial = () => {
  const { t } = useTranslation();
  const customWidth = useWidthSize();

  const [showModalInfo, setShowModalInfo] = React.useState(
    customWidth > 800 ? false : true
  );
  const navigate = useNavigate();
  const language = useAppSelector(getGlobalLanguage);
  const userData = useAppSelector(getUserData);
  const memberData = useAppSelector(getCurrentMember);
  const showMemberStatus = useAppSelector(showMember);

  const urlId =
    getAge(memberData.birth_date) >= 9
      ? caries_video[2][language]
      : caries_video[1][language];

  useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="cavityTutorialPage">
        <ConfirmationModal
          title=""
          open={showModalInfo}
          closeHandler={() => {
            setShowModalInfo(false);
          }}
          showCancelButton={false}
          showSubmitButton={false}
          showPlay
          audioText={t("cavity-tutorial.1")}
        >
          <Typography className="text">{t("cavity-tutorial.1")}</Typography>
        </ConfirmationModal>
        <Box className="cavityTutorialPage__contentContainer">
          {userData?.isMove === 0 && (
            <GuidePopup
              text={
                <Typography
                  fontSize="1.2rem"
                  fontWeight="inherit"
                  marginBottom="1rem"
                >
                  {t("cavity-tutorial.1")}
                </Typography>
              }
              top="340px"
              left="180px"
              width="534px"
              activeGuide={customWidth > 800 ? true : false}
              audioText={t("cavity-tutorial.1")}
            />
          )}

          <Box className="subMain">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <VideoEmbed
                  className="video"
                  embedId={urlId}
                  width="568px"
                  height="362px"
                  border="3px solid #9EA6B"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className="contentContainer">
                  <Typography className="title" variant="h3">
                    {t("tooth-tutorial.1")}
                  </Typography>
                  <Typography className="user">
                    {t("tooth-tutorial.2")}
                  </Typography>
                  <Box className="content">
                    <Typography className="contentText">
                      {t("tooth-tutorial.3")}
                    </Typography>
                    <img src={teethIcon} alt="" className="teethIcon" />
                  </Box>
                  <Button
                    className="btn"
                    onClick={() => navigate("/start-assessment")}
                  >
                    {t("common.3")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CavityTutorial;
