import * as React from 'react';
import {
  Modal,
  Box,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import foodDrinksExample from '../../utils/foodDrinksExample.json';
import { teethIcon } from '../../utils/common/global';
import './FoodDrinksTableModal.scss';
import { useAppSelector } from '../../app/hooks';
import { getGlobalLanguage } from '../../features/global/globalSlice';
import { useTranslation } from 'react-i18next';

interface FoodDrinksTableProps {
  open: boolean;
  closeHandler: () => void;
  selectedFoodOrDrinkType?: string;
}

interface FoodDrinksSample {
  food_en?: any;
  drinks_en?: any;
  food_es?: any;
  drinks_es?: any;
}

const FoodDrinksTableModal: React.FC<FoodDrinksTableProps> = ({
  open,
  closeHandler,
  selectedFoodOrDrinkType,
}) => {
  const { t } = useTranslation();
  const language = useAppSelector(getGlobalLanguage);
  const foodSelected = `food_${language}`;
  const drinksSelected = `drinks_${language}`;
  const foodDrinksSimple = foodDrinksExample as FoodDrinksSample;
  const foodRows = foodDrinksSimple[foodSelected as keyof FoodDrinksSample];
  const drinksRows = foodDrinksSimple[drinksSelected as keyof FoodDrinksSample];

  const selectedRows =
    selectedFoodOrDrinkType === 'food' ? foodRows : drinksRows;

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="foodDrinksModalContainer">
        <Box className="title" id="modal-modal-title">
          <img src={teethIcon} alt="" className="img" />
          <Typography className="titleText">
            {t('diet-assessment.7')}
          </Typography>
        </Box>
        <TableContainer component={Paper} sx={{ maxWidth: 800 }}>
          <Table
            sx={{ maxWidth: 800 }}
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {selectedFoodOrDrinkType === 'food'
                    ? t('food-drinks-table.1')
                    : t('food-drinks-table.2')}
                </TableCell>
                <TableCell align="left">
                  {selectedFoodOrDrinkType === 'food'
                    ? t('food-drinks-table.3')
                    : t('food-drinks-table.4')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedRows?.map((row: any) => (
                <TableRow
                  key={row['Name']}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row['Name']}
                  </TableCell>
                  <TableCell align="left">{row['Sample Name']}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" className="btn" onClick={closeHandler}>
          {t('common.10')}
        </Button>
      </Box>
    </Modal>
  );
};

export default FoodDrinksTableModal;
