// LicenseCheck.tsx
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useAppSelector } from "../../app/hooks";
import {
  getLicenseExpiredDate,
  getUserData,
} from "../../features/auth/authSlice";
import { decryptedCodeGenerator } from "../../utils/common/customModular";

interface LicenseCheckProps {
  children?: ReactNode;
}

const LicenseCheck: React.FC<LicenseCheckProps> = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const cryptedLicenseExpiredDate = useAppSelector(getLicenseExpiredDate);

  useEffect(() => {
    const isLicenseExpired = checkLicenseExpiration();

    if (isLicenseExpired && userData?.role_id !== 5) {
      navigate("/license-expired");
    } else {
      setIsLoading(false);
    }
  }, []);

  const checkLicenseExpiration = () => {
    const currentDate = moment();
    const expirationDate = moment(
      decryptedCodeGenerator(cryptedLicenseExpiredDate),
      "MM-DD-YYYY"
    );
    return currentDate.isAfter(expirationDate);
  };

  return !isLoading ? <>{children}</> : null;
};

export default LicenseCheck;
