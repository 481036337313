import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import "./CustomDialog.scss";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import { Close } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";

interface CustomModalProps {
  open: boolean;
  closeHandler: () => void;
  saveHandler?: (data?: any) => void;
  title?: string;
  data?: any;
  type?: string;
}

const CustomDialog: React.FunctionComponent<CustomModalProps> = ({
  open,
  closeHandler,
  saveHandler,
  title,
  data,
  type = "add",
}) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [birthDate, setBirthDate] = React.useState<Moment | null>(null);

  React.useEffect(() => {
    setFirstName(data?.firstName ?? "");
    setLastName(data?.lastName ?? "");
    setBirthDate(data?.birthDate ? moment(data?.birthDate) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog className="dialog" open={open} onClose={closeHandler} fullWidth>
        <DialogTitle className="dialogTitle">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {title}
            <Close
              sx={{ fontSize: "1.5rem", cursor: "pointer" }}
              onClick={closeHandler}
            />
          </Box>
        </DialogTitle>

        <DialogContent className="dialogContent">
          <Box
            className="formContainer"
            component="form"
            noValidate
            autoComplete="off"
          >
            <Box className="formControl">
              <Typography width="100%" maxWidth="120px" color="#005994">
                {t("user-dashboard.8")}
                <span className="text-danger"> *</span>
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                type="text"
                name="firstname"
                placeholder={t("user-dashboard.8")}
                className="input"
                value={firstName}
                onChange={(ev) => setFirstName(ev.target.value)}
                error={!firstName ? true : false}
              />
            </Box>
            <Box className="formControl">
              <Typography width="100%" maxWidth="120px" color="#005994">
                {t("user-dashboard.9")}
                <span className="text-danger"> *</span>
              </Typography>
              <TextField
                type="text"
                name="lastname"
                variant="outlined"
                size="small"
                placeholder={t("user-dashboard.9")}
                className="input"
                value={lastName}
                onChange={(ev) => setLastName(ev.target.value)}
                error={!lastName ? true : false}
              />
            </Box>
            <Box className="formControl">
              <Typography width="100%" maxWidth="120px" color="#005994">
                {t("user-dashboard.10")}
                <span className="text-danger"> *</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  name="date"
                  //  placeholder={t("user-dashboard.10")}
                  value={birthDate}
                  onChange={(value) => {
                    setBirthDate(value);
                  }}
                  disabled={type === "edit"}
                  className="input"
                  disableFuture
                  shouldDisableDate={(day) => {
                    return !day;
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button
            className="cancelBtn"
            onClick={closeHandler}
            variant="outlined"
          >
            {t("common.2")}
          </Button>
          <Button
            className="saveBtn"
            onClick={(ev) =>
              saveHandler?.({
                first_name: firstName,
                last_name: lastName,
                birth_date: moment(birthDate).format("YYYY-MM-DD"),
                tracking_status: 0,
              })
            }
            variant="contained"
            disabled={!firstName || !lastName || !birthDate}
          >
            {type === "add" ? t("common.5") : t("common.6")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomDialog;
