import * as React from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';
import './CustomAlert.scss';

interface CustomAlertProps {
  type: AlertColor;
  open: boolean;
  message: any;
  closeHandler: () => void;
  hideDuration?: number;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  type,
  open,
  closeHandler,
  message,
  hideDuration = 3000,
}) => {
  return (
    <Snackbar
      open={open}
      onClose={closeHandler}
      autoHideDuration={hideDuration}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        severity={type}
        variant="filled"
        sx={{
          minWidth: '400px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0.5rem 2rem',
          fontSize: '1rem',
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
