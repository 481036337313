import React, { useEffect } from "react";
import { Box, Typography, Button, Grid, Avatar } from "@mui/material";
import "./AdministratorDashboard.scss";
import { useTranslation } from "react-i18next";
import { AddRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import CustomAlert from "../../../components/custom-alert/CustomAlert";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import { fetchLicenses } from "../../../features/administrator/asyncAction";
import moment from "moment";
import { LicenseIcon } from "../../../utils/common/global";

const AdministratorDashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const userData = useAppSelector(getUserData);
  const [licenses, setLicenses] = React.useState<any>([]);
  const showMemberStatus = useAppSelector(showMember);

  const getLicenses = async () => {
    const { data } = await fetchLicenses();
    setLicenses(data);
  };

  useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    getLicenses();
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <Box className="administratorDashboardPage">
        <Box className="administratorDashboardPage__membersContainer">
          <Box className="subHeader">
            <Typography
              marginRight="auto"
              variant="h5"
              fontWeight="700"
              color="#005994"
            >
              {t("administrator-dashboard.1")}
            </Typography>
            <Box className="btnContainer">
              <Button
                variant="contained"
                className="btn containedBtn"
                onClick={() => navigate("/new-license")}
              >
                {t("administrator-dashboard.2")}
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={3}
            sx={{ marginTop: "1rem" }}
            className="gridContainer"
          >
            {/* license list */}
            {licenses.map((license: any) => (
              <Grid
                item
                key={license.license_id}
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
              >
                <Box className="license">
                  <Avatar className="license-logo" src={LicenseIcon} alt="" />
                  <Typography className="license__title">
                    <strong>License: </strong>
                    {license.license_code}
                  </Typography>
                  <Typography className="license__title" marginBottom="1rem">
                    <strong>Manager: </strong>
                    {license.first_Name}{" "}
                    {license.last_name?.slice(0, 1)?.toUpperCase()}
                  </Typography>
                  <Typography className="license__desc">
                    <strong>Expired Date: </strong>{" "}
                    {moment(license.start_date)
                      .add(license.duration, "months")
                      .format("MM/DD/YYYY")}
                  </Typography>
                  <Typography className="license__desc">
                    <strong>Number Purchased: </strong> {license.total_users}
                  </Typography>
                  <Typography
                    className="details"
                    onClick={() =>
                      navigate(`/license-details/${license.license_id}`)
                    }
                  >
                    see details
                  </Typography>
                </Box>
              </Grid>
            ))}
            <Grid item key={"add-new"} xs={12} sm={6} md={3} lg={3} xl={3}>
              <Box className="addNew" onClick={() => navigate("/new-license")}>
                <AddRounded sx={{ fontSize: "1.5rem" }} className="addIcon" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AdministratorDashboard;
