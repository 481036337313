import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Input,
  RadioGroup,
  Radio,
  FormControlLabel,
  AlertColor,
  Grid,
  TextField,
} from "@mui/material";
import "./EditUserAccount.scss";
import { useTranslation } from "react-i18next";
import CustomStepper from "../../../components/customStepper/CustomStepper";
import { Link, useNavigate } from "react-router-dom";
import { SelectPicker } from "rsuite";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector } from "../../../app/hooks";
import { getUserData, loadUserData } from "../../../features/auth/authSlice";
import CustomAlert from "../../../components/custom-alert/CustomAlert";
import { updateFamilyUserInfo } from "../../../features/manager/asyncActions";
import { checkEmpty } from "../../../utils/common/checkEmpty";
import { store } from "../../../app/store";
import {
  addMultiplePcsByFamily,
  fetchPcsByFamilyId,
} from "../../../features/user/asyncActions";
import {
  cryptedCodeGenerator,
  formatPhoneNumber,
  normalizePhoneNumber,
} from "../../../utils/common/customModular";
import {
  authUpdatePassword,
  sendVerifyCode,
} from "../../../features/auth/asyncAction";
import ConfirmationModal from "../../../components/confirmation-modal/ConfirmationModal";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import { validPassword } from "../../../utils/common/checkValidator";
import PasswordValidation from "../../../components/password-validation/PasswordValidation";
import {
  fetchAllCounselors,
  fetchAllProviders,
} from "../../../features/PC/asyncActions";

const EditUserAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const [allProviders, setAllProviders] = useState([]);
  const [allCounselors, setAllCounselors] = useState([]);
  const [passowrd, setPassowrd] = useState("***********");
  const [zipCode, setZipCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [previousPhoneNumber, setPreviousPhoneNumber] = useState("");
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const [selectedComMethod, setSelectedComMethod] = useState<any>("");
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [userProviders, setUserProviders] = useState<any>([]);
  const [userCounselors, setUserCounselors] = useState<any>([]);
  const [ShowVerifiedNumberModal, setShowVerifiedNumberModal] =
    React.useState(false);
  const [verifiedCode, setVerifiedCode] = React.useState<any>("");
  const [inputVerifiedCode, setInputVerifiedCode] = React.useState<any>("");
  const [dataToSend, setDataToSend] = React.useState<any>({});
  const showMemberStatus = useAppSelector(showMember);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const generateVerifiedCode = () => {
    const code = Math.random().toString().substring(2, 8);
    setVerifiedCode(code);
  };

  React.useEffect(() => {
    generateVerifiedCode();
  }, []);

  const checkValidPhoneNumber = async (ev: any) => {
    ev.preventDefault();
    if (phoneNumber.length === 10) {
      await sendVerifyCodeHandler(ev);
    } else {
      setShowMessage(true);
      setMessageType("error");
      setMessage(t("follow-up-info.10"));
    }
  };

  const sendVerifyCodeHandler = async (ev: any) => {
    ev.preventDefault();
    const cryptedCode = cryptedCodeGenerator(verifiedCode);
    const { message, status } = await sendVerifyCode(
      `+1${phoneNumber}`,
      cryptedCode,
      userData.lan_id
    );
    setShowMessage(true);
    setMessageType(status);
    setMessage(message);
    if (status === "success") {
      setShowVerifiedNumberModal(true);
    }
  };

  const verifyCodeHandler = async (ev: any, data: any) => {
    ev.preventDefault();
    if (inputVerifiedCode?.length === 6) {
      if (inputVerifiedCode === verifiedCode) {
        await saveHandler(ev, data);
      } else {
        setShowMessage(true);
        setMessageType("error");
        setMessage(t("follow-up-info.9"));
      }
    } else {
      setShowMessage(true);
      setMessageType("error");
      setMessage(t("follow-up-info.8"));
    }
  };

  const PreferredMethod = [
    t("user-account.18"),
    t("user-account.19"),
    t("user-account.20"),
  ];

  const getAllProviders = async () => {
    const { data } = await fetchAllProviders(userData?.authorized_code);
    setAllProviders(data);
  };
  const getAllCounselors = async () => {
    const { data } = await fetchAllCounselors(userData?.authorized_code);
    setAllCounselors(data);
  };
  const fetchUserProviders = async () => {
    const { data } = await fetchPcsByFamilyId({
      fromId: userData?.group_id,
      roleId: "2",
    });

    setUserProviders(data.map((item: any) => item?.group_id));
  };

  const fetchUserCounselors = async () => {
    const { data } = await fetchPcsByFamilyId({
      fromId: userData?.group_id,
      roleId: "3",
    });
    setUserCounselors(data.map((item: any) => item?.group_id));
  };

  const saveHandler = async (ev: any, data: any) => {
    await updateFamilyUserInfo({ groupId: userData.group_id, ...data });
    await saveMultiplePcsHandler(ev);
    await store.dispatch(loadUserData(userData.group_id));
    navigate("/user-account");
  };

  const submithandler = async (ev: any) => {
    ev.preventDefault();
    const data = {
      firstName,
      lastName,
      phoneNumber: phoneNumber ? formatPhoneNumber(phoneNumber) : "",
      zipCode,
      languageId: preferredLanguage,
      notificationFlag: selectedComMethod,
    };
    setDataToSend(data);
    const state = checkEmpty(data);
    if (state) {
      if (selectedComMethod === 2) {
        await saveHandler(ev, data);
      } else {
        if (phoneNumber && phoneNumber === previousPhoneNumber) {
          await saveHandler(ev, data);
        } else {
          await checkValidPhoneNumber(ev);
        }
      }
    } else {
      setShowMessage(true);
      setMessage(t("common.16"));
      setMessageType("error");
    }
  };

  const closeUpdatePasswordModal = () => {
    setShowChangePassword(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const saveMultiplePcsHandler = async (ev: any) => {
    const filterdProviders = userProviders.filter((i: any) => i);
    const filterdCounselors = userCounselors.filter((i: any) => i);
    await addMultiplePcsByFamily({
      from_id: userData?.group_id,
      to_id: [...filterdProviders, ...filterdCounselors],
    });
  };

  const addPcHandler = (type: string) => {
    if (type === "provider" && userProviders?.length < allProviders?.length) {
      setUserProviders([...userProviders, ""]);
    } else if (
      type === "counselor" &&
      userCounselors?.length < allCounselors?.length
    ) {
      setUserCounselors([...userCounselors, ""]);
    }
  };

  const selectedPCHandler = (val: any, idx: number, type: string) => {
    const list = type === "provider" ? [...userProviders] : [...userCounselors];
    const newArr = [...list];
    newArr[idx] = val;
    type === "provider" ? setUserProviders(newArr) : setUserCounselors(newArr);
  };

  const cleanHandler = (idx: any, type: string) => {
    if (type === "provider") {
      const updatedProviders = userProviders.map(
        (provider: any, index: number) => (index !== idx ? provider : "")
      );
      setUserProviders(updatedProviders);
    } else if (type === "counselor") {
      const updatedCounselors = userCounselors.map(
        (counselor: any, index: number) => (index !== idx ? counselor : "")
      );
      setUserCounselors(updatedCounselors);
    }
  };

  const deletePcHandler = (index: number, type: string) => {
    if (type === "provider") {
      const updatedProviders = userProviders.filter(
        (_: any, idx: number) => idx !== index
      );
      setUserProviders(updatedProviders);
    } else if (type === "counselor") {
      const updatedCounselors = userCounselors.filter(
        (_: any, idx: number) => idx !== index
      );
      setUserCounselors(updatedCounselors);
    }
  };

  const updatePasswordHandler = async (ev: any) => {
    ev.preventDefault();
    if (newPassword === confirmPassword) {
      const resp = await authUpdatePassword({
        groupId: userData.group_id,
        newPassword,
        oldPassword,
      });
      setShowMessage(true);
      setMessageType(resp.status);
      setMessage(resp.message);
      resp?.status === "success" && closeUpdatePasswordModal();
    } else {
      setShowMessage(true);
      setMessage("Password didn't match");
      setMessageType("error");
    }
  };

  useEffect(() => {
    fetchUserProviders();
    fetchUserCounselors();
    getAllProviders();
    getAllCounselors();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  useEffect(() => {
    setFirstName(userData?.first_Name ?? "");
    setlastName(userData?.last_name ?? "");
    setPhoneNumber(normalizePhoneNumber(userData?.phone_number ?? ""));
    setPreviousPhoneNumber(normalizePhoneNumber(userData?.phone_number ?? ""));
    setZipCode(userData?.zip_code ?? "");
    setPreferredLanguage(userData?.lan_id ?? "");
    setSelectedComMethod(userData?.notificationFlag ?? "");
  }, [userData]);

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <ConfirmationModal
        open={showChangePassword}
        title={"Change password "}
        closeHandler={closeUpdatePasswordModal}
        submitParams={{
          btnContent: "Save changes",
          submitHandler: updatePasswordHandler,
          disabledRules:
            validPassword(newPassword) &&
            oldPassword &&
            newPassword &&
            confirmPassword
              ? false
              : true,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="1.5rem"
        >
          <Box width="100%">
            <TextField
              label="Old Password"
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="standard"
            />
            <TextField
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="standard"
            />
            {newPassword && !validPassword(newPassword) && (
              <PasswordValidation password={newPassword} />
            )}
            <TextField
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="standard"
            />
          </Box>
        </Box>
      </ConfirmationModal>
      <ConfirmationModal
        open={ShowVerifiedNumberModal}
        title={t("follow-up-info.6")}
        closeHandler={() => setShowVerifiedNumberModal(false)}
        showCancelButton={false}
        showSubmitButton={false}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="1.5rem"
          marginTop="2rem"
        >
          <input
            placeholder={t("follow-up-info.7")}
            className="verify-code-input"
            type="number"
            maxLength={6}
            value={inputVerifiedCode}
            onChange={(ev: any) => {
              ev.target.value.length <= 6 &&
                setInputVerifiedCode(ev.target.value);
            }}
          />
          <Button
            variant="contained"
            className="btn"
            sx={{ width: "270px", alignSelf: "center" }}
            onClick={(ev) => verifyCodeHandler(ev, dataToSend)}
          >
            {t("common.25")}
          </Button>
        </Box>
      </ConfirmationModal>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="editUserAccountPage">
        <Box className="editUserAccountPage__contentContainer">
          <Box
            display="flex"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexDirection={{ xs: "column", sm: "row" }}
            columnGap="6rem"
            rowGap="1rem"
            borderBottom="1px solid #e0e0e0"
          >
            {userData?.first_Name && userData?.last_name && (
              <Typography fontSize="1.5rem" color="#07120D">
                {userData.first_Name} {userData.last_name}
              </Typography>
            )}
            <Typography fontSize="1.5rem" color="#07120D">
              ID : {userData?.group_id}
            </Typography>
          </Box>
          <Typography className="subTitle__info">
            {t("user-account.2")}
          </Typography>
          <Box className="subMain">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography
                    className={`title ${
                      !firstName && messageType === "error" ? "text-danger" : ""
                    }`}
                  >
                    {t("user-account.3")}
                    <span className="text-danger"> *</span>
                  </Typography>
                  <Input
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      opacity: "0.7",
                    }}
                    required
                    error={!firstName && messageType === "error" ? true : false}
                    id="First Name"
                    placeholder={t("user-account.3")}
                    type="text"
                    value={firstName}
                    onChange={(ev) => setFirstName(ev.target.value)}
                    inputProps={{ "aria-label": "description" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography
                    className={`title ${
                      !lastName && messageType === "error" ? "text-danger" : ""
                    }`}
                  >
                    {t("user-account.4")}
                    <span className="text-danger"> *</span>
                  </Typography>
                  <Input
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      opacity: "0.7",
                    }}
                    id="Last Name"
                    required
                    error={!lastName && messageType === "error" ? true : false}
                    placeholder={t("user-account.4")}
                    type="text"
                    value={lastName}
                    onChange={(ev) => setlastName(ev.target.value)}
                    inputProps={{ "aria-label": "description" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography
                    className={`title ${
                      (selectedComMethod === 1 || selectedComMethod === 3) &&
                      !phoneNumber &&
                      messageType === "error"
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {t("user-account.5")}
                    {(selectedComMethod === 1 || selectedComMethod === 3) && (
                      <span className="text-danger"> *</span>
                    )}
                  </Typography>
                  <Input
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      opacity: "0.7",
                    }}
                    id="Phone Number"
                    required
                    error={
                      (selectedComMethod === 1 || selectedComMethod === 3) &&
                      !phoneNumber &&
                      messageType === "error"
                        ? true
                        : false
                    }
                    placeholder={t("user-account.5")}
                    type="phone"
                    value={phoneNumber}
                    onChange={(ev) => setPhoneNumber(ev.target.value)}
                    inputProps={{ "aria-label": "description" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.6")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      opacity: "0.7",
                    }}
                  >
                    {userData?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.7")}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap={2}
                  >
                    <Input
                      sx={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        opacity: "0.7",
                      }}
                      id="password"
                      placeholder={t("user-account.7")}
                      type="password"
                      value={passowrd}
                      onChange={(ev) => setPassowrd(ev.target.value)}
                      inputProps={{ "aria-label": "description" }}
                      disabled={true}
                    />
                    <Button
                      variant="contained"
                      color="info"
                      size="small"
                      sx={{ borderRadius: "6px", textTransform: "none" }}
                      onClick={() => setShowChangePassword(true)}
                    >
                      Change password
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography
                    className={`title ${
                      !zipCode && messageType === "error" ? "text-danger" : ""
                    }`}
                  >
                    {t("user-account.8")}
                    <span className="text-danger"> *</span>
                  </Typography>
                  <Input
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      opacity: "0.7",
                    }}
                    id="zipcode"
                    required
                    error={!zipCode && messageType === "error" ? true : false}
                    placeholder={t("user-account.8")}
                    type="text"
                    value={zipCode}
                    onChange={(ev) => setZipCode(ev.target.value)}
                    inputProps={{ "aria-label": "description" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.10")}
                    <span className="text-danger"> *</span>
                  </Typography>
                  <SelectPicker
                    className="select"
                    data={PreferredMethod.map((method: any, idx: number) => ({
                      label: method,
                      value: idx + 1,
                    }))}
                    value={selectedComMethod}
                    onChange={(val) => setSelectedComMethod(val)}
                    style={{ width: 300, marginTop: " 3px" }}
                    menuStyle={{ zIndex: "1000000" }}
                    searchable={false}
                    menuMaxHeight={140}
                    cleanable={false}
                  />
                  {(selectedComMethod === 1 || selectedComMethod === 3) && (
                    <Typography fontSize="14px" marginTop="10px">
                      {t("user-account.22")}
                      <Link to="/privacy">{t("user-account.23")}</Link>
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={8}>
                <Box>
                  <Typography className="title">
                    {t("user-account.9")}
                  </Typography>
                  <RadioGroup
                    row
                    value={preferredLanguage}
                    onChange={(ev) => setPreferredLanguage(ev.target.value)}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label={t("common.30")}
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={t("common.31")}
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label={t("common.32")}
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label={t("common.33")}
                    />
                  </RadioGroup>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {userData?.authorized_code ? (
            <Typography className="subTitle__info">
              <span style={{ color: "#07120D", fontWeight: "normal" }}>{`${t(
                "user-account.21"
              )} : `}</span>
              {`${userData?.authorized_code}`}
            </Typography>
          ) : null}

          <Typography className="subTitle__info">
            {t("user-account.11")}
          </Typography>
          <Box className="subMain">
            {userProviders.map((providerId: any, idx: number) => (
              <Box
                key={`provider-${providerId}`}
                display="flex"
                marginBottom="1rem"
                alignItems={{
                  xs: "start",
                  sm: "center",
                  md: "center",
                }}
                flexDirection={{
                  xs: "column",
                  sm: "row",
                }}
              >
                <Typography className="title" sx={{ minWidth: "200px" }}>
                  {t("user-account.12")} {idx + 1}
                </Typography>
                <Box display="flex" alignItems="center" width="100%">
                  <SelectPicker
                    className="select"
                    data={allProviders.map((pc: any) => ({
                      label: `${pc?.first_Name} ${pc?.last_name}`,
                      value: pc?.group_id,
                    }))}
                    style={{ width: 300, marginTop: " 3px" }}
                    menuStyle={{ zIndex: "1000000" }}
                    searchable={false}
                    menuMaxHeight={140}
                    value={providerId}
                    disabledItemValues={userProviders.map((i: any) => i)}
                    onChange={(val) => selectedPCHandler(val, idx, "provider")}
                    onClean={() => cleanHandler(idx, "provider")}
                    placeholder={t("common.17")}
                  />
                  <DeleteOutlineIcon
                    onClick={() => deletePcHandler(idx, "provider")}
                    sx={{ marginLeft: "1rem", cursor: "pointer" }}
                  />
                </Box>
              </Box>
            ))}
            {allProviders?.length > 0}
            {userProviders?.length < allProviders?.length && (
              <Box
                display="flex"
                marginBottom="0.5rem"
                alignItems="center"
                marginLeft={{
                  xs: "0",
                  sm: "7rem",
                }}
              >
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                    border: "2px dashed #005994",
                    marginRight: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => addPcHandler("provider")}
                >
                  <AddIcon />
                </Box>
                <Typography className="title" sx={{ minWidth: "200px" }}>
                  {t("user-account.16")}
                </Typography>
              </Box>
            )}
            {userCounselors.map((counselorId: any, idx: number) => (
              <Box
                key={`counselor-${counselorId}`}
                display="flex"
                marginBottom="1rem"
                alignItems={{
                  xs: "start",
                  sm: "center",
                  md: "center",
                }}
                flexDirection={{
                  xs: "column",
                  sm: "row",
                }}
              >
                <Typography className="title" minWidth="200px">
                  {t("user-account.14")} {idx + 1}:
                </Typography>
                <Box display="flex" alignItems="center" width="100%">
                  <SelectPicker
                    className="select"
                    data={allCounselors.map((counselor: any) => ({
                      label: `${counselor?.first_Name} ${counselor?.last_name}`,
                      value: counselor.group_id,
                    }))}
                    style={{ width: 300, marginTop: " 3px" }}
                    menuStyle={{ zIndex: "1000000" }}
                    searchable={false}
                    menuMaxHeight={140}
                    value={counselorId}
                    disabledItemValues={userCounselors.map((i: any) => i)}
                    onChange={(val) => selectedPCHandler(val, idx, "counselor")}
                    onClean={() => cleanHandler(counselorId, "counselor")}
                    placeholder={t("common.17")}
                  />
                  <DeleteOutlineIcon
                    sx={{ marginLeft: "1rem", cursor: "pointer" }}
                    onClick={() => deletePcHandler(idx, "counselor")}
                  />
                </Box>
              </Box>
            ))}
            {userCounselors?.length < allCounselors?.length && (
              <Box
                display="flex"
                marginBottom="0.5rem"
                alignItems="center"
                marginLeft={{
                  xs: "0",
                  sm: "7rem",
                }}
              >
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                    border: "2px dashed #005994",
                    marginRight: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => addPcHandler("counselor")}
                >
                  <AddIcon />
                </Box>
                <Typography className="title" sx={{ minWidth: "200px" }}>
                  {t("user-account.17")}
                </Typography>
              </Box>
            )}
          </Box>
          <Button className="btn" onClick={submithandler} variant="contained">
            {t("common.19")}
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={0} />
      </Box>
    </>
  );
};

export default EditUserAccount;
