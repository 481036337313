import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Grid, Button } from "@mui/material";
import "./ProviderDashboard.scss";
import { useTranslation } from "react-i18next";
import { whiteMemberLogo } from "../../../utils/common/global";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getUserData } from "../../../features/auth/authSlice";
import {
  fetchFamiliesUnderPcId,
  getFamiliesUnderPCId,
} from "../../../features/user/userSlice";
import { store } from "../../../app/store";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import { checkSiteUniqueCode } from "../../../features/manager/asyncActions";

const ProviderDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const families = useAppSelector(getFamiliesUnderPCId);
  const showMemberStatus = useAppSelector(showMember);
  const [siteName, setSiteName] = useState(null);

  const familiesUnderPCId = async () => {
    if (userData?.group_id)
      await store.dispatch(fetchFamiliesUnderPcId(userData?.group_id));
  };

  useEffect(() => {
    familiesUnderPCId();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  const getSiteName = async () => {
    const { data, isExists } = await checkSiteUniqueCode({
      siteCode: userData.authorized_code,
    });
    setSiteName(isExists ? data.description : null);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    getSiteName();
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <Box className="providerDashboardPage">
        <Box className="providerDashboardPage__membersContainer">
          <Box className="subHeader">
            <Box display="flex" flexDirection="column">
              <Typography fontSize="2rem" color="#005994" fontWeight="700">
                {siteName}
              </Typography>
              <Typography fontSize="1.5rem" color="#005994">
                {t("manager-dashboard.17")}
              </Typography>
            </Box>
            <Button
              variant="contained"
              className="addMemberBtn"
              style={{ width: "270px" }}
              onClick={() => navigate(`/user-report/pc/${userData.group_id}`)}
            >
              {t("pc-dashboard.1")}
            </Button>
          </Box>

          <Grid container spacing={3} sx={{ marginTop: "1rem" }}>
            {families?.length > 0 &&
              families.map((family) => (
                <Grid
                  item
                  key={family.group_id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                >
                  <div className="member">
                    <Avatar
                      className="memberLogo"
                      src={whiteMemberLogo}
                      alt=""
                    />
                    <Typography className="memberName">
                      {/* {t('navigation.4')} #{family.group_id} */}
                      {`${family?.first_Name ?? "null"} ${
                        family?.last_name ?? "null"
                      }-${family.group_id}`}
                    </Typography>
                    <Typography
                      className="details"
                      onClick={() =>
                        navigate(
                          `/show-family-members/Provider/${family.group_id}`
                        )
                      }
                    >
                      {t("user-dashboard.6")}
                    </Typography>
                  </div>
                </Grid>
              ))}
            {families?.length === 0 && (
              <Typography
                variant="h4"
                width="100%"
                fontWeight="700"
                textAlign="center"
                marginTop="2rem"
              >
                {t("manager-dashboard.21")}
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ProviderDashboard;
