import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./UserFollowUp.scss";
import { useTranslation } from "react-i18next";
import CustomStepper from "../../components/customStepper/CustomStepper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import FollowUpContent from "../../components/followUpContent/followUpContent";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import { store } from "../../app/store";
import { useAppSelector } from "../../app/hooks";

const UserFollowUp = () => {
  const { t } = useTranslation();
  const { planId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { currentPage, acheivedStatus } = queryString.parse(search);
  const showMemberStatus = useAppSelector(showMember);

  useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    if (!currentPage && !acheivedStatus) {
      navigate(`/follow-up/${planId}?currentPage=1`);
    }
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <Box className="userFollowUpPage">
        <Box className="userFollowUpPage__contentContainer">
          <Box className="subHeader">
            <Typography
              variant="h4"
              fontSize={{
                lg: "2rem",
              }}
              color="#fff"
              fontWeight="700"
              textAlign="center"
            >
              {t("follow-up.1")}
            </Typography>
          </Box>
          <Box className="subMain">
            <FollowUpContent
              planId={planId ? planId : ""}
              currentPage={currentPage}
              acheivedStatus={acheivedStatus}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={4} />
      </Box>
    </>
  );
};

export default UserFollowUp;
