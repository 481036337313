import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Grid } from "@mui/material";
import "./CounselorDashboard.scss";
import { useTranslation } from "react-i18next";
import { whiteMemberLogo } from "../../../utils/common/global";
import CustomDialog from "../../../components/customDialog/CustomDialog";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getUserData } from "../../../features/auth/authSlice";
import {
  fetchFamiliesUnderPcId,
  getFamiliesUnderPCId,
} from "../../../features/user/userSlice";
import { store } from "../../../app/store";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";

const CounselorDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);
  const [showMemberDetailsDialog, setShowMemberDetailsDialog] = useState(false);
  const families = useAppSelector(getFamiliesUnderPCId);
  const showMemberStatus = useAppSelector(showMember);

  const familiesUnderPCId = async () => {
    if (userData?.group_id)
      await store.dispatch(fetchFamiliesUnderPcId(userData?.group_id));
  };

  useEffect(() => {
    familiesUnderPCId();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <CustomDialog
        open={showAddMemberDialog}
        closeHandler={() => {
          setShowAddMemberDialog(false);
        }}
        title="New Member"
      />

      <CustomDialog
        open={showMemberDetailsDialog}
        closeHandler={() => {
          setShowMemberDetailsDialog(false);
        }}
        title="Member Details"
      />

      <Box className="counselorDashboardPage">
        <Box className="counselorDashboardPage__membersContainer">
          <Box className="subHeader">
            <Typography sx={{ fontSize: "1.5rem", color: "#07120D" }}>
              {t("manager-dashboard.17")}
            </Typography>
          </Box>

          <Grid container spacing={3} sx={{ marginTop: "1rem" }}>
            {families.length > 0 &&
              families?.map((family) => (
                <Grid
                  item
                  key={family.group_id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                >
                  <div className="member">
                    <Avatar
                      className="memberLogo"
                      src={whiteMemberLogo}
                      alt=""
                    />
                    <Typography className="memberName">
                      {`${family?.first_Name ?? "null"} ${
                        family?.last_name ?? "null"
                      }-${family.group_id}`}
                    </Typography>
                    <Typography
                      className="details"
                      onClick={() =>
                        navigate(
                          `/show-family-members/Counselor/${family.group_id}`
                        )
                      }
                    >
                      {t("user-dashboard.6")}
                    </Typography>
                  </div>
                </Grid>
              ))}
            {families?.length === 0 && (
              <Typography
                variant="h4"
                width="100%"
                fontWeight="700"
                textAlign="center"
                marginTop="2rem"
              >
                {t("manager-dashboard.21")}
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CounselorDashboard;
