import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import './SuccessEmailVerification.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { authEmailVerified } from '../../features/auth/asyncAction';
import LanguageSupport from '../../components/language-support/LanguageSupport';
import { store } from '../../app/store';
import {
  showMember,
  updateShowMember,
} from '../../features/global/globalSlice';
import { useAppSelector } from '../../app/hooks';

const SuccessEmailVerificationPage = () => {
  const { t } = useTranslation();
  const { roleId, groupId } = useParams();
  const navigate = useNavigate();
  const showMemberStatus = useAppSelector(showMember);

  const emailVerificationHandler = async () => {
    await authEmailVerified({ roleId, groupId });
  };

  useEffect(() => {
    emailVerificationHandler();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <Box className="successEmailVerificationPage">
        <Typography className="firstTypo">
          {t('email-verification.1')}
        </Typography>
        <Typography className="secondTypo">
          {t('email-verification.2')}
        </Typography>
        <Typography className="thirdTypo">
          {t('email-verification.3')}
        </Typography>
        <Button
          className="btn"
          variant="contained"
          onClick={() => navigate('/sign-in', { replace: true })}
        >
          {t('common.21')}
        </Button>
      </Box>
    </>
  );
};

export default SuccessEmailVerificationPage;
