import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, AlertColor } from "@mui/material";
import "./EditSiteDetails.scss";
import { useTranslation } from "react-i18next";
import { CloseRounded } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal, {
  SubmitParams,
} from "../../components/confirmation-modal/ConfirmationModal";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { getUserData } from "../../features/auth/authSlice";
import { SelectPicker } from "rsuite";
import CustomAlert from "../../components/custom-alert/CustomAlert";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import {
  addPcToSite,
  fetchAllCounselors,
  fetchAllProviders,
  removePcFromSite,
} from "../../features/PC/asyncActions";
import { fetchPcsByFamilyId } from "../../features/user/asyncActions";

const EditSiteDetails: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { siteId } = useParams();
  const userData = useAppSelector(getUserData);
  const [showDeletePcDialog, setShowDeletePcDialog] = useState(false);
  const [showAddProCounsDialog, setShowAddProCounsDialog] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState<string>("");
  const [selectedCounselorId, setSelectedCounselorId] = useState<string>("");
  const [selectedPcId, setSelectedPcId] = useState<string>("");
  const [allProviders, setAllProviders] = useState([]);
  const [allCounselors, setAllCounselors] = useState([]);
  const [userProviders, setUserProviders] = useState<any>([]);
  const [userCounselors, setUserCounselors] = useState<any>([]);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");
  const [selectedType, setSelectedType] = useState("");
  const showMemberStatus = useAppSelector(showMember);

  const submitParams: SubmitParams = {
    btnContent: "Delete",
    submitHandler: async (ev) => {
      const { status, message } = await removePcFromSite({
        sid: siteId,
        PC_id: selectedPcId,
      });
      setShowMessage(true);
      setMessageType(status);
      setMessage(message);
      setSelectedPcId("");
      getAllProviders();
      getAllCounselors();
      setShowDeletePcDialog(false);
    },
  };

  const getAllProviders = async () => {
    const { data } = await fetchAllProviders(userData?.authorized_code);
    setAllProviders(data);
  };
  const getAllCounselors = async () => {
    const { data } = await fetchAllCounselors(userData?.authorized_code);
    setAllCounselors(data);
  };

  const getUserProviders = async () => {
    const { data } = await fetchPcsByFamilyId({
      fromId: userData?.group_id,
      roleId: "2",
    });

    setUserProviders(data.map((item: any) => item?.group_id));
  };

  const getUserCounselors = async () => {
    const { data } = await fetchPcsByFamilyId({
      fromId: userData?.group_id,
      roleId: "3",
    });
    setUserCounselors(data.map((item: any) => item?.group_id));
  };

  const addPcToSiteHandler = async (id: any) => {
    const { message, status } = await addPcToSite({
      sid: siteId,
      mid: userData.group_id,
      PC_id: id,
    });
    setShowMessage(true);
    setMessageType(status);
    setMessage(message);
    setSelectedProviderId("");
    setSelectedCounselorId("");
    getAllProviders();
    getAllCounselors();
    getUserProviders();
    getUserCounselors();
  };

  useEffect(() => {
    getAllProviders();
    getAllCounselors();
    getUserProviders();
    getUserCounselors();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  return (
    <>
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <ConfirmationModal
        open={showDeletePcDialog}
        closeHandler={() => {
          setShowDeletePcDialog(false);
        }}
        title={`Delete ${selectedType}`}
        submitParams={submitParams}
      >
        <Box className="addProCounsContainer">
          <Box className="itemContainer">
            <Typography className="itemTitle">{`Do want to delete this ${selectedType}`}</Typography>
          </Box>
        </Box>
      </ConfirmationModal>
      <ConfirmationModal
        open={showAddProCounsDialog}
        closeHandler={() => {
          setShowAddProCounsDialog(false);
        }}
        title="Add Providers and Counselors"
        showSubmitButton={false}
      >
        <Box className="addProCounsContainer">
          <Box className="itemContainer">
            <Typography className="itemTitle">Add a provider</Typography>
            <Box className="content">
              <SelectPicker
                className="select"
                data={allProviders.map((provider: any) => ({
                  label: `${t("navigation.2")} ${provider.group_id}`,
                  value: provider.group_id,
                }))}
                style={{ width: 224 }}
                menuStyle={{ zIndex: "1000000" }}
                searchable={false}
                menuMaxHeight={140}
                value={selectedProviderId}
                onChange={(val: any) => setSelectedProviderId(val)}
              />
              <Button
                className="addBtn"
                variant="contained"
                onClick={() => addPcToSiteHandler(selectedProviderId)}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box className="itemContainer">
            <Typography className="itemTitle">Add a counselor</Typography>
            <Box className="content">
              <SelectPicker
                data={allCounselors.map((counselor: any) => ({
                  label: `Counselor ${counselor.group_id}`,
                  value: counselor.group_id,
                }))}
                style={{ width: 224 }}
                menuStyle={{ zIndex: "1000000" }}
                searchable={false}
                menuMaxHeight={140}
                value={selectedCounselorId}
                onChange={(val: any) => setSelectedCounselorId(val)}
              />
              <Button
                className="addBtn"
                variant="contained"
                onClick={() => addPcToSiteHandler(selectedCounselorId)}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Box>
      </ConfirmationModal>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="editSiteDetailsPage">
        <Box className="editSiteDetailsPage__membersContainer">
          <Box className="subContent">
            <Typography className="subTitle">Select provider </Typography>
            {userProviders.length > 0 && (
              <Grid container sx={{ margin: "0.5rem 0 4rem", flex: 1 }}>
                {userProviders.map((provider: any) => (
                  <Grid
                    item
                    key={provider.group_id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                  >
                    <Box className="item">
                      <CloseRounded
                        sx={{ alignSelf: "flex-end", cursor: "pointer" }}
                        onClick={() => {
                          setShowDeletePcDialog(true);
                          setSelectedPcId(provider.group_id);
                          setSelectedType("provider");
                        }}
                      />
                      <Typography className="item__title">
                        {`${provider.group_id} ${provider.first_name} ${provider.last_name}`}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
            {userProviders.length === 0 && (
              <Typography
                variant="h4"
                width="100%"
                fontWeight="700"
                textAlign="center"
                marginTop="3rem"
                marginBottom="2rem"
              >
                {t("site-details.7")}
              </Typography>
            )}
            <Typography className="subTitle">or counselor</Typography>
            {userCounselors.length > 0 && (
              <Grid container sx={{ margin: "0.5rem 0 4rem", flex: 1 }}>
                {userCounselors.map((counselor: any) => (
                  <Grid
                    item
                    key={counselor.group_id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                  >
                    <Box className="item counselor">
                      <CloseRounded
                        sx={{ alignSelf: "flex-end", cursor: "pointer" }}
                        onClick={() => {
                          setShowDeletePcDialog(true);
                          setSelectedPcId(counselor.group_id);
                          setSelectedType("counselor");
                        }}
                      />
                      <Typography className="item__title">
                        {`${counselor.group_id} ${counselor.first_name} ${counselor.last_name}`}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
            {userCounselors.length === 0 && (
              <Typography
                variant="h4"
                width="100%"
                fontWeight="700"
                textAlign="center"
                marginTop="3rem"
                marginBottom="2rem"
              >
                {t("site-details.8")}
              </Typography>
            )}
          </Box>
          <Button
            variant="outlined"
            onClick={() => navigate(-1)}
            className="outlinedBtn"
          >
            Back
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EditSiteDetails;
