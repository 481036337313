import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./RiskAreasManager.scss";
import { useTranslation } from "react-i18next";
import {
  getMember,
  getRiskAreasByMemberId,
} from "../../features/user/asyncActions";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  getGlobalLanguage,
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import CustomBackButton from "../../components/custom-bread-crumbs/CustomBackButton";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";
import CustomHint from "../../components/custom-hint/CustomHint";
import ConfirmationModal from "../../components/confirmation-modal/ConfirmationModal";
import { useWidthSize } from "../../utils/hooks/resizeHook";

const RiskAreasManager = () => {
  const { t } = useTranslation();
  const language = useAppSelector(getGlobalLanguage);
  const { memberId } = useParams();
  const [memberData, setMemberData] = useState<any>({});
  const [memberRiskAreas, setMemberRiskAreas] = useState([]);
  const showMemberStatus = useAppSelector(showMember);
  const [selectedRiskArea, setSelectedRiskArea] = useState<any>(null);

  const customWidth = useWidthSize();

  const [enableModal, setEnableModal] = React.useState(
    customWidth > 1023 ? false : true
  );

  const [openModal, setOpenModal] = useState(false);

  const fetchMemberRisksArea = async () => {
    const data = await getRiskAreasByMemberId(memberId);
    if (data) setMemberRiskAreas(data);
  };

  const fetchMemberData = async () => {
    const { data } = await getMember(memberId);
    setMemberData(data);
  };

  useEffect(() => {
    fetchMemberData();
    fetchMemberRisksArea();
    !showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="page">
        <ConfirmationModal
          title=""
          open={openModal}
          closeHandler={() => {
            setOpenModal(false);
          }}
          showCancelButton={false}
          showSubmitButton={false}
          showPlay
          audioText={t(`all_risk_areas_feedback.${selectedRiskArea}`)}
        >
          <Typography className="text">
            {t(`all_risk_areas_feedback.${selectedRiskArea}`)}
          </Typography>
        </ConfirmationModal>
        <Box className="page__intro">
          <CustomBackButton btnContent={t("risk-areas.1")} btnUrl={-1} />
        </Box>
        <Box className="page__contentContainer">
          <Box className="subHeader">
            <Typography color="#E33C90" variant="h3" fontWeight="700">
              {language === "en"
                ? `${memberData?.first_name}${t("risk-areas.2")}`
                : `${t("risk-areas.2")} ${memberData?.first_name}`}
            </Typography>
          </Box>
          <Box className="subMain">
            <Box className="riskAreasContainer">
              {memberRiskAreas.length > 0 &&
                memberRiskAreas?.map((area: any) => (
                  <Typography
                    key={area.risk_id}
                    variant="h5"
                    className="rightBox__title"
                    color={
                      area.risk_id === selectedRiskArea ? "#E33C90" : "#005994"
                    }
                    fontWeight="700"
                    marginX="2.5rem"
                    marginY="1rem"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (enableModal) setOpenModal(true);
                      setSelectedRiskArea(area.risk_id);
                    }}
                  >
                    {t(`all_risk_areas.${area.risk_id}`)}
                  </Typography>
                ))}
              {memberRiskAreas.length === 0 && (
                <Typography
                  variant="h4"
                  fontWeight="700"
                  textAlign="center"
                  marginTop="2rem"
                >
                  {t("risk-areas.3")}
                </Typography>
              )}
            </Box>
            {!enableModal && selectedRiskArea && (
              <Box alignSelf="center" margin="0 1rem">
                <CustomHint
                  showPlay
                  bottom="-50px"
                  left="-50px"
                  text={t(`all_risk_areas_feedback.${selectedRiskArea}`)}
                  audioText={t(`all_risk_areas_feedback.${selectedRiskArea}`)}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RiskAreasManager;
