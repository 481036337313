import * as React from "react";
import { Box, Typography } from "@mui/material";
import "./Resources.scss";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getGlobalLanguage } from "../../features/global/globalSlice";
import { useTranslation } from "react-i18next";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { firstFollowUpFlyer, renderAreas } from "../../utils/common/global";
import { BeatLoader } from "react-spinners";
import ImageMapper from "react-img-mapper";

const isFlyerWithURL = (goalId: string | undefined) =>
  ["7", "8", "16", "21", "22"].includes(goalId ?? "");

const areas: any = [
  { name: "Area 1", left: 0, top: 0, right: 57, bottom: 96 },
  // Add more areas as needed
];

const ResponsiveImageMap = ({ imageSrc, areas }: any) => {
  const [clickedArea, setClickedArea] = React.useState<any>(null);

  const handleAreaClick = (area: any) => {
    setClickedArea(area);
    // Handle click action for the area
  };

  return (
    <div style={{ position: "relative" }}>
      <img
        src={imageSrc}
        alt=""
        style={{ width: "100%", height: "auto" }}
        useMap="#image-map"
      />
      <map name="image-map">
        {areas.map((area: any, index: any) => (
          <area
            key={index}
            shape="rect"
            coords={`${area?.left}%, ${area?.top}%, ${area?.right}%, ${area?.bottom}%`}
            onClick={() => handleAreaClick(area)}
            alt={area.name}
          />
        ))}
      </map>
      {/* Display information about clicked area */}
      {clickedArea && (
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          Clicked Area: {clickedArea?.name}
        </div>
      )}
    </div>
  );
};

const ItemResources: React.FC = () => {
  const { t } = useTranslation();
  const { goalId } = useParams();
  const [loading, setLoading] = React.useState(true);
  const language = useAppSelector(getGlobalLanguage);
  const [slideImg, setSlideImg] = React.useState<any>(null);

  React.useEffect(() => {
    setLoading(true);
    const img = firstFollowUpFlyer(language, goalId ?? "");
    setSlideImg(img);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [language]);

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <Typography
        fontWeight="500"
        fontSize="36px"
        lineHeight="52px"
        textAlign="start"
        color="#5c5c5c"
        width="100%"
        paddingX="2rem"
      >
        {t("resources.1")}
      </Typography>
      <Box width="100%" padding="1rem">
        {loading ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={400}
          >
            <BeatLoader color="#1976d2" size={25} />
          </Box>
        ) : (
          <>
            {language === "en" && isFlyerWithURL(goalId) ? (
              <Box
                style={{
                  maxWidth: "1200px",
                  margin: "2.5rem auto",
                  height: "auto",
                  objectFit: "contain",
                  display: `${loading ? "none" : "block"}`,
                  width: "100%",
                }}
              >
                <ResponsiveImageMap areas={areas} imageSrc={slideImg} />
                <ImageMapper
                  src={slideImg}
                  map={{
                    name: `map-#${goalId}`,
                    areas: language === "en" ? renderAreas(goalId ?? "") : [{}],
                  }}
                  responsive
                  parentWidth={1200}
                  onClick={(area, index) => {
                    window.open(area.href, "_blank");
                  }}
                />
              </Box>
            ) : (
              <img
                src={slideImg}
                alt=""
                style={{
                  maxWidth: "1200px",
                  margin: "2.5rem auto",
                  height: "auto",
                  objectFit: "contain",
                  display: `${loading ? "none" : "block"}`,
                  width: "100%",
                }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ItemResources;
