import * as React from "react";
import ReactPlayer from "react-player/lazy";
import "./VideoEmbed.scss";

interface VideoEmbedProps {
  embedId: string;
  width?: string;
  height?: string;
  border?: string;
  className?: string;
}

const VideoEmbed: React.FunctionComponent<VideoEmbedProps> = ({
  embedId,
  width,
  height,
  border,
  className,
}) => {
  const customWidth = width ? width : "100%";
  const customHeight = height ? height : "360px";

  return (
    <div className={`videoEmbed ${className}`}>
      <ReactPlayer
        url={`https://www.youtube.com/embed/${embedId}?origin=https://mysmilebuddy.org/`}
        // url={`https://www.youtube.com/embed/${embedId}?origin=http://localhost:3001//`}
        controls={true}
        width={customWidth}
        height={customHeight}
        style={{
          top: "0",
          left: "0",
          overflow: "hidden",
          borderRadius: "18px",
          boxSizing: "border-box",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          // maxWidth: "780px",
          maxWidth: "580px",
        }}
      />
    </div>
  );
};

export default VideoEmbed;
