import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import "./Resources.scss";
import VideoEmbed from "../../components/video-embed/VideoEmbed";
import {
  caries_video,
  followUpPdfLink,
  resourcesImages,
  toothbrush_video,
  uniqueGoals,
} from "../../utils/common/global";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import {
  getGlobalLanguage,
  showMember,
  updateShowMember,
} from "../../features/global/globalSlice";
import LanguageSupport from "../../components/language-support/LanguageSupport";
import { store } from "../../app/store";
import { Link } from "react-router-dom";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@mui/icons-material";

interface ResourcesProps {}

const Resources: React.FC<ResourcesProps> = (props) => {
  const { t } = useTranslation();
  const language = useAppSelector(getGlobalLanguage);
  const showMemberStatus = useAppSelector(showMember);
  const [selectedGoalId, setSelectedGoalId] = React.useState<number | null>(
    null
  );

  React.useEffect(() => {
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleDownloadPDF = () => {
  //   // Replace 'YOUR_PDF_URL_HERE' with the actual URL of the PDF hosted on AWS
  //   const pdfUrl = "YOUR_PDF_URL_HERE";

  //   axios
  //     .get(pdfUrl, {
  //       responseType: "blob", // important
  //     })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       setFileUrl(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the PDF file:", error);
  //     });
  // };

  const generatePdf = (goalId: any, idx: any) => {
    // const link = document.createElement("a");
    // link.href = followUpPdfLink(language, goalId, idx);
    // link.download = "document.pdf"; // specify the filename
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    window.open(followUpPdfLink(language, goalId, idx), "_blank");
  };

  return (
    <>
      <LanguageSupport showTranslateComponent />
      <Box className="resourcesPage">
        <Typography className="resources__title">{t("resources.1")}</Typography>
        <Typography className="resources__subtitle">
          {t("resources.6")}
        </Typography>
        <Box className="resources__tutorials">
          <Box className="tutorial">
            <VideoEmbed
              embedId={caries_video["1"][language]}
              width="100%"
              height="320px"
              className="tutorialVideo"
            />
            <Typography className="tutorialText">{t("resources.2")}</Typography>
          </Box>
          <Box className="tutorial">
            <VideoEmbed
              embedId={caries_video["2"][language]}
              width="100%"
              height="320px"
              className="tutorialVideo"
            />
            <Typography className="tutorialText">{t("resources.3")}</Typography>
          </Box>
        </Box>
        <Typography className="resources__subtitle">
          {t("resources.7")}
        </Typography>
        <Box className="resources__tutorials">
          <Box className="tutorial">
            <VideoEmbed
              embedId={toothbrush_video["1"][language]}
              width="100%"
              height="320px"
              className="tutorialVideo"
            />
            <Typography className="tutorialText">{t("resources.2")}</Typography>
          </Box>
          <Box className="tutorial">
            <VideoEmbed
              embedId={toothbrush_video["2"][language]}
              width="100%"
              height="320px"
              className="tutorialVideo"
            />
            <Typography className="tutorialText">{t("resources.3")}</Typography>
          </Box>
        </Box>
        <Box className="resources__tutorials">
          <Grid
            container
            className="resources__goals"
            spacing={6}
            width={"100%"}
            mx={0}
          >
            {Object.keys(uniqueGoals).map((goalId: any) => (
              <Grid
                item
                key={goalId}
                xs={12}
                sm={12}
                md={4}
                style={{ paddingLeft: 0 }}
              >
                <Box
                  className="goal"
                  m={{
                    xs: 0,
                    sm: 0,
                    md: "1rem",
                  }}
                  width={{
                    xs: "100%",
                    sm: "100%",
                    md: "calc(100% - 32px)",
                  }}
                >
                  <img
                    src={resourcesImages[goalId]}
                    alt=""
                    className="goalImg"
                  />
                  <Box className="goalContent">
                    <Typography className="content__title" variant="h2">
                      {t(`all_goals.${goalId}`)}
                    </Typography>
                    <Box className="tipsContainer">
                      <Typography
                        className="see more"
                        onClick={() => {
                          if (selectedGoalId === goalId) {
                            setSelectedGoalId(null);
                          } else {
                            setSelectedGoalId(goalId);
                          }
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        {selectedGoalId === goalId ? (
                          <>
                            {t("resources.5")}{" "}
                            <ArrowDropUpOutlined className="arrow" />
                          </>
                        ) : (
                          <>
                            {t("resources.4")}{" "}
                            <ArrowDropDownOutlined className="arrow" />
                          </>
                        )}
                      </Typography>
                      {selectedGoalId === goalId && (
                        <Box className="tips">
                          {uniqueGoals[goalId].map((tip: any, idx: number) => (
                            <Link to={`/resources/${tip}`} target="_blank">
                              <Typography
                                className="tip"
                                sx={{ margin: "0.25rem" }}
                              >
                                {`Helpful Tip #1 (${
                                  idx === 0
                                    ? "0-8 Years Old"
                                    : "9 Years Old And Above"
                                })`}
                              </Typography>
                            </Link>
                          ))}
                          {goalId === "8" &&
                            (language === "en" || language === "sw") && (
                              <>
                                <Link to={`/resources/21`} target="_blank">
                                  <Typography
                                    className="tip"
                                    sx={{ margin: "0.25rem" }}
                                  >
                                    {`Helpful Tip #2 (0-8 Years Old)`}
                                  </Typography>
                                </Link>
                                <Link to={`/resources/22`} target="_blank">
                                  <Typography
                                    className="tip"
                                    sx={{ margin: "0.25rem" }}
                                  >
                                    {`Helpful Tip #2 (9 Years Old And Above)`}
                                  </Typography>
                                </Link>
                              </>
                            )}
                          {uniqueGoals[goalId].map((goal: any, idx: number) => (
                            <Typography
                              className="tip"
                              sx={{ margin: "0.25rem" }}
                              onClick={() => generatePdf(goal, idx)}
                            >
                              {`Download all follow up materials (${
                                idx === 0
                                  ? "0-8 Years Old"
                                  : "9 Years Old And Above"
                              })`}
                            </Typography>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Resources;
