export const checkEmpty = (body: object): boolean => {
  let state: boolean = true;
  for (var key in body) {
    if (key !== "phoneNumber") {
      if (
        body[key as keyof object] === null ||
        body[key as keyof object] === undefined ||
        body[key as keyof object] === "" ||
        body[key as keyof object] === false
      )
        state = false;
    }
  }
  return state;
};
export const checkListEmpty = (data: Array<any>): boolean => {
  let state: boolean = true;
  for (var key of data) {
    if (key.length > 0) state = false;
  }
  return state;
};
