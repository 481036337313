import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "./ProviderAccount.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { getUserData } from "../../../features/auth/authSlice";
import { managersUnderSitesByPcId } from "../../../features/manager/asyncActions";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import { store } from "../../../app/store";

const ProviderAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const [managersSites, setManagersSites] = useState<any>([]);
  const showMemberStatus = useAppSelector(showMember);

  const fetchManagersUnderSitesByPcId = async () => {
    const data = await managersUnderSitesByPcId(userData.group_id);
    setManagersSites(data);
  };

  useEffect(() => {
    fetchManagersUnderSitesByPcId();
    showMemberStatus && store.dispatch(updateShowMember(!showMemberStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="providerAccountPage">
        <Box className="providerAccountPage__contentContainer">
          <Box className="subHeader">
            <Box
              display="flex"
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              flexDirection={{ xs: "column", sm: "row" }}
              columnGap="6rem"
              rowGap="1rem"
            >
              {userData?.first_Name && userData.last_name && (
                <Typography sx={{ fontSize: "1.5rem", color: "#07120D" }}>
                  {userData.first_Name} {userData.last_name}
                </Typography>
              )}
              <Typography fontSize="1.5rem" color="#07120D">
                ID : {userData?.group_id}
              </Typography>
            </Box>
            <Button
              variant="contained"
              className="btn containedBtn"
              onClick={() => navigate("/edit-provider-account")}
            >
              {t("common.6")}
            </Button>
          </Box>
          <Typography className="subTitle__info">
            {t("user-account.2")}
          </Typography>
          <Box className="subMain">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.3")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.first_Name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.4")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.last_name || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.5")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData.phone_number || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.6")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    {userData?.email || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Box>
                  <Typography className="title">
                    {t("user-account.7")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "500",
                      opacity: "0.6",
                    }}
                  >
                    ***********
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {userData?.authorized_code ? (
            <Typography className="subTitle__info">
              <span style={{ color: "#07120D", fontWeight: "normal" }}>{`${t(
                "pc-account.9"
              )} : `}</span>
              {`${userData?.authorized_code}`}
            </Typography>
          ) : null}

          {/* <Box
            width="100%"
            maxWidth="600px"
            margin="1rem 0"
            borderRadius="10px"
          >
            <TableContainer component={Paper} sx={{ maxWidth: 800 }}>
              <Table
                sx={{ maxWidth: 800 }}
                size="small"
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t('pc-account.7')}</TableCell>
                    <TableCell align="left">{t('pc-account.8')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {managersSites?.map((site: any) => (
                    <TableRow
                      key={site.sites_id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="site" align="center">
                        {t('navigation.6')} {site.sites_id}
                      </TableCell>
                      <TableCell align="left">
                        {site.managers?.join(', ')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default ProviderAccount;
