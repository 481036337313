import * as React from "react";
import "../common/GoalSettings.scss";
import { Box, Typography, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import {
  getCurrentMember,
  saveRiskAndGoal,
} from "../../../features/user/userSlice";
import { getUserData, loadUserData } from "../../../features/auth/authSlice";
import {
  showMember,
  updateShowMember,
} from "../../../features/global/globalSlice";
import {
  getAllRiskAreas,
  getRiskAreasByMemberId,
  updateIsMoveStatus,
} from "../../../features/user/asyncActions";
import { store } from "../../../app/store";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import AudioSupport from "../../../components/audio-support/AudioSupport";
import CustomStepper from "../../../components/customStepper/CustomStepper";
import CustomHint from "../../../components/custom-hint/CustomHint";
import { isEmpty } from "lodash";

import RiskArea from "../../../components/riskArea/RiskArea";

const SettingsRiskAreas = () => {
  const navigate = useNavigate();
  const memberData = useAppSelector(getCurrentMember);
  const [memberRiskAreas, setMemberRiskAreas] = React.useState<any>([]);
  const userData = useAppSelector(getUserData);
  const showMemberStatus = useAppSelector(showMember);
  const { t } = useTranslation();
  const [selectedRiskArea, setSelectedRiskArea] = React.useState<any | null>(
    {}
  );
  const [allRiskAreas, setAllRiskAreas] = React.useState<any>([]);

  const fetchMemberRisksArea = async () => {
    const data = await getRiskAreasByMemberId(memberData?.user_id);
    if (data) setMemberRiskAreas(data);
  };

  const fetchAllRisksArea = async () => {
    const data = await getAllRiskAreas(memberData?.user_id);
    if (data) setAllRiskAreas(data);
  };

  React.useEffect(() => {
    fetchMemberRisksArea();
    fetchAllRisksArea();
    if (userData?.isMove === 0) updateIsMoveStatus(userData?.group_id);
    store.dispatch(loadUserData(userData?.group_id));
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  React.useEffect(() => {
    !showMemberStatus && store.dispatch(updateShowMember(true));
  }, []);

  const storeRiskAreaHandler = () => {
    store.dispatch(
      saveRiskAndGoal({
        riskId: selectedRiskArea.id,
        riskName: selectedRiskArea.name,
      })
    );
  };

  return (
    <>
      <LanguageSupport showBackArrowComponent showTranslateComponent />
      <Box className="goalSettingsPage">
        <Box className="goalSettingsPage__contentContainer">
          <Box className="subHeader">
            <AudioSupport audioText={t("goal-settings.6")} />
          </Box>
          <Box className="subMain">
            <Box className="goalRiskAreasContainer">
              <Typography
                className="title"
                variant="h3"
                fontSize={{
                  lg: "2.5rem",
                }}
              >
                {t("goal-settings.3")}
              </Typography>
              <Grid container spacing={3} className="areasContainer">
                <Grid item xs={12} sm={12} md={7} lg={8}>
                  <Box className="areas">
                    {(memberRiskAreas.length > 0
                      ? memberRiskAreas
                      : allRiskAreas
                    ).map((area: any) => (
                      <RiskArea
                        key={area.risk_id}
                        title={area.risk_id}
                        clicked
                        clickHandler={() =>
                          setSelectedRiskArea({
                            id: area.risk_id,
                            name: area.risk_name,
                          })
                        }
                        selectedArea={area.risk_id === selectedRiskArea?.id}
                        type="high_risk_areas"
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                >
                  {!isEmpty(selectedRiskArea) ? (
                    <Box alignSelf="center" margin="0 1rem">
                      <CustomHint
                        showPlay
                        bottom="-50px"
                        left="-50px"
                        text={t(
                          `all_risk_areas_feedback.${selectedRiskArea.id}`
                        )}
                        audioText={t(
                          `all_risk_areas_feedback.${selectedRiskArea.id}`
                        )}
                      />
                    </Box>
                  ) : (
                    <CustomHint
                      text={t("goal-settings.4")}
                      audioText={t("goal-settings.4")}
                      bottom="-50px"
                      right="-50px"
                    />
                  )}
                </Grid>
              </Grid>
              <Button
                className="btn"
                variant="contained"
                onClick={() => {
                  storeRiskAreaHandler();
                  navigate("/goal-settings/start-goal");
                }}
                disabled={!selectedRiskArea?.id}
              >
                {t("common.3")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomStepper activeStep={2} />
      </Box>
    </>
  );
};

export default SettingsRiskAreas;
