import React from "react";
import { Box, Typography, Button, Grid, AlertColor } from "@mui/material";
import "./NewLicense.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LanguageSupport from "../../../components/language-support/LanguageSupport";
import CustomAlert from "../../../components/custom-alert/CustomAlert";
import { checkEmpty } from "../../../utils/common/checkEmpty";
import { validEmail } from "../../../utils/common/checkValidator";
import { addNewLicense } from "../../../features/administrator/asyncAction";

const NewLicense: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [licenseName, setLicenseName] = React.useState<string>("");
  const [startDate, setStartDate] = React.useState<string>("");
  const [duration, setDuration] = React.useState<string>("");
  const [totalUsers, setTotalUsers] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [messageType, setMessageType] = React.useState<AlertColor>("error");

  const submitHandler = async () => {
    const requiredData = {
      email,
      licenseName,
      startDate,
      duration,
      totalUsers,
    };
    const state = checkEmpty(requiredData);
    if (state) {
      if (validEmail(email)) {
        const allData = {
          ...requiredData,
          firstName,
          lastName,
          phoneNumber,
          language: "English",
        };
        const { message, status } = await addNewLicense(allData);
        setShowMessage(true);
        setMessage(message);
        setMessageType(status ?? "error");
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      } else {
        setShowMessage(true);
        setMessage(t("common.27"));
        setMessageType("error");
      }
    } else {
      setShowMessage(true);
      setMessage(t("common.16"));
      setMessageType("error");
    }
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <>
      <LanguageSupport showTranslateComponent showBackArrowComponent />
      <CustomAlert
        type={messageType}
        open={showMessage}
        closeHandler={() => setShowMessage(false)}
        message={message}
      />
      <Box className="newLicensePage">
        <Box sx={{ marginTop: "1rem" }}>
          <Typography className="newLicensePage__title" variant="h3">
            {t("new-license.1")}
          </Typography>
        </Box>
        <Box className="newLicensePage__container">
          <Typography
            marginRight="auto"
            variant="h5"
            fontWeight="700"
            color="#005994"
          >
            {t("new-license.2")}
          </Typography>
          <Grid
            container
            spacing={3}
            sx={{ marginTop: "1rem" }}
            className="gridContainer"
          >
            {/* license list */}
            <Grid item key={1} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box className="input-container">
                <Typography variant="h6">{t("new-license.3")}</Typography>
                <input
                  type="text"
                  className="input"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item key={1} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box className="input-container">
                <Typography variant="h6">{t("new-license.4")}</Typography>
                <input
                  type="text"
                  className="input"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item key={1} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box className="input-container">
                <Typography variant="h6">
                  {t("new-license.5")} <span style={{ color: "red" }}>*</span>
                </Typography>
                <input
                  type="email"
                  className="input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item key={1} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box className="input-container">
                <Typography variant="h6">{t("new-license.6")}</Typography>
                <input
                  type="text"
                  className="input"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
          <Typography
            marginRight="auto"
            variant="h5"
            fontWeight="700"
            color="#005994"
            marginTop="4rem"
          >
            {t("new-license.7")}
          </Typography>
          <Grid
            container
            spacing={3}
            sx={{ marginTop: "1rem" }}
            className="gridContainer"
          >
            {/* license list */}
            <Grid item key={1} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box className="input-container">
                <Typography variant="h6">
                  {t("new-license.8")} <span style={{ color: "red" }}>*</span>
                </Typography>
                <input
                  type="text"
                  className="input"
                  value={licenseName}
                  onChange={(e) => setLicenseName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item key={1} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box className="input-container">
                <Typography variant="h6">
                  {t("new-license.9")} <span style={{ color: "red" }}>*</span>
                </Typography>
                <input
                  type="date"
                  className="input"
                  placeholder="Select Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item key={1} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box className="input-container">
                <Typography variant="h6">
                  {t("new-license.10")} <span style={{ color: "red" }}>*</span>
                </Typography>
                <select
                  className="input"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Months
                  </option>
                  {Array.from(Array(24).keys()).map((i) => (
                    <option value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </Box>
            </Grid>
            <Grid item key={1} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box className="input-container">
                <Typography variant="h6">
                  {t("new-license.11")} <span style={{ color: "red" }}>*</span>
                </Typography>
                <select
                  className="input"
                  value={totalUsers}
                  onChange={(e) => setTotalUsers(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Total Users
                  </option>
                  <option value="1">1</option>
                  <option value="10">10</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </Box>
            </Grid>
          </Grid>
          <Box className="btnContainer">
            <Button
              variant="outlined"
              className="btn cancel"
              sx={{ width: "270px" }}
              onClick={() => navigate(-1)}
            >
              {t("common.2")}
            </Button>
            <Button
              className="btn submit"
              variant="contained"
              onClick={submitHandler}
            >
              {t("common.26")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewLicense;
